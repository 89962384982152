.CursorPointer {
    cursor:pointer;
}

.HelpBoxFadeIn {
    transition: opacity 0.5s, visibility 0.5s;
    opacity: 1;
    display:block;
    visibility:initial;
}

.HelpBoxFadeOut {
    transition: opacity 0.5s, visibility 0.5s;
    opacity: 0;
    display:block;
    visibility:hidden;
}

.MaskBoxFadeIn {
    transition: opacity 0.5s, visibility 0.5s;
    opacity: 0.8;
    display:block;
    visibility:initial;
}

.MaskBoxFadeOut {
    transition: opacity 0.5s, visibility 0.5s;
    opacity: 0;
    display:block;
    visibility:hidden;
}

.NoDrag {
    -ms-user-select: none;
    -moz-user-select: -moz-none;
    -webkit-user-select: none;
    user-select: none;
}

.HideScrollBar::-webkit-scrollbar {
    display: none;
}

.MenuListFont {
    font-size: 15px;
    font-weight: 500;
}
@media screen and (min-width:1200px) and (max-width:1600px) {
    .MenuListFont {
        font-size: 14px;
        font-weight:400;
    }
}

@media screen and (max-width: 1199px) {
    .MenuListFont {
        font-weight: 400;
    }
}



.LoadingBarAbsoluteCenter {
    position:absolute;
    top:50%;
    left:45%;
    margin-top:-80px;
    margin-left:-80px;
}

.UploadingProgressBar {
    position:absolute;
    top:10%;
    left: calc(50vw-133px);
    text-align:center;

}

@media screen and (max-width: 1199px) {
    .UploadingProgressBar {
        position:absolute;
        top:15%;
        left: calc(50vw - 3rem);
    }
}



.CropImageInL4 {
    object-fit: none;
    object-position: 50% 35%;
}


.MyToggleContainer {
    width: 100%;
    /*margin-top: 200px;*/
    padding: 20px 0;
}


.ImageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.StoryContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*padding: 20px 0;*/
    margin-bottom:60px;
}

@media screen and (min-width:1200px) and (max-width:1600px) {
    .StoryContainer {
        padding: 0;
    }
}

@media screen and (max-width:1199px) {
    .StoryContainer {
        margin-bottom: 0px;
        overflow:auto;
        padding-top:0px;
    }
}



.StoryContainer p {
    font-size: 22px;
    font-weight: 400;
    color: #424141;
    letter-spacing: -.02em;
    line-height: 1.71429em;
    width: auto;
    max-width:1000px;
    padding:0 40px;
}

@media screen and (min-width:1200px) and (max-width:1600px) {
    .StoryContainer p {
        font-size: 20px;
    }
}

@media screen and (max-width: 1199px){
    .StoryContainer p {
        font-size: 1rem;
        max-width: 70vw;
        margin-bottom:20px;
        padding-top: 30px;
    }

}


.StoryContainer .larger {
    font-size: 28px;
}


@media screen and (max-width: 1199px){
    .MyToggleContainer {
        display:flex;
        flex-direction: row-reverse;
        justify-content: flex-end;
        width: 100%;
        /*margin-top: 200px;*/
        padding: 20px 0 0 0;
    }

    .MyToggleContainer .StoryContainer {
        max-width: 65%;
    }

    .MyToggleContainer .StoryContainer p{
        font-size: 0.89rem;
    }

    .MyToggleContainer .ImageContainer img {
        position: absolute;
        top: 25%;
        right: 5%;

    }

    .ImageContainer.rdn img{
        height: 35vh;
        width: auto;

    }

    .StoryContainer.rdn p{
        font-size: 0.85rem;
        line-height: 1.2rem;
    }

    .StoryContainer .larger {
        font-size: 1.1rem;
        max-width: initial;
        width: 100%;
    }

}




.CropImageInL5 {
    /*width:1000px;*/
    height: 400px;
    object-fit: contain;
    object-position: 50% 0;
}
@media screen and (min-width:1200px) and (max-width:1600px) {
    .CropImageInL5 {
        height: 300px;
    }
}

@media screen and (max-width: 1199px){
    .CropImageInL5 {
        height:40vh;
    }
}


.CropImageInL7 {
    width: 800px;
    height: 400px;
    object-fit: cover;
    margin-top:20px;
    /*object-position: 50% 0%;*/
}

@media screen and (min-width:1200px) and (max-width:1600px) {
    .CropImageInL7 {
        width: 600px;
        height:300px;
        object-position: 50% 10%;
    }


}

@media screen and (max-width: 1199px){
    .CropImageInL7 {
        display:none;
        /*margin-top: 0;*/
        /*height: 40vh;*/
        /*width: auto;*/
    }
}

.CropImageInL8 {
    /*width: 800px;*/
    /*height: 400px;*/
    object-fit: cover;
    /*object-position: 50% 0%;*/
}
@media screen and (min-width:1200px) and (max-width:1600px) {
    .CropImageInL8 {
        width: 700px;
        /*height: 400px;*/
    }
}
@media screen and (max-width: 1199px){

    .CropImageInL8 {
        height: 45%;
        width: auto;
        /*height: 400px;*/
    }
}



.ShowAnimation {
    display:none;
}

.CropImageInL6 {
    object-fit: none;
    height: 450px;
    object-position: 50% 10%;

}


/*Hint*/
.view-section .view-body .btn-hint-toggle {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    background-color: #ff7300;
    color:#fff;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    position: absolute;
    right: 36px;
    top: 65px;
    width: 100px;
    border-radius: 50px;
    padding: 10px 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}
@media screen and (min-width:1200px) and (max-width:1600px) {
    .view-section .view-body .btn-hint-toggle {
        right: 45px;
        top: 20px;
    }
}
@media screen and (max-width: 1199px){
    .view-section .view-body .btn-hint-toggle {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        background-color: #ff7300;
        color:#fff;
        font-size: 0.8rem;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        position: absolute;
        right: 1rem;
        top: 3rem;
        width: 4.5rem;
        border-radius: 2.5rem;
        padding: 0.1rem 0.2rem;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease
    }




    .view-section .view-body .btn-hint-toggle img{
       height: 1rem;
        width: auto;
    }
}


.view-section .view-body .btn-hint-toggle:hover {
    background-color: #ff9c04;
    transition:background-color 0.5s;
}

.view-section .view-body .hint-container {
    margin: 0;
    font-weight: 400;
    background-color: #ff7300;
    color:#fff;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    position: absolute;
    right: 36px;
    top: 65px;
    min-width: 150px;
    border-radius: 50px;
    padding: 10px 20px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease

}
@media screen and (min-width:1200px) and (max-width:1600px) {
    .view-section .view-body .hint-container {
        right: 45px;
        top: 20px;
    }
}

@media screen and (max-width: 1199px){
    .view-section .view-body .hint-container {
        right: 1rem;
        top: 3rem;
        font-size: 0.8rem;
        padding: 0.1rem 1rem;
    }
}



/*HINT 종료*/


/*스위치 버튼*/

.outerDivFull {
    margin: 50px;
}

.switchToggle input[type=checkbox] {
    height: 0;
    width: 0;
    visibility: hidden;
    position: absolute;
}

.switchToggle label {
    cursor: pointer;
    text-indent: -9999px;
    width: 70px;
    max-width: 70px;
    height: 30px;
    background: #d1d1d1;
    display: block;
    border-radius: 100px;
    position: relative;
}

.switchToggle label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 26px;
    height: 26px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
}

@media screen and (max-width: 1199px){
    .switchToggle label {
        width: 35px;
        max-width: 35px;
        height: 15px;
        border-radius: 50px;
    }

    .switchToggle label:after {
        top: 1px;
        left: 1px;
        width: 13px;
        height: 13px;
        background: #fff;
        border-radius: 45px;
        transition: 0.3s;
    }
}


.switchToggle input:checked + label, .switchToggle input:checked + input + label {
    background: #3e98d3;
}

.switchToggle input + label:before, .switchToggle input + input + label:before {
    content: 'No';
    position: absolute;
    top: 5px;
    left: 35px;
    width: 26px;
    height: 26px;
    border-radius: 90px;
    transition: 0.3s;
    text-indent: 0;
    color: #fff;
}

.switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {
    content: 'Yes';
    position: absolute;
    top: 5px;
    left: 10px;
    width: 26px;
    height: 26px;
    border-radius: 90px;
    transition: 0.3s;
    text-indent: 0;
    color: #fff;
}

@media screen and (max-width: 1199px){

    .switchToggle input + label:before, .switchToggle input + input + label:before {
        content: '';
        top: 2.5px;
        left: 17.5px;
        width: 13px;
        height: 13px;
        border-radius: 45px;
        transition: 0.3s;
        text-indent: 0;
        color: #fff;
    }

    .switchToggle input:checked + label:before, .switchToggle input:checked + input + label:before {
        content: '';
        position: absolute;
        top: 2.5px;
        left: 5px;
        width: 13px;
        height: 13px;
        border-radius: 45px;
        transition: 0.3s;
        text-indent: 0;
        color: #fff;
    }
}


.switchToggle input:checked + label:after, .switchToggle input:checked + input + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
}

.switchToggle label:active:after {
    width: 60px;
}
@media screen and (max-width: 1199px){
    .switchToggle label:active:after {
        width: 30px;
    }
}


.toggle-switchArea {
    margin: 10px 0 10px 0;
}
@media screen and (max-width: 1199px){
    .toggle-switchArea {
        margin: 5px 0 5px 0;
    }
}




.Err {
    color:#d83b27;
    font-size:100%;
}
.Err-back {
    color:#f7f8fc;
    padding:10px 20px;
}

@media screen and (min-width:1200px) and (max-width:1600px) {
    .Err {
        position:absolute;
        bottom:10px;
    }
}

.TempTranslationTestText {
    font-size: 24px;
    line-height: 2;
}

@media screen and (min-width:1200px) and (max-width:1600px) {
    .small-figure img {
        height: 500px;
        width: auto;
        object-fit:contain;
    }
}

@media screen and (max-width: 1199px){
    .small-figure img {
        height: 60vh;
        width: auto;
        object-fit:contain;
    }
}

@media screen and (max-width: 1199px) {
    .figure-lnu img {
        height: 70vh;
        width: auto;
    }

    .figure-skt img {
        height: 70vh;
        width: auto;
    }
}

.line-through {
    text-decoration: line-through;
}

.l3cp-figure {

}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l3cp-figure img{
        width: 680px;
    }
}
@media screen and (max-width: 1199px){
    .l3cp-figure {
        height: 70%;
        width: auto;
        margin: 0 auto;

    }
    .l3cp-figure img{
        height: 100%;
    }
}



/*마이크 비쥬얼라이져 (모바일)*/

/*.recorder-container {*/
    /*width: 100%;*/
    /*height: 100%;*/
    /*position:relative;*/
    /*display:flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/



/*}*/
/*.recorder-container .mic-bg {*/
    /*width: 2rem;*/
    /*height: 2rem;*/
    /*background-color: #00759c;*/
    /*border-radius: 100%;*/
    /*display:flex;*/
    /*justify-content: center;*/
    /*align-items: center;*/
    /*z-index:2;*/
/*}*/

/*.recorder-container img{*/
    /*height: 60%;*/
    /*width: 60%;*/

/*}*/

/*.icon-microphone {*/


/*}*/

/*.outer {*/
    /*width: 2.1rem;*/
    /*height: 2.1rem;*/
    /*-webkit-transform: scale(1);*/
    /*border-radius: 100%;*/
    /*background-color: rgba(0, 117, 156, 0.71);*/
    /*opacity: 0;*/
    /*!*border: 1px solid #ffffff;*!*/
    /*position:absolute;*/
    /*top: 0.5125rem;*/
    /*right: calc(50% - 1.05rem);*/
    /*z-index: 1;*/
    /*!*transition: 1.5s all ease;*!*/
    /*-webkit-animation: woong 1.8s infinite;*/
    /*-webkit-animation-delay: 0.9s;*/
/*}*/
/*.outer-2 {*/
    /*width: 2.1rem;*/
    /*height: 2.1rem;*/
    /*-webkit-transform: scale(1);*/
    /*border-radius: 100%;*/
    /*background-color: rgba(0, 117, 156, 0.71);*/
    /*z-index: 1;*/
    /*position:absolute;*/
    /*top: 0.5125rem;*/
    /*right: calc(50% - 1.05rem);*/
    /*!*transition: 1.5s all ease;*!*/
    /*-webkit-animation: woong-2 1.8s infinite;*/
    /*box-sizing: content-box;*/

/*}*/

.recorder-container {
    width: 100%;
    height: 100%;
    position:relative;
    display:flex;
    justify-content: center;
    align-items: center;
}
.recorder-container .mic-bg {
    width: 50px;
    height: 50px;
    background-color: #00759c;
    border-radius: 100%;
    display:flex;
    justify-content: center;
    align-items: center;
    z-index:2;
}

.recorder-container img{
    height: 60%;
    width: 60%;

}

.icon-microphone {


}

.outer {
    width: 60px;
    height: 60px;
    -webkit-transform: scale(1);
    border-radius: 100%;
    background-color: rgba(0, 117, 156, 0.71);
    opacity: 0;
    /*border: 1px solid #ffffff;*/
    position:absolute;
    top: 30px;
    right: calc(50% - 30px);
    z-index: 1;
    /*transition: 1.5s all ease;*/
    -webkit-animation: woong 1.8s infinite;
    -webkit-animation-delay: 0.9s;
}
.outer-2 {
    width: 60px;
    height: 60px;
    -webkit-transform: scale(1);
    border-radius: 100%;
    background-color: rgba(0, 117, 156, 0.71);
    z-index: 1;
    position:absolute;
    top: 30px;
    right: calc(50% - 30px);
    /*transition: 1.5s all ease;*/
    -webkit-animation: woong-2 1.8s infinite;
    box-sizing: content-box;

}



@media screen and (min-width: 1200px) and (max-width: 1600px){
    .recorder-container {
        width: 100%;
        height: 100%;
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;



    }
    .recorder-container .mic-bg {
        width: 50px;
        height: 50px;
        background-color: #00759c;
        border-radius: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
        z-index:2;
    }

    .recorder-container img{
        height: 60%;
        width: 60%;

    }

    .icon-microphone {


    }

    .outer {
        width: 60px;
        height: 60px;
        -webkit-transform: scale(1);
        border-radius: 100%;
        background-color: rgba(0, 117, 156, 0.71);
        opacity: 0;
        /*border: 1px solid #ffffff;*/
        position:absolute;
        top: 20px;
        right: calc(50% - 30px);
        z-index: 1;
        /*transition: 1.5s all ease;*/
        -webkit-animation: woong 1.8s infinite;
        -webkit-animation-delay: 0.9s;
    }
    .outer-2 {
        width: 60px;
        height: 60px;
        -webkit-transform: scale(1);
        border-radius: 100%;
        background-color: rgba(0, 117, 156, 0.71);
        z-index: 1;
        position:absolute;
        top: 20px;
        right: calc(50% - 30px);
        /*transition: 1.5s all ease;*/
        -webkit-animation: woong-2 1.8s infinite;
        box-sizing: content-box;

    }
}




@media screen and (max-width: 1199px) {

    /*.recorder-container {*/
        /*width: 100%;*/
        /*height: 100%;*/
        /*position:relative;*/
        /*display:flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/



    /*}*/
    /*.recorder-container .mic-bg {*/
        /*width: 40px;*/
        /*height: 40px;*/
        /*background-color: #00759c;*/
        /*border-radius: 100%;*/
        /*display:flex;*/
        /*justify-content: center;*/
        /*align-items: center;*/
        /*z-index:2;*/
    /*}*/

    /*.recorder-container img{*/
        /*height: 60%;*/
        /*width: 60%;*/

    /*}*/

    /*.icon-microphone {*/


    /*}*/

    /*.outer {*/
        /*width: 50px;*/
        /*height: 50px;*/
        /*-webkit-transform: scale(1);*/
        /*border-radius: 100%;*/
        /*background-color: rgba(0, 117, 156, 0.71);*/
        /*opacity: 0;*/
        /*!*border: 1px solid #ffffff;*!*/
        /*position:absolute;*/
        /*top: 15px;*/
        /*right: calc(50% - 25px);*/
        /*z-index: 1;*/
        /*!*transition: 1.5s all ease;*!*/
        /*-webkit-animation: woong 1.8s infinite;*/
        /*-webkit-animation-delay: 0.9s;*/
    /*}*/
    /*.outer-2 {*/
        /*width: 50px;*/
        /*height: 50px;*/
        /*-webkit-transform: scale(1);*/
        /*border-radius: 100%;*/
        /*background-color: rgba(0, 117, 156, 0.71);*/
        /*z-index: 1;*/
        /*position:absolute;*/
        /*top: 15px;*/
        /*right: calc(50% - 25px);*/
        /*!*transition: 1.5s all ease;*!*/
        /*-webkit-animation: woong-2 1.8s infinite;*/
        /*box-sizing: content-box;*/

    /*}*/

    .recorder-container {
        width: 100%;
        height: 100%;
        position:relative;
        display:flex;
        justify-content: center;
        align-items: center;



    }
    .recorder-container .mic-bg {
        width: 2rem;
        height: 2rem;
        background-color: #00759c;
        border-radius: 100%;
        display:flex;
        justify-content: center;
        align-items: center;
        z-index:2;
    }

    .recorder-container img{
        height: 60%;
        width: 60%;

    }

    .icon-microphone {


    }

    .outer {
        width: 2.1rem;
        height: 2.1rem;
        -webkit-transform: scale(1);
        border-radius: 100%;
        background-color: rgba(0, 117, 156, 0.71);
        opacity: 0;
        /*border: 1px solid #ffffff;*/
        position:absolute;
        top: 0.5125rem;
        right: calc(50% - 1.05rem);
        z-index: 1;
        /*transition: 1.5s all ease;*/
        -webkit-animation: woong 1.8s infinite;
        -webkit-animation-delay: 0.9s;
    }
    .outer-2 {
        width: 2.1rem;
        height: 2.1rem;
        -webkit-transform: scale(1);
        border-radius: 100%;
        background-color: rgba(0, 117, 156, 0.71);
        z-index: 1;
        position:absolute;
        top: 0.5125rem;
        right: calc(50% - 1.05rem);
        /*transition: 1.5s all ease;*/
        -webkit-animation: woong-2 1.8s infinite;
        box-sizing: content-box;

    }

}


@-webkit-keyframes woong {
    0% {
        -webkit-trasform: scale(1);
        opacity: 1;
    }
    50% {
        /*-webkit-transform: scale(1.5);*/
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1.6);
        opacity: 0;
    }
}
@-webkit-keyframes woong-2 {
    0% {
        -webkit-transform: scale(1);
        opacity: 0.6;
    }
    50% {
        /*-webkit-transform: scale(1.4);*/
        opacity: 0.5;
    }
    100% {
        -webkit-transform: scale(1.6);
        opacity: 0;

    }
}


.fake-text {
    min-height: 40px;
}

.fake-text strong{
    color:#f07e23;
    font-weight: initial;
}

.fake-text-2 {
    height: 4.42rem;
    font-size: 1.5rem;
    font-weight: 500;
    padding: 0 1.6rem;
}
.fake-text-2 strong{
    color:#f07e23;
    font-weight: initial;
}


/*.typing-game-04 .sentence-list li .input {*/
    /*display: block;*/
    /*border-radius: 1.06rem;*/
    /*width: 100%;*/
    /*background-color: #f2f3f6;*/
    /*border: 2px solid #d7d8db;*/
    /*height: 4.42rem;*/
    /*font-family: Roboto, sans-serif;*/
    /*font-size: 2.02rem;*/
    /*font-weight: 500;*/
    /*padding: 0 1.6rem;*/
    /*color:transparent;*/
    /*caret-color:#222;*/
/*}*/


/***
타이핑 게임
**/

.white-space {
    width: 10px;
}
.white-space.sentence {
    width: 5.1px;
}

.white-space-2 {
    width: 4px;
}
.wrong-space-2 {
    width: 4px;
    height: 3rem;
    background-color: #f07e23;
}

.wrong-space {
    width: 10px;
    height: 3rem;
    background-color: #f07e23;
}
.wrong-space.sentence {
    width: 5.1px;
}

@media screen and (max-width: 1600px){

    .white-space {
        width: 7.1px;
    }
    .white-space.sentence {
        width: 4.5px;
    }

    .white-space-2 {
        width: 4px;
    }
    .wrong-space-2 {
        width: 4px;
        height: 3rem;
        background-color: #f07e23;
    }

    .wrong-space {
        width: 7.1px;
        height: 3rem;
        background-color: #f07e23;
    }
    .wrong-space.sentence {
        width: 4.5px;
    }
}

.typing-game-01 .btn-play-sound {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 4.42rem;
    height: 4.42rem;
    border: 0 none;
    background: 0 0;
    padding: 0;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 4.42rem 4.42rem;
    text-indent: -9999px;
    transition: all .3s ease
}

.typing-game-01 .btn-play-sound.disabled,
.typing-game-01 .btn-play-sound:disabled {
    opacity: .65
}

.typing-game-01 .btn-play-sound:not([disabled]):not(.disabled) {
    cursor: pointer
}


.typing-game-01 .btn-play-sound.dark {
    background-color: transparent;
    background-image: url(../images/lecture/btn-play-sound-dark.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 3.24rem 3.24rem
}

.typing-game-01 .btn-play-sound.dark:hover {
    background-image: url(../images/lecture/btn-play-sound-dark-hover.png)
}



.typing-game-01 {
    flex-direction: column;
    z-index: 10
}


.typing-game-01 .tp01-s-correct {
    color: #00759c;
}
.typing-game-01 .tp01-s-wrong {
    color:#f07e23;
}

.typing-game-01:after {
    bottom: 0;
    left: 0;
    width: 100%;
    position: absolute;
    content: '';
    display: block;
    height: 3.36rem;
    background-color: #024050
}

.typing-game-01 .typing-sentence {
    margin-bottom: 10rem
}

.typing-game-01 .typing-sentence .english {
    color: #222;
    /*font-family: Roboto, sans-serif;*/
    font-size: 5.3336rem;
    line-height: 1em
}
.typing-game-01 .typing-sentence .english.sentence {
    font-size: 2.5rem;
    text-align:center;
    max-width: 55vw;
}

.typing-game-01 .typing-sentence .korean {
    line-height: 1em;
    color: #00759c;
    font-weight: 500;
    font-size: 3.2rem;
    text-align: center
}

.typing-game-01 .input-wrap {
    width: 59.2rem;
    height: 6.02rem;
    position: absolute;
    border: 2px solid #dcdde1;
    border-radius: .5rem;
    left: 50%;
    transform: translateX(-50%);
    bottom: 15.733rem
}

.typing-game-01 .input-wrap.sentence {
    width: 100vw;
    height: 6.02rem;
    position: absolute;
    border: 2px solid #dcdde1;
    border-left: 0;
    border-right: 0;
    border-radius: 0;
    bottom: 15.733rem
}



.typing-game-01 .input-wrap>input {
    width: 100%;
    height: 100%;
    display: block;
    /*color: transparent;*/
    text-align: center;
    font-size: 2.66rem;
    position: relative;
    z-index: 2;
    background-color: transparent;
    border: 0 none;
    caret-color:#222
}
.typing-game-01 .input-wrap.sentence>input {
    font-size: 1.5rem;
}

.typing-game-01 .input-wrap>input:focus {
    outline: 0
}

.typing-game-01 .input-wrap .input-visual {
    color: #222;
    font-size: 2.66rem;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;
}

.typing-game-01 .input-wrap.sentence .input-visual {
    font-size: 1.5rem;
}
.typing-game-01 .input-wrap .input-visual .correct {
    color: #222
}

.typing-game-01 .input-wrap .input-visual .wrong {
    color: #f07e23
}

.typing-game-01 .input-wrap .input-visual .cursor {
    display: inline-block;
    width: 1px;
    height: 2.66rem;
    background: #222;
    animation: blink 1s linear infinite
}

.typing-game-01 .keyboard {
    position: absolute;
    width: 60.266rem;
    height: 18.24rem;
    bottom: -3.4666rem;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../images/keyboard/bg-keyboard.png);
    background-size: 100% 100%;
    z-index: 110
}

.typing-game-01 .keyboard button {
    position: absolute;
    display: block;
    text-indent: -9999px;
    border: 0 none;
    background: 0 0;
    width: 3.4666rem;
    height: 3.52rem
}

.typing-game-01 .keyboard button.rows-1 {
    top: 1.706664rem
}

.typing-game-01 .keyboard button.rows-2 {
    top: 5.49336rem
}

.typing-game-01 .keyboard button.rows-3 {
    top: 9.28rem
}

.typing-game-01 .keyboard button.rows-4 {
    top: 13.06664rem
}

.typing-game-01 .keyboard button[data-keycode="16"]:not(.right),
.typing-game-01 .keyboard button[data-keycode="17"]:not(.right),
.typing-game-01 .keyboard button[data-keycode="20"],
.typing-game-01 .keyboard button[data-keycode="9"] {
    left: 1.706664rem
}

.typing-game-01 .keyboard button[data-keycode="9"] {
    width: 5.54664rem
}

.typing-game-01 .keyboard button[data-keycode="13"] {
    width: 7.46664rem;
    height: 7.253336rem;
    left: 51.2rem
}

.typing-game-01 .keyboard button[data-keycode="16"] {
    width: 9.33336rem
}

.typing-game-01 .keyboard button[data-keycode="16"].right {
    left: 49.33336rem
}

.typing-game-01 .keyboard button[data-keycode="17"] {
    width: 5.5466rem
}

.typing-game-01 .keyboard button[data-keycode="17"].right {
    left: 53.12rem
}

.typing-game-01 .keyboard button[data-keycode="18"] {
    width: 5.5466rem;
    left: 7.573336rem
}

.typing-game-01 .keyboard button[data-keycode="18"].right {
    left: 47.253336rem
}

.typing-game-01 .keyboard button[data-keycode="20"] {
    width: 7.413336rem
}

.typing-game-01 .keyboard button[data-keycode="32"] {
    width: 33.38664rem;
    left: 13.49rem
}

.typing-game-01 .keyboard button[data-keycode="59"] {
    left: 43.568rem
}

.typing-game-01 .keyboard button[data-keycode="65"] {
    left: 9.492rem
}

.typing-game-01 .keyboard button[data-keycode="66"],
.typing-game-01 .keyboard button[data-keycode="89"] {
    left: 26.56rem
}

.typing-game-01 .keyboard button[data-keycode="67"],
.typing-game-01 .keyboard button[data-keycode="82"] {
    left: 18.986rem
}

.typing-game-01 .keyboard button[data-keycode="68"] {
    left: 17.066rem
}

.typing-game-01 .keyboard button[data-keycode="69"],
.typing-game-01 .keyboard button[data-keycode="88"] {
    left: 15.2rem
}

.typing-game-01 .keyboard button[data-keycode="70"] {
    left: 20.853rem
}

.typing-game-01 .keyboard button[data-keycode="71"] {
    left: 24.64rem
}

.typing-game-01 .keyboard button[data-keycode="72"] {
    left: 28.42664rem
}

.typing-game-01 .keyboard button[data-keycode="73"],
.typing-game-01 .keyboard button[data-keycode="77"] {
    left: 34.13336rem
}

.typing-game-01 .keyboard button[data-keycode="74"] {
    left: 32.2133rem
}

.typing-game-01 .keyboard button[data-keycode="75"] {
    left: 36rem
}

.typing-game-01 .keyboard button[data-keycode="76"] {
    left: 39.7864rem
}

.typing-game-01 .keyboard button[data-keycode="78"],
.typing-game-01 .keyboard button[data-keycode="85"] {
    left: 30.346rem
}

.typing-game-01 .keyboard button[data-keycode="188"],
.typing-game-01 .keyboard button[data-keycode="79"] {
    left: 37.92rem
}

.typing-game-01 .keyboard button[data-keycode="190"],
.typing-game-01 .keyboard button[data-keycode="80"] {
    left: 41.70664rem
}

.typing-game-01 .keyboard button[data-keycode="81"] {
    left: 7.6266rem
}

.typing-game-01 .keyboard button[data-keycode="83"] {
    left: 13.28rem
}

.typing-game-01 .keyboard button[data-keycode="84"],
.typing-game-01 .keyboard button[data-keycode="86"] {
    left: 22.77336rem
}

.typing-game-01 .keyboard button[data-keycode="87"],
.typing-game-01 .keyboard button[data-keycode="90"] {
    left: 11.4133rem
}

.typing-game-01 .keyboard button[data-keycode="191"],
.typing-game-01 .keyboard button[data-keycode="219"] {
    left: 45.493rem
}

.typing-game-01 .keyboard button[data-keycode="221"] {
    left: 49.28rem
}

.typing-game-01 .keyboard button[data-keycode="222"] {
    left: 47.36rem
}

.typing-game-01 .keyboard button[data-keycode="9"] {
    background-image: url(../images/keyboard/key_9.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="9"].pressed {
    background-image: url(../images/keyboard/key_9_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="13"] {
    background-image: url(../images/keyboard/key_13.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="13"].pressed {
    background-image: url(../images/keyboard/key_13_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="16"] {
    background-image: url(../images/keyboard/key_16.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="16"].right {
    background-image: url(../images/keyboard/key_16_right.png)
}

.typing-game-01 .keyboard button[data-keycode="16"].pressed {
    background-image: url(../images/keyboard/key_16_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="16"].pressed.right {
    background-image: url(../images/keyboard/key_16_right_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="17"] {
    background-image: url(../images/keyboard/key_17.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="17"].right {
    background-image: url(../images/keyboard/key_17_right.png)
}

.typing-game-01 .keyboard button[data-keycode="17"].pressed {
    background-image: url(../images/keyboard/key_17_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="17"].pressed.right {
    background-image: url(../images/keyboard/key_17_right_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="18"] {
    background-image: url(../images/keyboard/key_18.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="18"].right {
    background-image: url(../images/keyboard/key_18_right.png)
}

.typing-game-01 .keyboard button[data-keycode="18"].pressed {
    background-image: url(../images/keyboard/key_18_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="18"].pressed.right {
    background-image: url(../images/keyboard/key_18_right_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="20"] {
    background-image: url(../images/keyboard/key_20.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="20"].pressed {
    background-image: url(../images/keyboard/key_20_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="32"] {
    background-image: url(../images/keyboard/key_32.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="32"].pressed {
    background-image: url(../images/keyboard/key_32_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="59"] {
    background-image: url(../images/keyboard/key_59.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="59"].pressed {
    background-image: url(../images/keyboard/key_59_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="65"] {
    background-image: url(../images/keyboard/key_65.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="65"].pressed {
    background-image: url(../images/keyboard/key_65_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="66"] {
    background-image: url(../images/keyboard/key_66.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="66"].pressed {
    background-image: url(../images/keyboard/key_66_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="67"] {
    background-image: url(../images/keyboard/key_67.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="67"].pressed {
    background-image: url(../images/keyboard/key_67_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="68"] {
    background-image: url(../images/keyboard/key_68.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="68"].pressed {
    background-image: url(../images/keyboard/key_68_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="69"] {
    background-image: url(../images/keyboard/key_69.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="69"].pressed {
    background-image: url(../images/keyboard/key_69_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="70"] {
    background-image: url(../images/keyboard/key_70.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="70"].pressed {
    background-image: url(../images/keyboard/key_70_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="71"] {
    background-image: url(../images/keyboard/key_71.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="71"].pressed {
    background-image: url(../images/keyboard/key_71_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="72"] {
    background-image: url(../images/keyboard/key_72.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="72"].pressed {
    background-image: url(../images/keyboard/key_72_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="73"] {
    background-image: url(../images/keyboard/key_73.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="73"].pressed {
    background-image: url(../images/keyboard/key_73_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="74"] {
    background-image: url(../images/keyboard/key_74.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="74"].pressed {
    background-image: url(../images/keyboard/key_74_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="75"] {
    background-image: url(../images/keyboard/key_75.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="75"].pressed {
    background-image: url(../images/keyboard/key_75_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="76"] {
    background-image: url(../images/keyboard/key_76.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="76"].pressed {
    background-image: url(../images/keyboard/key_76_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="77"] {
    background-image: url(../images/keyboard/key_77.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="77"].pressed {
    background-image: url(../images/keyboard/key_77_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="78"] {
    background-image: url(../images/keyboard/key_78.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="78"].pressed {
    background-image: url(../images/keyboard/key_78_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="79"] {
    background-image: url(../images/keyboard/key_79.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="79"].pressed {
    background-image: url(../images/keyboard/key_79_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="80"] {
    background-image: url(../images/keyboard/key_80.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="80"].pressed {
    background-image: url(../images/keyboard/key_80_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="81"] {
    background-image: url(../images/keyboard/key_81.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="81"].pressed {
    background-image: url(../images/keyboard/key_81_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="82"] {
    background-image: url(../images/keyboard/key_82.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="82"].pressed {
    background-image: url(../images/keyboard/key_82_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="83"] {
    background-image: url(../images/keyboard/key_83.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="83"].pressed {
    background-image: url(../images/keyboard/key_83_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="84"] {
    background-image: url(../images/keyboard/key_84.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="84"].pressed {
    background-image: url(../images/keyboard/key_84_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="85"] {
    background-image: url(../images/keyboard/key_85.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="85"].pressed {
    background-image: url(../images/keyboard/key_85_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="86"] {
    background-image: url(../images/keyboard/key_86.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="86"].pressed {
    background-image: url(../images/keyboard/key_86_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="87"] {
    background-image: url(../images/keyboard/key_87.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="87"].pressed {
    background-image: url(../images/keyboard/key_87_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="88"] {
    background-image: url(../images/keyboard/key_88.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}


.typing-game-01 .keyboard button[data-keycode="88"].pressed {
    background-image: url(../images/keyboard/key_88_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="89"] {
    background-image: url(../images/keyboard/key_89.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="89"].pressed {
    background-image: url(../images/keyboard/key_89_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="90"] {
    background-image: url(../images/keyboard/key_90.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="90"].pressed {
    background-image: url(../images/keyboard/key_90_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="188"] {
    background-image: url(../images/keyboard/key_188.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="188"].pressed {
    background-image: url(../images/keyboard/key_188_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="190"] {
    background-image: url(../images/keyboard/key_190.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="190"].pressed {
    background-image: url(../images/keyboard/key_190_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="191"] {
    background-image: url(../images/keyboard/key_191.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="191"].pressed {
    background-image: url(../images/keyboard/key_191_selected.png)
}
.typing-game-01 .keyboard button[data-keycode="219"] {
    background-image: url(../images/keyboard/key_219.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="219"].pressed {
    background-image: url(../images/keyboard/key_219_selected.png)
}


.typing-game-01 .keyboard button[data-keycode="221"] {
    background-image: url(../images/keyboard/key_221.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="221"].pressed {
    background-image: url(../images/keyboard/key_221_selected.png)
}

.typing-game-01 .keyboard button[data-keycode="222"] {
    background-image: url(../images/keyboard/key_222.png);
    background-size: 100% 100%;
    background-repeat: no-repeat
}

.typing-game-01 .keyboard button[data-keycode="222"].pressed {
    background-image: url(../images/keyboard/key_222_selected.png)
}


@media screen and (max-width: 1600px){

    .typing-game-01 .btn-play-sound {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 4.42rem;
        height: 4.42rem;
        border: 0 none;
        background: 0 0;
        padding: 0;
        background-color: transparent;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 4.42rem 4.42rem;
        text-indent: -9999px;
        transition: all .3s ease
    }

    .typing-game-01 .btn-play-sound.disabled,
    .typing-game-01 .btn-play-sound:disabled {
        opacity: .65
    }

    .typing-game-01 .btn-play-sound:not([disabled]):not(.disabled) {
        cursor: pointer
    }


    .typing-game-01 .btn-play-sound.dark {
        background-color: transparent;
        background-image: url(../images/lecture/btn-play-sound-dark.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 3.24rem 3.24rem;
        margin-top:10px;
    }

    .typing-game-01 .btn-play-sound.dark:hover {
        background-image: url(../images/lecture/btn-play-sound-dark-hover.png)
    }



    .typing-game-01 {
        flex-direction: column;
        z-index: 10
    }

    .typing-game-01:after {
        bottom: 0;
        left: 0;
        width: 100%;
        position: absolute;
        content: '';
        display: block;
        height: 3.36rem;
        background-color: #024050
    }

    .typing-game-01 .typing-sentence {
        margin-bottom: 13rem;
    }

    .typing-game-01 .typing-sentence .english {
        color: #222;
        /*font-family: Roboto, sans-serif;*/
        font-size: 3rem;
        line-height: 1em
    }
    .typing-game-01 .typing-sentence .english.sentence {
        font-size: 2rem;
        text-align:center;
        max-width: 55vw;
    }

    .typing-game-01 .typing-sentence .korean {
        line-height: 1em;
        color: #00759c;
        font-weight: 500;
        font-size: 2rem;
        text-align: center;
        transform:translateY(-30px);
    }

    .typing-game-01 .input-wrap {
        width: 50rem;
        height: 4rem;
        position: absolute;
        border: 2px solid #dcdde1;
        border-radius: .5rem;
        left: 50%;
        transform: translateX(-50%);
        bottom: 13rem;
    }


    .typing-game-01 .input-wrap.sentence {
        width: 100vw;
        height: 5rem;
        position: absolute;
        border: 2px solid #dcdde1;
        border-left: 0;
        border-right: 0;
        border-radius: 0;
        bottom: 13rem
    }



    .typing-game-01 .input-wrap>input {
        width: 100%;
        height: 100%;
        display: block;
        /*color: transparent;*/
        text-align: center;
        font-size: 2rem;
        position: relative;
        z-index: 2;
        background-color: transparent;
        border: 0 none;
        caret-color:#222
    }
    .typing-game-01 .input-wrap.sentence>input {
        font-size: 1.2rem;
    }

    .typing-game-01 .input-wrap>input:focus {
        outline: 0
    }

    .typing-game-01 .input-wrap .input-visual {
        color: #222;
        font-size: 2rem;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: .5rem;
    }

    .typing-game-01 .input-wrap.sentence .input-visual {
        font-size: 1.2rem;
    }
    .typing-game-01 .input-wrap .input-visual .correct {
        color: #222
    }

    .typing-game-01 .input-wrap .input-visual .wrong {
        color: #f07e23
    }

    .typing-game-01 .input-wrap .input-visual .cursor {
        display: inline-block;
        width: 1px;
        height: 2.66rem;
        background: #222;
        animation: blink 1s linear infinite
    }

    .typing-game-01 .keyboard {
        position: absolute;
        width: 48.21rem;
        height: 14.56rem;
        bottom: -2.77rem;
        left: 50%;
        transform: translateX(-50%);
        background-image: url(../images/keyboard/bg-keyboard.png);
        background-size: 100% 100%;
        z-index: 110;
    }

    .typing-game-01 .keyboard button {
        position: absolute;
        display: block;
        text-indent: -9999px;
        border: 0 none;
        background: 0 0;
        width: 2.77rem;
        height: 2.82rem
    }

    .typing-game-01 .keyboard button.rows-1 {
        top: 1.36rem
    }

    .typing-game-01 .keyboard button.rows-2 {
        top: 4.4rem
    }

    .typing-game-01 .keyboard button.rows-3 {
        top: 7.42rem
    }

    .typing-game-01 .keyboard button.rows-4 {
        top: 10.45rem
    }

    .typing-game-01 .keyboard button[data-keycode="16"]:not(.right),
    .typing-game-01 .keyboard button[data-keycode="17"]:not(.right),
    .typing-game-01 .keyboard button[data-keycode="20"],
    .typing-game-01 .keyboard button[data-keycode="9"] {
        left: 1.36rem
    }

    .typing-game-01 .keyboard button[data-keycode="9"] {
        width: 4.43rem
    }

    .typing-game-01 .keyboard button[data-keycode="13"] {
        width: 5.95rem;
        height: 5.8rem;
        left: 40.96rem
    }

    .typing-game-01 .keyboard button[data-keycode="16"] {
        width: 7.46rem
    }

    .typing-game-01 .keyboard button[data-keycode="16"].right {
        left: 39.46rem
    }

    .typing-game-01 .keyboard button[data-keycode="17"] {
        width: 4.432rem
    }

    .typing-game-01 .keyboard button[data-keycode="17"].right {
        left: 42.5rem
    }

    .typing-game-01 .keyboard button[data-keycode="18"] {
        width: 4.43rem;
        left: 6.05rem
    }

    .typing-game-01 .keyboard button[data-keycode="18"].right {
        left: 37.80rem
    }

    .typing-game-01 .keyboard button[data-keycode="20"] {
        width: 5.93rem
    }

    .typing-game-01 .keyboard button[data-keycode="32"] {
        width: 26.71rem;
        left: 10.79rem
    }

    .typing-game-01 .keyboard button[data-keycode="59"] {
        left: 34.85rem
    }

    .typing-game-01 .keyboard button[data-keycode="65"] {
        left: 7.59rem
    }

    .typing-game-01 .keyboard button[data-keycode="66"],
    .typing-game-01 .keyboard button[data-keycode="89"] {
        left: 21.248rem
    }

    .typing-game-01 .keyboard button[data-keycode="67"],
    .typing-game-01 .keyboard button[data-keycode="82"] {
        left: 15.19rem
    }

    .typing-game-01 .keyboard button[data-keycode="68"] {
        left: 13.65rem
    }

    .typing-game-01 .keyboard button[data-keycode="69"],
    .typing-game-01 .keyboard button[data-keycode="88"] {
        left: 12.16rem
    }

    .typing-game-01 .keyboard button[data-keycode="70"] {
        left: 16.68rem
    }

    .typing-game-01 .keyboard button[data-keycode="71"] {
        left: 19.71rem
    }

    .typing-game-01 .keyboard button[data-keycode="72"] {
        left: 22.74rem
    }

    .typing-game-01 .keyboard button[data-keycode="73"],
    .typing-game-01 .keyboard button[data-keycode="77"] {
        left: 27.31rem
    }

    .typing-game-01 .keyboard button[data-keycode="74"] {
        left: 25.77rem
    }

    .typing-game-01 .keyboard button[data-keycode="75"] {
        left: 28.8rem
    }

    .typing-game-01 .keyboard button[data-keycode="76"] {
        left: 31.83rem
    }

    .typing-game-01 .keyboard button[data-keycode="78"],
    .typing-game-01 .keyboard button[data-keycode="85"] {
        left: 24.27rem
    }

    .typing-game-01 .keyboard button[data-keycode="188"],
    .typing-game-01 .keyboard button[data-keycode="79"] {
        left: 30.336rem
    }

    .typing-game-01 .keyboard button[data-keycode="190"],
    .typing-game-01 .keyboard button[data-keycode="80"] {
        left: 33.36rem
    }

    .typing-game-01 .keyboard button[data-keycode="81"] {
        left: 6.10rem
    }

    .typing-game-01 .keyboard button[data-keycode="83"] {
        left: 10.624rem
    }

    .typing-game-01 .keyboard button[data-keycode="84"],
    .typing-game-01 .keyboard button[data-keycode="86"] {
        left: 18.22rem
    }

    .typing-game-01 .keyboard button[data-keycode="87"],
    .typing-game-01 .keyboard button[data-keycode="90"] {
        left: 9.13rem
    }

    .typing-game-01 .keyboard button[data-keycode="191"],
    .typing-game-01 .keyboard button[data-keycode="219"] {
        left: 36.39rem
    }

    .typing-game-01 .keyboard button[data-keycode="221"] {
        left: 39.42rem
    }

    .typing-game-01 .keyboard button[data-keycode="222"] {
        left: 37.89rem
    }


}



.typing-game-02 .game-word-list {
    list-style: none;
    padding: 40px 0 0 0;
    margin: 0;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 75.33333rem;

}

.typing-game-02 .game-word-list li {
    border: 3px solid #15a6ca;
    background-color: #15a6ca;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    position: relative;
    width: 14.1rem;
    height: 8.5rem;
    border-radius: 1.06667rem;
    margin: .66667rem;
    font-size: 2rem;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    text-align: center;
}

.typing-game-02 .game-word-list li.checked {
    border-color: #ecedf1;
    background-color: #fff;
    color: #d6d6d9
}

.typing-game-02 .game-word-list li.checked:after {
    content: '';
    width: 4rem;
    height: 3rem;
    background-color: transparent;
    background-image: url(../images/lecture/word-clear.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.typing-game-02 .typing-input {
    position: absolute;
    bottom: 2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 59.2rem;
    height: 6.02rem;
    border: 2px solid #dcdde1;
    border-radius: .5rem;
    text-align: center;
    text-align: center;
    font-size: 2.5rem
}

.typing-game-02 .typing-input:focus {
    outline: 0
}

.typing-game-02 .score-wrap {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 30rem;
    height: 30rem;
    border-radius: 100%;
    background-color: #15a6ca;
    box-shadow: 0 1.46667rem 1.6rem 0 rgba(0, 0, 0, .16);
    position: relative;
    margin-bottom:6.5rem;
}

.typing-game-02 .score-wrap .btn-ranking {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    display: block;
    position: absolute;
    bottom: 3.33333rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    background-image: url(../images/lecture/btn-ranking.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    transition: all .3s ease;
    text-indent: -9999px;
    border: 0 none;
    padding: 0;
    background-color: transparent;
    background-size: 100% 100%;
    width: 9rem;
    height: 3.5rem
}

.typing-game-02 .score-wrap .btn-ranking.disabled,
.typing-game-02 .score-wrap .btn-ranking:disabled {
    opacity: .65
}

.typing-game-02 .score-wrap .btn-ranking:not([disabled]):not(.disabled) {
    cursor: pointer
}

.typing-game-02 .score-wrap .btn-ranking:hover {
    background-image: url(../images/lecture/btn-ranking-hover.png)
}

.typing-game-02 .score-wrap .t-score {
    color: #fcfe00;
    font-size: 6rem;
    font-weight: 700;
    font-family: Roboto, sans-serif;
    text-align: center;
    display: block;
    line-height: 1em;
    margin-bottom: 1rem
}

.typing-game-02 .score-wrap .text {
    color: #fcfe00;
    font-family: Roboto, sans-serif;
    text-align: center;
    display: block;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1em
}

.typing-game-02 .ranking-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999
}

.typing-game-02 .ranking-popup:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1000;
    background-color: rgba(0, 0, 0, .4)
}

.typing-game-02 .ranking-popup .ranking-popup-wrap {
    position: absolute;
    width: 36rem;
    height: 80vh;
    border-radius: 1.5rem;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    z-index: 1003;
    flex-direction: column;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%)
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header {
    border-top-left-radius: 2rem;
    border-top-right-radius: 2rem;
    flex-shrink: 0;
    height: 3.5rem;
    background-color: #fbba23;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    padding: 0 2rem 0 2.66667rem
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .title {
    color: #252421;
    font-size: 1.46667rem;
    font-weight: 700
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    margin-left: auto;
    background-color: transparent;
    background-image: url(../images/lecture/btn-ranking-close.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 1.26667rem;
    height: 1.26667rem;
    padding: 0;
    border: 0 none;
    background-size: 100% 100%;
    transition: all .3s ease;
    text-indent: -9999px
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close.disabled,
.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close:disabled {
    opacity: .65
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close:not([disabled]):not(.disabled) {
    cursor: pointer
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close:hover {
    opacity: .75
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body {
    flex-grow: 1;
    background-color: #fff;
    border-bottom-left-radius: 2rem;
    border-bottom-right-radius: 2rem;
    padding: 2.4rem 3.06667rem;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-direction: column;
    overflow: hidden
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab {
    height: 4.46667rem;
    flex-shrink: 0;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li {
    flex-grow: 1;
    position: relative
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li+li {
    margin-left: -2px
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li:nth-child(1).active a {
    border-bottom-left-radius: 0
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li:nth-child(2).active a {
    border-bottom-right-radius: 0
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li.active {
    z-index: 3
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li.active a {
    background-color: #fff;
    border-color: #fbba23;
    border-bottom-color: #fff;
    color: #161616
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li a {
    border: 2px solid #ddd;
    color: #828282;
    font-size: 1.2rem;
    font-weight: 500;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: .53333rem
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score {
    flex-shrink: 0;
    margin-top: -.53333rem;
    margin-bottom: 1.33333rem;
    border-radius: .53333rem;
    border: 2px solid #fbba23;
    height: 10.6rem;
    background-color: #fff;
    z-index: 2;
    box-shadow: 0 7px 8px 0 rgba(0, 0, 0, .1), inset 0 0 0 2px rgba(251, 186, 35, .004);
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .thumb {
    width: 4.9rem;
    height: 4.9rem;
    display: block;
    border-radius: 50%;
    background-color:#fff;
    border:6px solid #E8E8E8;
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info {
    margin-left: 1.13333rem
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .name {
    color: #333;
    font-size: 1.33333rem;
    font-weight: 400;
    line-height: 1em;
    margin-bottom: .66667rem
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .t-score {
    color: #484848;
    font-size: 1.33333rem;
    font-family: Roboto, 'Noto Sans KR', sans-serif;
    font-weight: 400
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .t-score strong {
    font-size: 1.93333rem;
    font-weight: 700
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .t-score strong.color {
    color: #ff5a00
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-list {
    flex-grow: 1;
    overflow-y: auto
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list {
    overflow-y: auto;
    flex-grow: 1
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li {
    height: 5.93333rem;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #ebebeb;
    padding: 0 2rem 0 .66667rem
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .rank {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.06667rem;
    height: 2.06667rem;
    background-color: grey;
    color: #fff;
    font-size: 1.06667rem;
    font-family: Roboto, sans-serif;
    margin-right: .86667rem;
    flex-shrink: 0;
    border-radius: 100%
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .rank.top-rank {
    background-color: #ff9e00
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .thumb {
    width: 3rem;
    height: 3rem;
    display: block;
    flex-shrink: 0;
    margin-right: .5rem;
    border-radius: 50%;
    background-color: #F1ECE3;
    border: 2px solid #E8E8E8;

}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .name {
    flex-grow: 1;
    color: #373636;
    font-size: 1.2rem;
    font-weight: 400
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .t-score {
    color: #60605f;
    font-size: 1.2rem;
    font-weight: 700
}

.typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .t-score strong {
    color: #ff5a00;
    font-family: Roboto, sans-serif
}
.typing-game-02-footer {
    align-self:center;
}

.typing-game-02-footer .icon-progress-clock {
    background-color: transparent;
    background-image: url(../images/lecture/progress-clock.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 2.3rem;
    height: 2.666rem;
    background-size: 100% 100%;
    margin-right: 1rem
}

.typing-game-02-footer .footer-progress {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%
}

.typing-game-02-footer .footer-progress .remain-time {
    color: #fff;
    font-size: 1.06667rem;
    margin-left: 1rem;
    font-weight: 500
}

.typing-game-02-footer .typing02-progress {
    width: 48.1rem;
    height: 2.18rem;
    position: relative;
    background-color: transparent;
    background-image: url(../images/lecture/bg-progress.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100% 100%
}

.typing-game-02-footer .typing02-progress .progress-inner {
    position: absolute;
    left: 0;
    top: 0;
    height: 2.18rem;
    background-color: transparent;
    background-image: url(../images/lecture/bg-progress-inner.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 48.2133rem 2.29rem;
    background-position: top left;
    border-top-right-radius: .33333rem;
    border-bottom-right-radius: .33333rem
}

.typing-game-02-footer .btn-next-dark,
.typing-game-02-footer .btn-retry {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    display: block;
    width: 2rem!important;
    height: 3.66667rem!important;
    text-indent: -9999px;
    border: 0 none;
    padding: 0;
    background-color: transparent!important;
    margin: 0 4.53333rem
}

.typing-game-02-footer .btn-next-dark.disabled,
.typing-game-02-footer .btn-next-dark:disabled,
.typing-game-02-footer .btn-retry.disabled,
.typing-game-02-footer .btn-retry:disabled {
    opacity: .65
}

.typing-game-02-footer .btn-next-dark:not([disabled]):not(.disabled),
.typing-game-02-footer .btn-retry:not([disabled]):not(.disabled) {
    cursor: pointer
}

@media screen and (max-width: 1600px){



    .typing-game-02 .game-word-list {
        list-style: none;
        padding: 0;
        margin: 0;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 55rem;
    }

    .typing-game-02 .game-word-list li {
        border: 3px solid #15a6ca;
        background-color: #15a6ca;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        position: relative;
        width: 12rem;
        height: 6rem;
        border-radius: 0.8rem;
        margin: 0.2rem;
        font-size: 1.5rem;
        font-family: CircularStd, sans-serif;
        font-weight: 500;
        text-align: center;
    }

    .typing-game-02 .game-word-list li.checked {
        border-color: #ecedf1;
        background-color: #fff;
        color: #d6d6d9
    }

    .typing-game-02 .game-word-list li.checked:after {
        content: '';
        width: 4rem;
        height: 3rem;
        background-color: transparent;
        background-image: url(../images/lecture/word-clear.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .typing-game-02 .typing-input {
        position: absolute;
        bottom: 2rem;
        left: 50%;
        transform: translateX(-50%);
        width: 50rem;
        height: 5rem;
        border: 2px solid #dcdde1;
        border-radius: .5rem;
        text-align: center;
        text-align: center;
        font-size: 2rem
    }

    .typing-game-02 .typing-input:focus {
        outline: 0
    }

    .typing-game-02 .score-wrap {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 20rem;
        height: 20rem;
        border-radius: 100%;
        background-color: #15a6ca;
        box-shadow: 0 1.46667rem 1.6rem 0 rgba(0, 0, 0, .16);
        position: relative;
        margin-bottom:6.5rem;
    }

    .typing-game-02 .score-wrap .btn-ranking {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        display: block;
        position: absolute;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: transparent;
        background-image: url(../images/lecture/btn-ranking.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        transition: all .3s ease;
        text-indent: -9999px;
        border: 0 none;
        padding: 0;
        background-color: transparent;
        background-size: 100% 100%;
        width: 9rem;
        height: 3.5rem
    }

    .typing-game-02 .score-wrap .btn-ranking.disabled,
    .typing-game-02 .score-wrap .btn-ranking:disabled {
        opacity: .65
    }

    .typing-game-02 .score-wrap .btn-ranking:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .typing-game-02 .score-wrap .btn-ranking:hover {
        background-image: url(../images/lecture/btn-ranking-hover.png)
    }

    .typing-game-02 .score-wrap .t-score {
        color: #fcfe00;
        font-size: 4rem;
        font-weight: 700;
        font-family: Roboto, sans-serif;
        text-align: center;
        display: block;
        line-height: 1em;
        margin-bottom: 0;
    }

    .typing-game-02 .score-wrap .text {
        color: #fcfe00;
        font-family: Roboto, sans-serif;
        text-align: center;
        display: block;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 1em
    }

    .typing-game-02 .ranking-popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 999
    }

    .typing-game-02 .ranking-popup:before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: rgba(0, 0, 0, .4)
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap {
        position: absolute;
        width: 36rem;
        height: 80vh;
        border-radius: 1.5rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        z-index: 1003;
        flex-direction: column;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%)
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header {
        border-top-left-radius: 2rem;
        border-top-right-radius: 2rem;
        flex-shrink: 0;
        height: 3.5rem;
        background-color: #fbba23;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        padding: 0 2rem 0 2.66667rem
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .title {
        color: #252421;
        font-size: 1.46667rem;
        font-weight: 700
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        margin-left: auto;
        background-color: transparent;
        background-image: url(../images/lecture/btn-ranking-close.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 1.26667rem;
        height: 1.26667rem;
        padding: 0;
        border: 0 none;
        background-size: 100% 100%;
        transition: all .3s ease;
        text-indent: -9999px
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close.disabled,
    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close:disabled {
        opacity: .65
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-header .btn-close:hover {
        opacity: .75
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body {
        flex-grow: 1;
        background-color: #fff;
        border-bottom-left-radius: 2rem;
        border-bottom-right-radius: 2rem;
        padding: 2.4rem 3.06667rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        overflow: hidden
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab {
        height: 4.46667rem;
        flex-shrink: 0;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li {
        flex-grow: 1;
        position: relative
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li+li {
        margin-left: -2px
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li:nth-child(1).active a {
        border-bottom-left-radius: 0
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li:nth-child(2).active a {
        border-bottom-right-radius: 0
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li.active {
        z-index: 3
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li.active a {
        background-color: #fff;
        border-color: #fbba23;
        border-bottom-color: #fff;
        color: #161616
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-tab li a {
        border: 2px solid #ddd;
        color: #828282;
        font-size: 1.2rem;
        font-weight: 500;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        border-radius: .53333rem
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score {
        flex-shrink: 0;
        margin-top: -.53333rem;
        margin-bottom: 1.33333rem;
        border-radius: .53333rem;
        border: 2px solid #fbba23;
        height: 7rem;
        background-color: #fff;
        z-index: 2;
        box-shadow: 0 7px 8px 0 rgba(0, 0, 0, .1), inset 0 0 0 2px rgba(251, 186, 35, .004);
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .thumb {
        width: 4.9rem;
        height: 4.9rem;
        display: block;
        border-radius: 50%;
        background-color:#fff;
        border:6px solid #E8E8E8;
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info {
        margin-left: 1.13333rem
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .name {
        color: #333;
        font-size: 1.33333rem;
        font-weight: 400;
        line-height: 1em;
        margin-bottom: .66667rem
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .t-score {
        color: #484848;
        font-size: 1.33333rem;
        font-family: Roboto, 'Noto Sans KR', sans-serif;
        font-weight: 400
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .t-score strong {
        font-size: 1.93333rem;
        font-weight: 700
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-score .user-info .t-score strong.color {
        color: #ff5a00
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-body .ranking-list {
        flex-grow: 1;
        overflow-y: auto
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list {
        overflow-y: auto;
        flex-grow: 1
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li {
        height: 4.5rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ebebeb;
        padding: 0 2rem 0 .66667rem
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .rank {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.06667rem;
        height: 2.06667rem;
        background-color: grey;
        color: #fff;
        font-size: 1.06667rem;
        font-family: Roboto, sans-serif;
        margin-right: .86667rem;
        flex-shrink: 0;
        border-radius: 100%
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .rank.top-rank {
        background-color: #ff9e00
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .thumb {
        width: 3rem;
        height: 3rem;
        display: block;
        flex-shrink: 0;
        margin-right: .5rem;
        border-radius: 50%;
        background-color: #F1ECE3;
        border: 2px solid #E8E8E8;

    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .name {
        flex-grow: 1;
        color: #373636;
        font-size: 1.2rem;
        font-weight: 400
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .t-score {
        color: #60605f;
        font-size: 1.2rem;
        font-weight: 700
    }

    .typing-game-02 .ranking-popup .ranking-popup-wrap .ranking-list li .t-score strong {
        color: #ff5a00;
        font-family: Roboto, sans-serif
    }
    .typing-game-02-footer {
        align-self:center;
    }

    .typing-game-02-footer .icon-progress-clock {
        background-color: transparent;
        background-image: url(../images/lecture/progress-clock.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 2.3rem;
        height: 2.666rem;
        background-size: 100% 100%;
        margin-right: 1rem
    }

    .typing-game-02-footer .footer-progress {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%
    }

    .typing-game-02-footer .footer-progress .remain-time {
        color: #fff;
        font-size: 1.06667rem;
        margin-left: 1rem;
        font-weight: 500
    }

    .typing-game-02-footer .typing02-progress {
        width: 48.1rem;
        height: 2.18rem;
        position: relative;
        background-color: transparent;
        background-image: url(../images/lecture/bg-progress.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100% 100%
    }

    .typing-game-02-footer .typing02-progress .progress-inner {
        position: absolute;
        left: 0;
        top: 0;
        height: 2.18rem;
        background-color: transparent;
        background-image: url(../images/lecture/bg-progress-inner.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 48.2133rem 2.29rem;
        background-position: top left;
        border-top-right-radius: .33333rem;
        border-bottom-right-radius: .33333rem
    }

    .typing-game-02-footer .btn-next-dark,
    .typing-game-02-footer .btn-retry {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        display: block;
        width: 2rem!important;
        height: 3.66667rem!important;
        text-indent: -9999px;
        border: 0 none;
        padding: 0;
        background-color: transparent!important;
        margin: 0 4.53333rem
    }

    .typing-game-02-footer .btn-next-dark.disabled,
    .typing-game-02-footer .btn-next-dark:disabled,
    .typing-game-02-footer .btn-retry.disabled,
    .typing-game-02-footer .btn-retry:disabled {
        opacity: .65
    }

    .typing-game-02-footer .btn-next-dark:not([disabled]):not(.disabled),
    .typing-game-02-footer .btn-retry:not([disabled]):not(.disabled) {
        cursor: pointer
    }

}

.typing-game-04-footer {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%
}

.typing-game-04-footer .icon-correct-percent,
.typing-game-04-footer .icon-time-info,
.typing-game-04-footer .icon-words-count {
    display: inline-block;
    vertical-align: bottom;
    margin-right: .86667rem
}

.typing-game-04-footer .icon-correct-percent {
    background-color: transparent;
    background-image: url(../images/lecture/icon-correct-percent.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 2.66rem;
    height: 1.92rem;
    background-size: 100% 100%
}

.typing-game-04-footer .icon-time-info {
    background-color: transparent;
    background-image: url(../images/lecture/icon-time-info.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 1.92rem;
    height: 1.92rem;
    background-size: 100% 100%
}

.typing-game-04-footer .icon-words-count {
    background-color: transparent;
    background-image: url(../images/lecture/icon-words-count.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 2.3rem;
    height: 2rem;
    background-size: 100% 100%
}

.typing-game-04-footer .type-info {
    display: block;
    color: #fff;
    font-size: 1rem;
    font-weight: 500
}

.typing-game-04-footer .type-info+.type-info {
    margin-left: 10.4rem
}

.typing-game-04-footer .type-info strong {
    margin-left: .5rem;
    color: #fff;
    font-size: 1.38rem;
    font-family: Roboto, sans-serif;
    font-weight: 700
}

.typing-game-04 {
    overflow-y: hidden;
    align-items: flex-start
}

.typing-game-04 .sentence-list {
    list-style: none;
    padding: 20px 0 40px 0;
    margin: 0;
    overflow-y: auto;
    height: 100%;
    width: 100%;

}

.typing-game-04 .sentence-list li {
    display: block;
    width: 74rem;
    margin: 0 auto;
    padding: 1.06rem 0 0 0;
}

.typing-game-04 .sentence-list li .english {
    color: #484848;
    font-size: 1.5rem;
    font-weight: 700;
    /*font-family: Roboto, sans-serif;*/
    line-height: 1.5em;
    margin-bottom: 0.5rem;
}


.typing-game-04 .sentence-list li .input-wrap {
    display: block;
    width: 74rem;
    height: 3rem;
    position:relative;
}

.typing-game-04 .sentence-list li .input-wrap .input {
    /*display: block;*/
    /*border-radius: 1.06rem;*/
    /*width: 100%;*/
    /*background-color: transparent;*/
    /*border: 2px solid transparent;*/
    /*height: 3rem;*/
    /*font-family: "Noto Sans KR", sans-serif;*/
    /*font-size: 1.1rem;*/
    /*font-weight: 500;*/
    /*padding: 0 1.6rem;*/
    /*!*color: rgba(34, 34, 34, 0);*!*/
    /*caret-color:#222;*/
    /*z-index:2;*/
    /*position:relative;*/
    color: #222;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3rem;
    background-color: #f2f3f6;
    display: flex;
    justify-content: left;
    align-items: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 1.6rem;
    border:2px solid #d7d8db;
    border-radius: 1.06rem;
}




.typing-game-04 .sentence-list .i .input:focus {
    /*color: #0f8e92;*/
    border-color: #15a6ca;
    background-color: #fff;
    outline: 0
}

.typing-game-04 .sentence-list li .input-wrap .input-visual {
    color: #222;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3rem;
    background-color: #f2f3f6;
    display: flex;
    justify-content: left;
    align-items: center;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1.1rem;
    font-weight: 500;
    padding: 0 1.6rem;
    border:2px solid #d7d8db;
    border-radius: 1.06rem;
}

.typing-game-04 .tp04-s-correct {
    color: #00759c;
}
.typing-game-04 .tp04-s-wrong {
    color:#f07e23;
}


@media screen and (max-width: 1600px) {

    .typing-game-04-footer {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%
    }

    .typing-game-04-footer .icon-correct-percent,
    .typing-game-04-footer .icon-time-info,
    .typing-game-04-footer .icon-words-count {
        display: inline-block;
        vertical-align: bottom;
        margin-right: .86667rem
    }

    .typing-game-04-footer .icon-correct-percent {
        background-color: transparent;
        background-image: url(../images/lecture/icon-correct-percent.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 2.66rem;
        height: 1.92rem;
        background-size: 100% 100%
    }

    .typing-game-04-footer .icon-time-info {
        background-color: transparent;
        background-image: url(../images/lecture/icon-time-info.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 1.92rem;
        height: 1.92rem;
        background-size: 100% 100%
    }

    .typing-game-04-footer .icon-words-count {
        background-color: transparent;
        background-image: url(../images/lecture/icon-words-count.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 2.3rem;
        height: 2rem;
        background-size: 100% 100%
    }

    .typing-game-04-footer .type-info {
        display: block;
        color: #fff;
        font-size: 1rem;
        font-weight: 500
    }

    .typing-game-04-footer .type-info+.type-info {
        margin-left: 10.4rem
    }

    .typing-game-04-footer .type-info strong {
        margin-left: .5rem;
        color: #fff;
        font-size: 1.38rem;
        font-family: Roboto, sans-serif;
        font-weight: 700
    }

    .typing-game-04 {
        overflow-y: hidden;
        align-items: flex-start
    }

    .typing-game-04 .sentence-list {
        list-style: none;
        padding: 20px 0 40px 0;
        margin: 0;
        overflow-y: auto;
        height: 100%;
        width: 100%;

    }

    .typing-game-04 .sentence-list li {
        display: block;
        width: 53rem;
        margin: 0 auto;
        padding: 1.06rem 0 0 0;
    }

    .typing-game-04 .sentence-list li .english {
        color: #484848;
        font-size: 1.2rem;
        font-weight: 700;
        /*font-family: Roboto, sans-serif;*/
        line-height: 1.5em;
        margin-bottom: 0.5rem;
    }


    .typing-game-04 .sentence-list li .input-wrap {
        display: block;
        width: 53rem;
        height: 3rem;
        position:relative;
    }

    .typing-game-04 .sentence-list li .input-wrap .input {
        display: block;
        border-radius: 1.06rem;
        width: 100%;
        /*background-color: transparent;*/
        /*border: 2px solid transparent;*/
        height: 3rem;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0 1.6rem;
        /*color: rgba(34, 34, 34, 0);*/
        caret-color:#222;
        z-index:2;
        position:relative;
    }




    .typing-game-04 .sentence-list .i .input:focus {
        /*color: #0f8e92;*/
        border-color: #15a6ca;
        background-color: #fff;
        outline: 0
    }

    .typing-game-04 .sentence-list li .input-wrap .input-visual {
        color: #222;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 3rem;
        background-color: #f2f3f6;
        display: flex;
        justify-content: left;
        align-items: center;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 1.1rem;
        font-weight: 500;
        padding: 0 1.6rem;
        border:2px solid #d7d8db;
        border-radius: 1.06rem;
    }

}

@charset "UTF-8";
@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-Book.eot);
    src: url(../fonts/CircularStd-Book.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-Book.woff2) format("woff2"), url(../fonts/CircularStd-Book.woff) format("woff"), url(../fonts/CircularStd-Book.ttf) format("truetype");
    font-weight: 300
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-BookItalic.eot);
    src: url(../fonts/CircularStd-BookItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-BookItalic.woff2) format("woff2"), url(../fonts/CircularStd-BookItalic.woff) format("woff"), url(../fonts/CircularStd-BookItalic.ttf) format("truetype");
    font-weight: 300;
    font-style: italic
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-Medium.eot);
    src: url(../fonts/CircularStd-Medium.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-Medium.woff2) format("woff2"), url(../fonts/CircularStd-Medium.woff) format("woff"), url(../fonts/CircularStd-Medium.ttf) format("truetype");
    font-weight: 400
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-MediumItalic.eot);
    src: url(../fonts/CircularStd-MediumItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-MediumItalic.woff2) format("woff2"), url(../fonts/CircularStd-MediumItalic.woff) format("woff"), url(../fonts/CircularStd-MediumItalic.ttf) format("truetype");
    font-weight: 400;
    font-style: italic
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-Bold.eot);
    src: url(../fonts/CircularStd-Bold.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-Bold.woff2) format("woff2"), url(../fonts/CircularStd-Bold.woff) format("woff"), url(../fonts/CircularStd-Bold.ttf) format("truetype");
    font-weight: 500
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-BoldItalic.eot);
    src: url(../fonts/CircularStd-BoldItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-BoldItalic.woff2) format("woff2"), url(../fonts/CircularStd-BoldItalic.woff) format("woff"), url(../fonts/CircularStd-BoldItalic.ttf) format("truetype");
    font-weight: 500;
    font-style: italic
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-Black.eot);
    src: url(../fonts/CircularStd-Black.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-Black.woff2) format("woff2"), url(../fonts/CircularStd-Black.woff) format("woff"), url(../fonts/CircularStd-Black.ttf) format("truetype");
    font-weight: 700
}

@font-face {
    font-family: CircularStd;
    src: url(../fonts/CircularStd-BlackItalic.eot);
    src: url(../fonts/CircularStd-BlackItalic.eot?#iefix) format("embedded-opentype"), url(../fonts/CircularStd-BlackItalic.woff2) format("woff2"), url(../fonts/CircularStd-BlackItalic.woff) format("woff"), url(../fonts/CircularStd-BlackItalic.ttf) format("truetype");
    font-weight: 700;
    font-style: italic
}

*,
::after,
::before {
    box-sizing: border-box
}

html {
    scroll-behavior: smooth;
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent
}

@-ms-viewport {
    width: device-width;
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
    display: block
}

body {
    margin: 0;
    font-family: "Noto Sans KR", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5em;
    color: #212529;
    text-align: left;
    background-color: #f7f8fc;
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-family: "Noto Sans KR", sans-serif;
    line-height: 1.5em
}

p {
    margin: 0
}

abbr[data-original-title],
abbr[title] {
    text-decoration: underline;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0
}

address {
    margin: 0;
    font-style: normal;
    line-height: inherit
}

dl,
ol,
ul {
    margin: 0;
    list-style: none;
    padding: 0
}

ol ol,
ol ul,
ul ol,
ul ul {
    margin: 0
}

dt {
    font-weight: 400
}

dd {
    margin: 0
}

blockquote {
    margin: 0
}

dfn {
    font-style: italic
}

b,
strong {
    font-weight: 700
}

small {
    font-size: 80%
}

sub,
sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #212529;
    text-decoration: none;
    background-color: transparent;
    -webkit-text-decoration-skip: objects
}

a:hover {
    color: #282828;
    text-decoration: none
}

a:not([href]):not([tabindedx]) {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindedx]):focus,
a:not([href]):not([tabindedx]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindedx]):focus {
    outline: 0
}

code,
kbd,
pre,
samp {
    font-family: "Noto Sans KR", sans-serif;
    font-size: 1em
}

pre {
    margin: 0;
    overflow: auto;
    -ms-overflow-style: scrollbar
}

figure {
    margin: 0
}

img {
    vertical-align: middle;
    border-style: none
}

svg:not(:root) {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #212529;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin: 0
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button,
input,
optgroup,
select,
textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,
input {
    overflow: visible
}

button,
select {
    text-transform: none
}

[type=reset],
[type=submit],
button,
html [type=button] {
    -webkit-appearance: button
}

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox],
input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date],
input[type=datetime-local],
input[type=month],
input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin: 0;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-cancel-button,
[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

body {
    padding-top: 80px;
    min-width: 768px
}

@media screen and (max-width:1199px){

    *,
    ::after,
    ::before {
        box-sizing: border-box
    }

    html {
        font-family: sans-serif;
        line-height: 1.15;
        -webkit-text-size-adjust: 100%;
        -ms-text-size-adjust: 100%;
        -ms-overflow-style: scrollbar;
        -webkit-tap-highlight-color: transparent
    }

    @-ms-viewport {
        width: device-width
    }

    article,
    aside,
    figcaption,
    figure,
    footer,
    header,
    hgroup,
    main,
    nav,
    section {
        display: block
    }

    body {
        margin: 0;
        font-family: "Noto Sans KR", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 1.5em;
        color: #212529;
        text-align: left;
        background-color: #fff
    }

    [tabindex="-1"]:focus {
        outline: 0!important
    }

    hr {
        box-sizing: content-box;
        height: 0;
        overflow: visible
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        font-family: "Noto Sans KR", sans-serif;
        line-height: 1.5em
    }

    p {
        margin: 0
    }

    abbr[data-original-title],
    abbr[title] {
        text-decoration: underline;
        text-decoration: underline dotted;
        cursor: help;
        border-bottom: 0
    }

    address {
        margin: 0;
        font-style: normal;
        line-height: inherit
    }

    dl,
    ol,
    ul {
        margin: 0;
        list-style: none;
        padding: 0
    }

    ol ol,
    ol ul,
    ul ol,
    ul ul {
        margin: 0
    }

    dt {
        font-weight: 400
    }

    dd {
        margin: 0
    }

    blockquote {
        margin: 0
    }

    dfn {
        font-style: italic
    }

    b,
    strong {
        font-weight: 700
    }

    small {
        font-size: 80%
    }

    sub,
    sup {
        position: relative;
        font-size: 75%;
        line-height: 0;
        vertical-align: baseline
    }

    sub {
        bottom: -.25em
    }

    sup {
        top: -.5em
    }

    a {
        color: #212529;
        text-decoration: none;
        background-color: transparent;
        -webkit-text-decoration-skip: objects
    }

    a:hover {
        color: #282828;
        text-decoration: none
    }

    a:not([href]):not([tabindedx]) {
        color: inherit;
        text-decoration: none
    }

    a:not([href]):not([tabindedx]):focus,
    a:not([href]):not([tabindedx]):hover {
        color: inherit;
        text-decoration: none
    }

    a:not([href]):not([tabindedx]):focus {
        outline: 0
    }

    code,
    kbd,
    pre,
    samp {
        font-family: "Noto Sans KR", sans-serif;
        font-size: 1em
    }

    pre {
        margin: 0;
        overflow: auto;
        -ms-overflow-style: scrollbar
    }

    figure {
        margin: 0
    }

    img {
        vertical-align: middle;
        border-style: none
    }

    svg:not(:root) {
        overflow: hidden
    }

    table {
        border-collapse: collapse
    }

    caption {
        padding-top: .75rem;
        padding-bottom: .75rem;
        color: #212529;
        text-align: left;
        caption-side: bottom
    }

    th {
        text-align: inherit
    }

    label {
        display: inline-block;
        margin: 0
    }

    button {
        border-radius: 0
    }

    button:focus {
        outline: 1px dotted;
        outline: 5px auto -webkit-focus-ring-color
    }

    button,
    input,
    optgroup,
    select,
    textarea {
        margin: 0;
        font-family: inherit;
        font-size: inherit;
        line-height: inherit
    }

    button,
    input {
        overflow: visible
    }

    button,
    select {
        text-transform: none
    }

    [type=reset],
    [type=submit],
    button,
    html [type=button] {
        -webkit-appearance: button
    }

    [type=button]::-moz-focus-inner,
    [type=reset]::-moz-focus-inner,
    [type=submit]::-moz-focus-inner,
    button::-moz-focus-inner {
        padding: 0;
        border-style: none
    }

    input[type=checkbox],
    input[type=radio] {
        box-sizing: border-box;
        padding: 0
    }

    input[type=date],
    input[type=datetime-local],
    input[type=month],
    input[type=time] {
        -webkit-appearance: listbox
    }

    textarea {
        overflow: auto;
        resize: vertical
    }

    fieldset {
        min-width: 0;
        padding: 0;
        margin: 0;
        border: 0
    }

    legend {
        display: block;
        width: 100%;
        max-width: 100%;
        padding: 0;
        margin: 0;
        font-size: 1.5rem;
        line-height: inherit;
        color: inherit;
        white-space: normal
    }

    progress {
        vertical-align: baseline
    }

    [type=number]::-webkit-inner-spin-button,
    [type=number]::-webkit-outer-spin-button {
        height: auto
    }

    [type=search] {
        outline-offset: -2px;
        -webkit-appearance: none
    }

    [type=search]::-webkit-search-cancel-button,
    [type=search]::-webkit-search-decoration {
        -webkit-appearance: none
    }

    ::-webkit-file-upload-button {
        font: inherit;
        -webkit-appearance: button
    }

    output {
        display: inline-block
    }

    summary {
        display: list-item;
        cursor: pointer
    }

    template {
        display: none
    }

    [hidden] {
        display: none!important
    }

    html {
        font-size: 3.88889vw
    }

    @media screen and (orientation:landscape) {
        html {
            font-size: 2.1875vw
        }
    }

    body {
        padding-top: 3.75rem;
        min-width:0;

    }

    @media screen and (orientation:landscape) {
        body.lecture-view {
            width: 100vw;
            height: 100vh
        }
    }

    @media screen and (orientation:portrait) {
        /*body.lecture-view {*/
            /*width: 100vh;*/
            /*height: 100vw;*/
            /*!*-webkit-transform: rotate(90deg) translateY(-100vw);*!*/
            /*!*-moz-transform: rotate(90deg) translateY(-100vw);*!*/
            /*!*-ms-transform: rotate(90deg) translateY(-100vw);*!*/
            /*!*-o-transform: rotate(90deg) translateY(-100vw);*!*/
            /*!*transform: rotate(90deg) translateY(-100vw);*!*/
            /*!*transform-origin: top left*!*/
        /*}*/
    }
}

#header {
    width: 100%;
    height: 80px;
    background-color: #0797c8;
    border-bottom: 1px solid #31a8d0;
    z-index: 100;
    position: fixed;
    top: 0
}


@media screen and (max-width: 1199px) {
    #header {
        height: 3.75rem;
        background-color: #0797c8;
        border-bottom: 1px solid #31a8d0;
        z-index: 100;
        position: fixed;
        top: 0
    }
}

/*@media screen and (max-width: 768px) {*/
    /*#header {*/
        /*height: 60px*/
    /*}*/
/*}*/

#header.no-border {
    border-bottom: 0 none
}

#header #logo {
    display: block;
    background-color: transparent;
    background-image: url(../images/common/logo.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    width: 146px;
    height: 38px;
    text-indent: -9999px;
    position: absolute;
    left: 13px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}


@media screen and (max-width: 1199px) {

    #header #logo {
        position: absolute;
        display: block;
        background-image: url(../images-m/common/logo-m.png);
        background-size: 5.8125rem 1.5rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-size: 100%;
        width: 5.8125rem;
        height: 1.5rem;
        text-indent: -9999px;
        position: absolute;
        left: 1.22917rem;
        top: .97917rem;
        transform: none;
    }


    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        #header #logo {
            background-image: url(../images-m/common/logo-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        #header #logo {
            background-image: url(../images-m/common/logo-m@3x.png)
        }
    }


}


/*@media screen and (max-width: 768px) {*/
    /*#header #logo {*/
        /*width: 94px;*/
        /*height: 24.33333px;*/
        /*left: 20px*/
    /*}*/
/*}*/

#header .user-info {
    margin: 0 auto;
    width: auto;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px
}

@media screen and (max-width: 1199px) {
    #header .user-info {
        display: none;
    }

}


/*@media screen and (max-width: 768px) {*/
    /*#header .user-info {*/
        /*width: 22px;*/
        /*height: 22px;*/
        /*display: block;*/
        /*position: absolute;*/
        /*top: 50%;*/
        /*right: 86.66667px;*/
        /*-webkit-transform: translateY(-50%);*/
        /*-moz-transform: translateY(-50%);*/
        /*-ms-transform: translateY(-50%);*/
        /*-o-transform: translateY(-50%);*/
        /*transform: translateY(-50%)*/
    /*}*/
/*}*/



#header .user-info .user-pic {
    position: relative
}

#header .user-info .user-pic img {
    border-radius: 100%
}

#header .user-info .user-pic.small img {
    display: block;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .35);
    background-color:#fff;
}



/*@media screen and (max-width: 768px) {*/
    /*#header .user-info .user-pic img {*/
        /*display: block;*/
        /*width: 22px;*/
        /*height: 22px*/
    /*}*/
/*}*/

#header .user-info .user-pic .user-grade {
    display: none
}

/*@media screen and (max-width: 768px) {*/
    /*#header .user-info .user-pic .user-grade {*/
        /*display: block;*/
        /*position: absolute;*/
        /*color: #fff;*/
        /*font-size: 9.33333px;*/
        /*font-weight: 700;*/
        /*line-height: 12px;*/
        /*padding: 0 4px;*/
        /*background-color: #ff9e00;*/
        /*vertical-align: middle;*/
        /*border-radius: 17px;*/
        /*top: -2.66667px;*/
        /*left: -17px*/
    /*}*/
/*}*/

#header .user-info .info {
    margin-left: 20px;
    padding: 4px 18px 4px 4px;
    background-color: #fff;
    border-radius: 21px;
    position: relative
}

#header .user-info .info:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: transparent;
    background-image: url(../images/common/user-info-arrow.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 17px;
    height: 8px;
    z-index: -1
}

#header .user-info .info .user-grade {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    line-height: 34px;
    padding: 0 9px;
    background-color: #ff9e00;
    vertical-align: middle;
    border-radius: 17px
}

#header .user-info .info .user-name {
    margin-left: 8px;
    color: #292a2a;
    font-size: 16px;
    font-weight: 500
}

#header .user-info .info .user-desc {
    color: #686868;
    font-size: 14px;
    margin-left: 8px
}

/*@media screen and (max-width: 768px) {*/
    /*#header .user-info .info {*/
        /*display: none*/
    /*}*/
/*}*/

#header #btn-help,
#header #btn-setting {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    position: absolute;
    display: block;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    border: 1px solid #fff;
    border-radius: 100%;
    text-indent: -9999px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    width: 47px;
    height: 47px
}


@media screen and (max-width: 1199px) {
    #header #btn-setting {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-image: url(../images-m/common/icon-cog-m.png);
        background-size: 1.375rem 1.375rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        position: absolute;
        display: block;
        right: 1.25rem;
        top: 1.02083rem;
        border-radius: 100%;
        text-indent: -9999px;
        border: 0 none;
        width: 1.375rem;
        height: 1.375rem;
        background-color: transparent;
        transform: none;
    }

}



#header #btn-help.disabled,
#header #btn-help:disabled,
#header #btn-setting.disabled,
#header #btn-setting:disabled {
    opacity: .65
}

#header #btn-help:not([disabled]):not(.disabled),
#header #btn-setting:not([disabled]):not(.disabled) {
    cursor: pointer
}

#header #btn-help:hover,
#header #btn-setting:hover {
    background-color: #fff
}

/*@media screen and (max-width: 768px) {*/
    /*#header #btn-help,*/
    /*#header #btn-setting {*/
        /*background-size: 100%;*/
        /*border: 0 none;*/
        /*width: 22.33333px;*/
        /*height: 22.33333px*/
    /*}*/

    /*#header #btn-help:hover,*/
    /*#header #btn-setting:hover {*/
        /*background-color: transparent*/
    /*}*/
/*}*/


#header #btn-setting {
    background-color: transparent;
    background-image: url(../images/common/icon-cog.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    right: 13px
}

#header #btn-setting:hover {
    background-image: url(../images/common/icon-cog-hover.png)
}

/*@media screen and (max-width: 768px) {*/
    /*#header #btn-setting {*/
        /*right: 20px;*/
        /*background-image: url(../images/common/icon-cog-m.png) !important*/
    /*}*/
/*}*/

#header #btn-help {
    background-color: transparent;
    background-image: url(../images/common/icon-question-mark.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    right: 77px
}

#header #btn-help.opened,
#header #btn-help:hover {
    background-color: #fff;
    background-image: url(../images/common/icon-question-mark-hover.png)
}

/*@media screen and (max-width: 768px) {*/
    /*#header #btn-help {*/
        /*background-color: transparent !important;*/
        /*background-image: url(../images/common/icon-question-mark-m.png) !important;*/
        /*right: 52.33333px*/
    /*}*/
/*}*/

#view-header {
    height: 75px;
    background-color: #309ac2;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100
}

@media screen and (max-width: 1199px) {
    #view-header {
        /*height: 36px;*/
        background-color: #f5f5f5
    }
}

#view-header #logo {
    display: block;
    background-color: transparent;
    background-image: url(../images/common/logo.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    width: 146px;
    height: 38px;
    text-indent: -9999px;
    position: absolute;
    left: 13px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media screen and (max-width: 1119px) {
    #view-header #logo {
        display: none
    }

    #header #btn-help {
        display: none
    }
}

#view-header .progressbar {
    display: none
}

@media screen and (max-width: 1119px) {
    #view-header .progressbar {
        display: block;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
        left: 54.66667px;
        width: 279.33333px;
        height: 4.33333px;
        background-color: #d4d4d4
    }

    #view-header .progressbar:after,
    #view-header .progressbar:before {
        content: '';
        border-radius: 100%;
        width: 8px;
        height: 8px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    #view-header .progressbar:before {
        background-color: #f60;
        left: -4px
    }

    #view-header .progressbar:after {
        right: -4px;
        background-color: #d4d4d4
    }

    #view-header .progressbar .progressbar-value {
        background-color: #f60;
        height: 4.33333px
    }
}

#view-header .btn-toggle-nav {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-menu.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 54px;
    height: 54px;
    border: 0;
    right: 15px;
    padding: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    text-indent: -9999px
}

#view-header .btn-toggle-nav.disabled,
#view-header .btn-toggle-nav:disabled {
    opacity: .65
}

#view-header .btn-toggle-nav:not([disabled]):not(.disabled) {
    cursor: pointer
}

@media screen and (max-width: 1119px) {
    #view-header .btn-toggle-nav {
        background-image: url(../images/lecture/btn-menu-m.png);
        background-size: 100%;
        right: 13px;
        width: 17.33333px;
        height: 13.33333px
    }
}

#view-header .btn-toggle-nav.opened {
    background-image: url(../images/lecture/btn-menu-toggled.png);
    width: 111px;
    height: 75px;
    right: 0
}

#view-header .btn-toggle-nav + .btn-close {
    right: 78px
}

#view-header .btn-toggle-nav.opened + .btn-close {
    right: 126px
}

#view-header .btn-toggle-guide {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-guide-m.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 22.66667px;
    height: 22.66667px;
    position: absolute;
    right: 50px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    display: none;
    text-indent: -9999px;
    border: 0;
    background-size: 100%
}

#view-header .btn-toggle-guide.disabled,
#view-header .btn-toggle-guide:disabled {
    opacity: .65
}

#view-header .btn-toggle-guide:not([disabled]):not(.disabled) {
    cursor: pointer
}

@media screen and (max-width: 1220px) {
    #view-header .btn-toggle-guide {
        display: block
    }
}

@media screen and (max-width: 1220px) {
    #view-header .btn-toggle-guide + .title-wrap {
        right: 85px
    }
}

#view-header .btn-close {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-close.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    padding: 0;
    margin: 0;
    text-indent: -9999px;
    border: 0;
    position: absolute;
    right: 14px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 54px;
    height: 54px
}

#view-header .btn-close.disabled,
#view-header .btn-close:disabled {
    opacity: .65
}

#view-header .btn-close:not([disabled]):not(.disabled) {
    cursor: pointer
}

#view-header .btn-close:hover {
    background-image: url(../images/lecture/btn-close-hover.png)
}

@media screen and (max-width: 1220px) {
    #view-header .btn-close {
        background-image: url(../images/lecture/btn-close-m.png) !important;
        width: 20px;
        height: 20px;
        right: auto;
        left: 16px;
        background-size: 100%
    }
}

#view-header .title-wrap {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    height: 75px;
    align-items: center;
    justify-content: center
}

@media screen and (max-width: 1220px) {
    #view-header .title-wrap {
        display: block;
        height: auto;
        position: absolute;
        right: 50px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }
}

#view-header .title-wrap .step {
    display: block;
    color: #004e6b;
    font-size: 16px;
    font-weight: 700;
    padding: 0 13px;
    line-height: 34px;
    border-radius: 17px;
    margin-right: 15px;
    background-color: #65d2fb;
    position: relative
}

@media screen and (max-width: 1220px) {
    #view-header .title-wrap .step {
        display: none
    }
}

#view-header .title-wrap .step:before {
    content: '';
    width: 21px;
    height: 10px;
    bottom: 0;
    position: absolute;
    background-color: transparent;
    background-image: url(../images/lecture/title-arrow.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    right: 0
}

#view-header .title-wrap h2 {
    display: block;
    color: #fff;
    font-weight: 700;
    font-size: 22px
}

@media screen and (max-width: 1220px) {
    #view-header .title-wrap h2 {
        font-size: 12px;
        color: #4a4848;
        text-align: right
    }
}

#view-nav {
    position: fixed;
    right: 13px;
    top: 75px;
    width: 245px;
    z-index: 90
}

@media screen and (max-width: 1220px) {
    #view-nav {
        z-index: 102;
        top: 0;
        right: 0;
        width: 100%;
        display: none;
        bottom: 0;
        overflow-y: auto
    }
}

#view-nav .btn-toggle-nav {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    padding: 0;
    border: 0;
    background: 0 0;
    color: #363535;
    font-size: 14px;
    vertical-align: middle;
    line-height: 20px
}

@media screen and (min-width: 1220px) {
    #view-nav .btn-toggle-nav {
        display: none
    }
}

#view-nav .btn-toggle-nav.disabled,
#view-nav .btn-toggle-nav:disabled {
    opacity: .65
}

#view-nav .btn-toggle-nav:not([disabled]):not(.disabled) {
    cursor: pointer
}

#view-nav .btn-toggle-nav > i {
    display: inline-block;
    width: 8px;
    height: 12px;
    background-color: transparent;
    background-image: url(../images/lecture/icon-nav-toggle.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    vertical-align: baseline;
    margin-right: 10px;
    line-height: 20px
}

@media screen and (max-width: 1220px) {
    #view-nav .btn-toggle-nav {
        text-indent: -9999px;
        width: 19px;
        height: 20px;
        background-color: transparent;
        background-image: url(../images/lecture/btn-menu-close-m.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        top: 9px;
        right: 230px;
        position: fixed
    }

    #view-nav .btn-toggle-nav > i {
        display: none
    }
}

#guide {
    position: fixed;
    top: 75px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    border: 3px solid #309ac2;
    background-color: #fff;
    line-height: 60px;
    border-radius: 30px;
    z-index: 101
}

#guide:before {
    content: '';
    width: 31px;
    height: 31px;
    display: block;
    position: absolute;
    left: 15px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: transparent;
    background-image: url(../images/lecture/icon-guide.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

#guide p {
    white-space: nowrap;
    padding: 0 14px 0 56px;
    margin: 0
}

@media screen and (max-width: 1220px) {
    #guide {
        top: 51.66667px;
        width: 90%
    }

    #guide:before {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        -o-transform: none;
        transform: none;
        top: 15px
    }

    #guide p {
        white-space: unset;
        line-height: 1.5em;
        padding: 15px 15px 15px 56px
    }
}

#footer-action {
    background-color: #055e7f;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    padding: 20px 0 5px
}

@media screen and (max-width: 1220px) {
    #footer-action {
        padding: 0;
        background-color: #006271;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between
    }
}

#footer-action .btn-back,
#footer-action .btn-mute,
#footer-action .btn-next,
#footer-action .btn-retest,
#footer-action .btn-submit,
#footer-action .btn-check-correct{
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    border: 0 none;
    padding: 0;
    text-indent: -9999px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    height: 104px
}

#footer-action .btn-back.disabled,
#footer-action .btn-back:disabled,
#footer-action .btn-mute.disabled,
#footer-action .btn-mute:disabled,
#footer-action .btn-next.disabled,
#footer-action .btn-next:disabled,
#footer-action .btn-retest.disabled,
#footer-action .btn-retest:disabled,
#footer-action .btn-submit.disabled,
#footer-action .btn-submit:disabled,
#footer-action .btn-check-correct:disabled {
    opacity: .65
}

#footer-action .btn-back:not([disabled]):not(.disabled),
#footer-action .btn-mute:not([disabled]):not(.disabled),
#footer-action .btn-next:not([disabled]):not(.disabled),
#footer-action .btn-retest:not([disabled]):not(.disabled),
#footer-action .btn-submit:not([disabled]):not(.disabled),
#footer-action .btn-check-correct:not([disabled]):not(.disabled) {
    cursor: pointer
}

@media screen and (max-width: 1220px) {
    #footer-action .btn-back,
    #footer-action .btn-mute,
    #footer-action .btn-next,
    #footer-action .btn-retest,
    #footer-action .btn-submit,
    #footer-action .btn-check-correct {
        background-size: 100% auto;
        height: 50px;
        margin: 0 16px
    }
}

#footer-action .btn-back {
    background-color: transparent;
    background-image: url(../images/lecture/btn-back.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 187px
}

#footer-action .btn-back:hover {
    background-image: url(../images/lecture/btn-back-hover.png)
}

@media screen and (max-width: 1220px) {
    #footer-action .btn-back {
        background-image: url(../images/lecture/btn-back-m.png) !important;
        width: 22px
    }
}

#footer-action .btn-check-correct {
    background-color: transparent;
    background-image: url("../images/lecture/btn-check-correct.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 60px;
}

#footer-action .btn-mute {
    background-color: transparent;
    background-image: url(../images/lecture/btn-mute.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 104px
}

#footer-action .btn-mute:hover {
    background-image: url(../images/lecture/btn-mute-hover.png)
}

@media screen and (max-width: 1220px) {
    #footer-action .btn-mute {
        background-image: url(../images/lecture/btn-mute-m.png) !important;
        width: 24.33333px
    }
}

#footer-action .btn-retest {
    background-color: transparent;
    background-image: url(../images/lecture/btn-retest.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 346px
}

#footer-action .btn-retest:hover {
    background-image: url(../images/lecture/btn-retest-hover.png)
}

@media screen and (max-width: 1220px) {
    #footer-action .btn-retest {
        background-image: url(../images/lecture/btn-retest-m.png) !important;
        width: 67px
    }
}

#footer-action .btn-submit {
    background-color: transparent;
    background-image: url(../images/lecture/btn-submit.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 346px
}

#footer-action .btn-submit:hover {
    background-image: url(../images/lecture/btn-submit-hover.png)
}

@media screen and (max-width: 1220px) {
    #footer-action .btn-submit {
        background-image: url(../images/lecture/btn-submit-m.png) !important;
        width: 77.66667px
    }
}

#footer-action .btn-next {
    background-color: transparent;
    background-image: url(../images/lecture/btn-next.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 187px
}

#footer-action .btn-next:hover {
    background-image: url(../images/lecture/btn-next-hover.png)
}

@media screen and (max-width: 1220px) {
    #footer-action .btn-next {
        background-image: url(../images/lecture/btn-next-m.png) !important;
        width: 22px
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only.sr-only-focusable:active,
.sr-only.sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.clearfix,
.clearfix:after,
.clearfix:before {
    display: block;
    clear: both;
    content: ""
}

.W5 {
    width: 5px !important
}

.H5 {
    height: 5px !important
}

.W10 {
    width: 10px !important
}

.H10 {
    height: 10px !important
}

.W15 {
    width: 15px !important
}

.H15 {
    height: 15px !important
}

.W20 {
    width: 20px !important
}

.H20 {
    height: 20px !important
}

.W25 {
    width: 25px !important
}

.H25 {
    height: 25px !important
}

.W30 {
    width: 30px !important
}

.H30 {
    height: 30px !important
}

.W35 {
    width: 35px !important
}

.H35 {
    height: 35px !important
}

.W40 {
    width: 40px !important
}

.H40 {
    height: 40px !important
}

.W45 {
    width: 45px !important
}

.H45 {
    height: 45px !important
}

.W50 {
    width: 50px !important
}

.H50 {
    height: 50px !important
}

.W55 {
    width: 55px !important
}

.H55 {
    height: 55px !important
}

.W60 {
    width: 60px !important
}

.H60 {
    height: 60px !important
}

.W65 {
    width: 65px !important
}

.H65 {
    height: 65px !important
}

.W70 {
    width: 70px !important
}

.H70 {
    height: 70px !important
}

.W75 {
    width: 75px !important
}

.H75 {
    height: 75px !important
}

.W80 {
    width: 80px !important
}

.H80 {
    height: 80px !important
}

.W85 {
    width: 85px !important
}

.H85 {
    height: 85px !important
}

.W90 {
    width: 90px !important
}

.H90 {
    height: 90px !important
}

.W95 {
    width: 95px !important
}

.H95 {
    height: 95px !important
}

.W100 {
    width: 100px !important
}

.H100 {
    height: 100px !important
}

.W105 {
    width: 105px !important
}

.H105 {
    height: 105px !important
}

.W110 {
    width: 110px !important
}

.H110 {
    height: 110px !important
}

.W115 {
    width: 115px !important
}

.H115 {
    height: 115px !important
}

.W120 {
    width: 120px !important
}

.H120 {
    height: 120px !important
}

.W125 {
    width: 125px !important
}

.H125 {
    height: 125px !important
}

.W130 {
    width: 130px !important
}

.H130 {
    height: 130px !important
}

.W135 {
    width: 135px !important
}

.H135 {
    height: 135px !important
}

.W140 {
    width: 140px !important
}

.H140 {
    height: 140px !important
}

.W145 {
    width: 145px !important
}

.H145 {
    height: 145px !important
}

.W150 {
    width: 150px !important
}

.H150 {
    height: 150px !important
}

.W155 {
    width: 155px !important
}

.H155 {
    height: 155px !important
}

.W160 {
    width: 160px !important
}

.H160 {
    height: 160px !important
}

.W165 {
    width: 165px !important
}

.H165 {
    height: 165px !important
}

.W170 {
    width: 170px !important
}

.H170 {
    height: 170px !important
}

.W175 {
    width: 175px !important
}

.H175 {
    height: 175px !important
}

.W180 {
    width: 180px !important
}

.H180 {
    height: 180px !important
}

.W185 {
    width: 185px !important
}

.H185 {
    height: 185px !important
}

.W190 {
    width: 190px !important
}

.H190 {
    height: 190px !important
}

.W195 {
    width: 195px !important
}

.H195 {
    height: 195px !important
}

.W200 {
    width: 200px !important
}

.H200 {
    height: 200px !important
}

.W205 {
    width: 205px !important
}

.H205 {
    height: 205px !important
}

.W210 {
    width: 210px !important
}

.H210 {
    height: 210px !important
}

.W215 {
    width: 215px !important
}

.H215 {
    height: 215px !important
}

.W220 {
    width: 220px !important
}

.H220 {
    height: 220px !important
}

.W225 {
    width: 225px !important
}

.H225 {
    height: 225px !important
}

.W230 {
    width: 230px !important
}

.H230 {
    height: 230px !important
}

.W235 {
    width: 235px !important
}

.H235 {
    height: 235px !important
}

.W240 {
    width: 240px !important
}

.H240 {
    height: 240px !important
}

.W245 {
    width: 245px !important
}

.H245 {
    height: 245px !important
}

.W250 {
    width: 250px !important
}

.H250 {
    height: 250px !important
}

.W255 {
    width: 255px !important
}

.H255 {
    height: 255px !important
}

.W260 {
    width: 260px !important
}

.H260 {
    height: 260px !important
}

.W265 {
    width: 265px !important
}

.H265 {
    height: 265px !important
}

.W270 {
    width: 270px !important
}

.H270 {
    height: 270px !important
}

.W275 {
    width: 275px !important
}

.H275 {
    height: 275px !important
}

.W280 {
    width: 280px !important
}

.H280 {
    height: 280px !important
}

.W285 {
    width: 285px !important
}

.H285 {
    height: 285px !important
}

.W290 {
    width: 290px !important
}

.H290 {
    height: 290px !important
}

.W295 {
    width: 295px !important
}

.H295 {
    height: 295px !important
}

.W300 {
    width: 300px !important
}

.H300 {
    height: 300px !important
}

.W305 {
    width: 305px !important
}

.H305 {
    height: 305px !important
}

.W310 {
    width: 310px !important
}

.H310 {
    height: 310px !important
}

.W315 {
    width: 315px !important
}

.H315 {
    height: 315px !important
}

.W320 {
    width: 320px !important
}

.H320 {
    height: 320px !important
}

.W325 {
    width: 325px !important
}

.H325 {
    height: 325px !important
}

.W330 {
    width: 330px !important
}

.H330 {
    height: 330px !important
}

.W335 {
    width: 335px !important
}

.H335 {
    height: 335px !important
}

.W340 {
    width: 340px !important
}

.H340 {
    height: 340px !important
}

.W345 {
    width: 345px !important
}

.H345 {
    height: 345px !important
}

.W350 {
    width: 350px !important
}

.H350 {
    height: 350px !important
}

.W355 {
    width: 355px !important
}

.H355 {
    height: 355px !important
}

.W360 {
    width: 360px !important
}

.H360 {
    height: 360px !important
}

.W365 {
    width: 365px !important
}

.H365 {
    height: 365px !important
}

.W370 {
    width: 370px !important
}

.H370 {
    height: 370px !important
}

.W375 {
    width: 375px !important
}

.H375 {
    height: 375px !important
}

.W380 {
    width: 380px !important
}

.H380 {
    height: 380px !important
}

.W385 {
    width: 385px !important
}

.H385 {
    height: 385px !important
}

.W390 {
    width: 390px !important
}

.H390 {
    height: 390px !important
}

.W395 {
    width: 395px !important
}

.H395 {
    height: 395px !important
}

.W400 {
    width: 400px !important
}

.H400 {
    height: 400px !important
}

.W405 {
    width: 405px !important
}

.H405 {
    height: 405px !important
}

.W410 {
    width: 410px !important
}

.H410 {
    height: 410px !important
}

.W415 {
    width: 415px !important
}

.H415 {
    height: 415px !important
}

.W420 {
    width: 420px !important
}

.H420 {
    height: 420px !important
}

.W425 {
    width: 425px !important
}

.H425 {
    height: 425px !important
}

.W430 {
    width: 430px !important
}

.H430 {
    height: 430px !important
}

.W435 {
    width: 435px !important
}

.H435 {
    height: 435px !important
}

.W440 {
    width: 440px !important
}

.H440 {
    height: 440px !important
}

.W445 {
    width: 445px !important
}

.H445 {
    height: 445px !important
}

.W450 {
    width: 450px !important
}

.H450 {
    height: 450px !important
}

.W455 {
    width: 455px !important
}

.H455 {
    height: 455px !important
}

.W460 {
    width: 460px !important
}

.H460 {
    height: 460px !important
}

.W465 {
    width: 465px !important
}

.H465 {
    height: 465px !important
}

.W470 {
    width: 470px !important
}

.H470 {
    height: 470px !important
}

.W475 {
    width: 475px !important
}

.H475 {
    height: 475px !important
}

.W480 {
    width: 480px !important
}

.H480 {
    height: 480px !important
}

.W485 {
    width: 485px !important
}

.H485 {
    height: 485px !important
}

.W490 {
    width: 490px !important
}

.H490 {
    height: 490px !important
}

.W495 {
    width: 495px !important
}

.H495 {
    height: 495px !important
}

.M0 {
    margin: 0 !important
}

.MT0 {
    margin-top: 0 !important
}

.MR0 {
    margin-right: 0 !important
}

.MB0 {
    margin-bottom: 0 !important
}

.ML0 {
    margin-left: 0 !important
}

.PT0 {
    padding-top: 0 !important
}

.PR0 {
    padding-right: 0 !important
}

.PB0 {
    padding-bottom: 0 !important
}

.PL0 {
    padding-left: 0 !important
}

.P0 {
    padding: 0 !important
}

.M5 {
    margin: 5px !important
}

.MT5 {
    margin-top: 5px !important
}

.MR5 {
    margin-right: 5px !important
}

.MB5 {
    margin-bottom: 5px !important
}

.ML5 {
    margin-left: 5px !important
}

.PT5 {
    padding-top: 5px !important
}

.PR5 {
    padding-right: 5px !important
}

.PB5 {
    padding-bottom: 5px !important
}

.PL5 {
    padding-left: 5px !important
}

.P5 {
    padding: 5px !important
}

.M10 {
    margin: 10px !important
}

.MT10 {
    margin-top: 10px !important
}

.MR10 {
    margin-right: 10px !important
}

.MB10 {
    margin-bottom: 10px !important
}

.ML10 {
    margin-left: 10px !important
}

.PT10 {
    padding-top: 10px !important
}

.PR10 {
    padding-right: 10px !important
}

.PB10 {
    padding-bottom: 10px !important
}

.PL10 {
    padding-left: 10px !important
}

.P10 {
    padding: 10px !important
}

.M15 {
    margin: 15px !important
}

.MT15 {
    margin-top: 15px !important
}

.MR15 {
    margin-right: 15px !important
}

.MB15 {
    margin-bottom: 15px !important
}

.ML15 {
    margin-left: 15px !important
}

.PT15 {
    padding-top: 15px !important
}

.PR15 {
    padding-right: 15px !important
}

.PB15 {
    padding-bottom: 15px !important
}

.PL15 {
    padding-left: 15px !important
}

.P15 {
    padding: 15px !important
}

.M20 {
    margin: 20px !important
}

.MT20 {
    margin-top: 20px !important
}

.MR20 {
    margin-right: 20px !important
}

.MB20 {
    margin-bottom: 20px !important
}

.ML20 {
    margin-left: 20px !important
}

.PT20 {
    padding-top: 20px !important
}

.PR20 {
    padding-right: 20px !important
}

.PB20 {
    padding-bottom: 20px !important
}

.PL20 {
    padding-left: 20px !important
}

.P20 {
    padding: 20px !important
}

.M25 {
    margin: 25px !important
}

.MT25 {
    margin-top: 25px !important
}

.MR25 {
    margin-right: 25px !important
}

.MB25 {
    margin-bottom: 25px !important
}

.ML25 {
    margin-left: 25px !important
}

.PT25 {
    padding-top: 25px !important
}

.PR25 {
    padding-right: 25px !important
}

.PB25 {
    padding-bottom: 25px !important
}

.PL25 {
    padding-left: 25px !important
}

.P25 {
    padding: 25px !important
}

.M30 {
    margin: 30px !important
}

.MT30 {
    margin-top: 30px !important
}

.MR30 {
    margin-right: 30px !important
}

.MB30 {
    margin-bottom: 30px !important
}

.ML30 {
    margin-left: 30px !important
}

.PT30 {
    padding-top: 30px !important
}

.PR30 {
    padding-right: 30px !important
}

.PB30 {
    padding-bottom: 30px !important
}

.PL30 {
    padding-left: 30px !important
}

.P30 {
    padding: 30px !important
}

.M35 {
    margin: 35px !important
}

.MT35 {
    margin-top: 35px !important
}

.MR35 {
    margin-right: 35px !important
}

.MB35 {
    margin-bottom: 35px !important
}

.ML35 {
    margin-left: 35px !important
}

.PT35 {
    padding-top: 35px !important
}

.PR35 {
    padding-right: 35px !important
}

.PB35 {
    padding-bottom: 35px !important
}

.PL35 {
    padding-left: 35px !important
}

.P35 {
    padding: 35px !important
}

.M40 {
    margin: 40px !important
}

.MT40 {
    margin-top: 40px !important
}

.MR40 {
    margin-right: 40px !important
}

.MB40 {
    margin-bottom: 40px !important
}

.ML40 {
    margin-left: 40px !important
}

.PT40 {
    padding-top: 40px !important
}

.PR40 {
    padding-right: 40px !important
}

.PB40 {
    padding-bottom: 40px !important
}

.PL40 {
    padding-left: 40px !important
}

.P40 {
    padding: 40px !important
}

.M45 {
    margin: 45px !important
}

.MT45 {
    margin-top: 45px !important
}

.MR45 {
    margin-right: 45px !important
}

.MB45 {
    margin-bottom: 45px !important
}

.ML45 {
    margin-left: 45px !important
}

.PT45 {
    padding-top: 45px !important
}

.PR45 {
    padding-right: 45px !important
}

.PB45 {
    padding-bottom: 45px !important
}

.PL45 {
    padding-left: 45px !important
}

.P45 {
    padding: 45px !important
}

.M50 {
    margin: 50px !important
}

.MT50 {
    margin-top: 50px !important
}

.MR50 {
    margin-right: 50px !important
}

.MB50 {
    margin-bottom: 50px !important
}

.ML50 {
    margin-left: 50px !important
}

.PT50 {
    padding-top: 50px !important
}

.PR50 {
    padding-right: 50px !important
}

.PB50 {
    padding-bottom: 50px !important
}

.PL50 {
    padding-left: 50px !important
}

.P50 {
    padding: 50px !important
}

.M55 {
    margin: 55px !important
}

.MT55 {
    margin-top: 55px !important
}

.MR55 {
    margin-right: 55px !important
}

.MB55 {
    margin-bottom: 55px !important
}

.ML55 {
    margin-left: 55px !important
}

.PT55 {
    padding-top: 55px !important
}

.PR55 {
    padding-right: 55px !important
}

.PB55 {
    padding-bottom: 55px !important
}

.PL55 {
    padding-left: 55px !important
}

.P55 {
    padding: 55px !important
}

.M60 {
    margin: 60px !important
}

.MT60 {
    margin-top: 60px !important
}

.MR60 {
    margin-right: 60px !important
}

.MB60 {
    margin-bottom: 60px !important
}

.ML60 {
    margin-left: 60px !important
}

.PT60 {
    padding-top: 60px !important
}

.PR60 {
    padding-right: 60px !important
}

.PB60 {
    padding-bottom: 60px !important
}

.PL60 {
    padding-left: 60px !important
}

.P60 {
    padding: 60px !important
}

.M65 {
    margin: 65px !important
}

.MT65 {
    margin-top: 65px !important
}

.MR65 {
    margin-right: 65px !important
}

.MB65 {
    margin-bottom: 65px !important
}

.ML65 {
    margin-left: 65px !important
}

.PT65 {
    padding-top: 65px !important
}

.PR65 {
    padding-right: 65px !important
}

.PB65 {
    padding-bottom: 65px !important
}

.PL65 {
    padding-left: 65px !important
}

.P65 {
    padding: 65px !important
}

.M70 {
    margin: 70px !important
}

.MT70 {
    margin-top: 70px !important
}

.MR70 {
    margin-right: 70px !important
}

.MB70 {
    margin-bottom: 70px !important
}

.ML70 {
    margin-left: 70px !important
}

.PT70 {
    padding-top: 70px !important
}

.PR70 {
    padding-right: 70px !important
}

.PB70 {
    padding-bottom: 70px !important
}

.PL70 {
    padding-left: 70px !important
}

.P70 {
    padding: 70px !important
}

.M75 {
    margin: 75px !important
}

.MT75 {
    margin-top: 75px !important
}

.MR75 {
    margin-right: 75px !important
}

.MB75 {
    margin-bottom: 75px !important
}

.ML75 {
    margin-left: 75px !important
}

.PT75 {
    padding-top: 75px !important
}

.PR75 {
    padding-right: 75px !important
}

.PB75 {
    padding-bottom: 75px !important
}

.PL75 {
    padding-left: 75px !important
}

.P75 {
    padding: 75px !important
}

.M80 {
    margin: 80px !important
}

.MT80 {
    margin-top: 80px !important
}

.MR80 {
    margin-right: 80px !important
}

.MB80 {
    margin-bottom: 80px !important
}

.ML80 {
    margin-left: 80px !important
}

.PT80 {
    padding-top: 80px !important
}

.PR80 {
    padding-right: 80px !important
}

.PB80 {
    padding-bottom: 80px !important
}

.PL80 {
    padding-left: 80px !important
}

.P80 {
    padding: 80px !important
}

.M85 {
    margin: 85px !important
}

.MT85 {
    margin-top: 85px !important
}

.MR85 {
    margin-right: 85px !important
}

.MB85 {
    margin-bottom: 85px !important
}

.ML85 {
    margin-left: 85px !important
}

.PT85 {
    padding-top: 85px !important
}

.PR85 {
    padding-right: 85px !important
}

.PB85 {
    padding-bottom: 85px !important
}

.PL85 {
    padding-left: 85px !important
}

.P85 {
    padding: 85px !important
}

.M90 {
    margin: 90px !important
}

.MT90 {
    margin-top: 90px !important
}

.MR90 {
    margin-right: 90px !important
}

.MB90 {
    margin-bottom: 90px !important
}

.ML90 {
    margin-left: 90px !important
}

.PT90 {
    padding-top: 90px !important
}

.PR90 {
    padding-right: 90px !important
}

.PB90 {
    padding-bottom: 90px !important
}

.PL90 {
    padding-left: 90px !important
}

.P90 {
    padding: 90px !important
}

.M95 {
    margin: 95px !important
}

.MT95 {
    margin-top: 95px !important
}

.MR95 {
    margin-right: 95px !important
}

.MB95 {
    margin-bottom: 95px !important
}

.ML95 {
    margin-left: 95px !important
}

.PT95 {
    padding-top: 95px !important
}

.PR95 {
    padding-right: 95px !important
}

.PB95 {
    padding-bottom: 95px !important
}

.PL95 {
    padding-left: 95px !important
}

.P95 {
    padding: 95px !important
}

.M100 {
    margin: 100px !important
}

.MT100 {
    margin-top: 100px !important
}

.MR100 {
    margin-right: 100px !important
}

.MB100 {
    margin-bottom: 100px !important
}

.ML100 {
    margin-left: 100px !important
}

.PT100 {
    padding-top: 100px !important
}

.PR100 {
    padding-right: 100px !important
}

.PB100 {
    padding-bottom: 100px !important
}

.PL100 {
    padding-left: 100px !important
}

.P100 {
    padding: 100px !important
}

.W5P {
    width: 5% !important
}

.W10P {
    width: 10% !important
}

.W15P {
    width: 15% !important
}

.W20P {
    width: 20% !important
}

.W25P {
    width: 25% !important
}

.W30P {
    width: 30% !important
}

.W35P {
    width: 35% !important
}

.W40P {
    width: 40% !important
}

.W45P {
    width: 45% !important
}

.W50P {
    width: 50% !important
}

.W55P {
    width: 55% !important
}

.W60P {
    width: 60% !important
}

.W65P {
    width: 65% !important
}

.W70P {
    width: 70% !important
}

.W75P {
    width: 75% !important
}

.W80P {
    width: 80% !important
}

.W85P {
    width: 85% !important
}

.W90P {
    width: 90% !important
}

.W95P {
    width: 95% !important
}

.W100P {
    width: 100% !important
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

/*************************************************************************************** 아이콘 */
.icon-info {
    width: 26px;
    height: 25px;
    background-color: transparent;
    background-image: url("../images/icons/icon-info.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    display: inline-block;
    vertical-align: middle;
}

.icon-star {
    width: 20px;
    height: 18px;
    background-color: transparent;
    background-image: url("../images/icons/icon-star.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    display: inline-block;
    vertical-align: middle;
}


/*모바일 아이콘*/
@media screen and (max-width: 1199px) {


    .icon-info {
        width: .77083rem;
        height: .77083rem;
        background-color: transparent;
        background-image: url(../images/icons/icon-info.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        display: inline-block;
        vertical-align: middle;
        background-size: cover;
        margin-right: .3125rem
    }

    .icon-star {
        width: .83333rem;
        height: .83333rem;
        background-size: 100%;
        background-color: transparent;
        background-image: url(../images/icons/icon-star.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        display: inline-block;
        vertical-align: top
    }
}

/*************************************************************************************** 버튼 */
.btn-ok {
    display: block;
    margin: 0 auto;
    width: 251.2px;
    height: 84px;
    background-color: transparent;
    background-image: url("../images/common/btn-ok.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    transition: all .3s ease;
    text-indent: -9999px;
    border: 0;
    padding: 0;
}

.btn-play-this {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 88.8px;
    height: 58.4px;
    border-radius: 10px;
    border: 2px solid #15a6ca;
    background-color: transparent;
    background-image: url("../images/icons/icon-play-this.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    transition: all .3s ease;
    text-indent: -9999px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .btn-play-this {
        width: 60px;
        height: 40px;
        background-size: 24px;
    }
}

.btn-play-this:disabled, .btn-play-this.disabled {
    opacity: 0.65;
}

.btn-play-this:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.btn-play-this:hover {
    background-color: #15a6ca;
    background-image: url("../images/icons/icon-play-this-hover.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.btn-use-hint {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url("../images/buttons/btn-use-hint.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 176.8px;
    height: 58.4px;
    text-indent: -9999px;
    border: 0 none;
    padding: 0;
    background-size: 100%;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .btn-use-hint {
        /*width: 160px;*/
        height: 50px;
        text-indent: -9999px;
        border: 0 none;
        padding: 0;
        background-size: auto 50px;
    }
}

.btn-use-hint:disabled, .btn-use-hint.disabled {
    opacity: 0.65;
}

.btn-use-hint:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.btn-pop-close {
    width: 31.2px;
    height: 31.2px;
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url("../images/buttons/btn-pop-close.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    text-indent: -9999px;
    border: 0 none;
    padding: 0;
    background-size: 100%;
}

.btn-pop-close:disabled, .btn-pop-close.disabled {
    opacity: 0.65;
}

.btn-pop-close:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.btn-reset {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 88px;
    height: 88px;
    border: 0;
    padding: 0;
    background-color: transparent;
    background-image: url("../images/buttons/btn-reset.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    text-indent: -9999px;
}

.btn-reset:disabled, .btn-reset.disabled {
    opacity: 0.65;
}

.btn-reset:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.btn-play-sound {
    text-indent: -9999px;
    font-size: 0;
    background-color: transparent;
    background-image: url(../images/common/icon-speak.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 60px;
    height: 60px;
    display: block;
    cursor: pointer;
    border: 0
}


.btn-play-sound:active,
.btn-play-sound:hover {
    background-color: transparent;
    background-image: url(../images/common/icon-speak-active.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    outline: 0
}

.btn-play-sound.big {
    background-color: transparent;
    background-image: url("../images/common/icon-speak-big2.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 85px;
    height: 86px;
    background-size: 100%;
}


.btn-play-sound.story-play{
    margin:0 auto;
}

@media screen and (max-width: 1199px){
    .btn-play-sound.story-play{
        margin: 10px auto -5px auto;
        width: 2rem;
        height: 2rem;
        background-size: 2rem;
    }

}



.btn-record-confirm {
    text-indent: -9999px;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-color: transparent;
    background-image: url(../images/common/confirm-recording.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 401px;
    height: 421px;
    display: block;
    cursor: pointer
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .btn-record-confirm {
        width: 316px;
        height: 346px;
        background-size: contain;
    }
}

.btn-record-confirm:active,
.btn-record-confirm:hover {
    background-color: transparent;
    background-image: url(../images/common/confirm-recording-active.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    outline: 0
}

.btn-picture {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 93.6px;
    height: 30.4px;
    text-indent: -9999px;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-color: transparent;
    background-image: url("../images/buttons/btn-picture.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
}

.btn-picture:disabled, .btn-picture.disabled {
    opacity: 0.65;
}

.btn-picture:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.btn-story {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 85.6px;
    height: 30.4px;
    text-indent: -9999px;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-color: transparent;
    background-image: url("../images/buttons/btn-story.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
}
.btn-meaning {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 85.6px;
    height: 30.4px;
    text-indent: -9999px;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-color: transparent;
    background-image: url("../images/buttons/btn-meaning.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
}

.btn-story:disabled, .btn-story.disabled , .btn-meaning:disabled, .btn-meaning.disabled{
    opacity: 0.65;
}

.btn-story:not([disabled]):not(.disabled),.btn-meaning:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.btn-record-submit {
    text-indent: -9999px;
    font-size: 0;
    border: none;
    background-color: transparent;
    background-color: transparent;
    background-image: url(../images/common/submit-recording.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 401px;
    height: 421px;
    display: block;
    cursor: pointer
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .btn-record-submit {
        width: 316px;
        height: 346px;
        background-size: contain;
    }
}

.btn-record-submit:active,
.btn-record-submit:hover {
    background-color: transparent;
    background-image: url(../images/common/submit-recording-active.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    outline: 0
}



/*모바일 버튼*/
@media screen and (max-width: 1199px) {

    .btn-ok {
        display: block;
        margin: 0 auto;
        width: 6.45833rem;
        height: 2.04167rem;
        background-color: transparent;
        background-image: url(../images-m/common/btn-ok.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        transition: all .3s ease;
        text-indent: -9999px;
        border: 0;
        padding: 0
    }

    .btn-reset {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 2.25rem;
        height: 2.25rem;
        border: 0;
        padding: 0;
        background-color: transparent;
        background-image: url(../images/buttons/btn-reset.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        text-indent: -9999px;
        background-size: cover
    }

    .btn-reset.disabled,
    .btn-reset:disabled {
        opacity: .65
    }

    .btn-reset:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .btn-play-sound {
        text-indent: -9999px;
        font-size: 0;
        background-image: url(../images-m/common/icon-speak-m.png);
        background-size: 1.45833rem 1.45833rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        width: 1.45833rem;
        height: 1.45833rem;
        display: block;
        cursor: pointer;
        border: 0
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .btn-play-sound {
            background-image: url(../images-m/common/icon-speak-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .btn-play-sound {
            background-image: url(../images-m/common/icon-speak-m@3x.png)
        }
    }

    .btn-record-confirm {
        text-indent: -9999px;
        font-size: 0;
        border: none;
        background-color: transparent;
        background-image: url(../images-m/common/confirm-recording-m.png);
        background-size: 9.125rem 10.0625rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        width: 9.125rem;
        height: 10.0625rem;
        display: block;
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .btn-record-confirm {
            background-image: url(../images-m/common/confirm-recording-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .btn-record-confirm {
            background-image: url(../images-m/common/confirm-recording-m@3x.png)
        }
    }

    .btn-record-submit {
        text-indent: -9999px;
        font-size: 0;
        border: none;
        background-color: transparent;
        background-image: url(../images-m/common/submit-recording-m.png);
        background-size: 9.125rem 10.0625rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        width: 9.125rem;
        height: 10.0625rem;
        display: block;
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .btn-record-submit {
            background-image: url(../images-m/common/submit-recording-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .btn-record-submit {
            background-image: url(../images-m/common/submit-recording-m@3x.png)
        }
    }

    .btn-play-this {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 2.91667rem;
        height: 1.52083rem;
        border-radius: .3125rem;
        border: 1px solid #15a6ca;
        background-color: transparent;
        background-image: url(../images/icons/icon-play-this.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        transition: all .3s ease;
        text-indent: -9999px;
        background-size: .66667rem .625rem
    }

    .btn-play-this.disabled,
    .btn-play-this:disabled {
        opacity: .65
    }

    .btn-play-this:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .btn-play-this:hover {
        background-color: #15a6ca;
        background-image: url(../images/icons/icon-play-this-hover.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }

    .btn-use-hint {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-color: transparent;
        background-image: url(../images/buttons/btn-use-hint.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 4.60417rem;
        height: 1.52083rem;
        text-indent: -9999px;
        border: 0 none;
        padding: 0;
        background-size: 4.60417rem 1.52083rem
    }

    .btn-use-hint.disabled,
    .btn-use-hint:disabled {
        opacity: .65
    }

    .btn-use-hint:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .btn-picture {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 4.54167rem;
        height: 1.52083rem;
        text-indent: -9999px;
        font-size: 0;
        border: none;
        background-color: transparent;
        background-color: transparent;
        background-image: url(../images/buttons/btn-picture.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%
    }

    .btn-picture.disabled,
    .btn-picture:disabled {
        opacity: .65
    }

    .btn-picture:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .btn-pop-close {
        width: .8125rem;
        height: .8125rem;
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-color: transparent;
        background-image: url(../images/buttons/btn-pop-close.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        text-indent: -9999px;
        border: 0 none;
        padding: 0;
        background-size: 100%
    }

    .btn-pop-close.disabled,
    .btn-pop-close:disabled {
        opacity: .65
    }

    .btn-pop-close:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .btn-story {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 3.89583rem;
        height: 1.22917rem;
        text-indent: -9999px;
        font-size: 0;
        border: none;
        background-color: transparent;
        background-image: url(../images/buttons/btn-story.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%
    }

    .btn-meaning {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 3.89583rem;
        height: 1.22917rem;
        text-indent: -9999px;
        font-size: 0;
        border: none;
        background-color: transparent;
        background-image: url(../images/buttons/btn-meaning.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%
    }

    .btn-story:disabled, .btn-story.disabled , .btn-meaning:disabled, .btn-meaning.disabled{
        opacity: .65;
    }

    .btn-story:not([disabled]):not(.disabled),.btn-meaning:not([disabled]):not(.disabled) {
        cursor: pointer;
    }

}



.main-section {
    min-height: calc(100vh - 80px);
    background-color: #0797c8;
    background-image: url(../images/common/bg-main.png);
    background-repeat: no-repeat;
    background-position: top center;
    position: relative;
    overflow-x: hidden
}

/*@media screen and (max-width: 768px) {*/
    /*.main-section {*/
        /*min-height: calc(100vh - 60px)*/
    /*}*/
/*}*/

.main-section .user-info-wrap {
    padding: 100px 0 60px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .main-section .user-info-wrap {
        padding: 40px 0 0 0;
    }
}


@media screen and (max-width: 1199px) {
    .main-section .user-info-wrap {
        padding: 0;
        height: 15.97917rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column
    }

}

/*@media screen and (max-width: 768px) {*/
    /*.main-section .user-info-wrap {*/
        /*padding: 85px 0 100px*/
    /*}*/
/*}*/

.main-section .user-info-wrap .user-pic {
    background-color: transparent;
    background-image: url(../images/common/thumb-bg.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 146px;
    height: 147px;
    position: relative;
    padding: 10px;
    margin: 0 auto 10px
}

.main-section .user-info-wrap .user-pic img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 100%;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .35);
    background-color:#fff;
}



@media screen and (max-width: 1199px) {

    .main-section .user-info-wrap .user-pic {
        width: 5.14583rem;
        height: 5.14583rem;
        position: relative;
        margin: 0 auto 1.25rem;
        background-color: #fff;
        border-radius: 100%;
        padding: 0;
    }

    .main-section .user-info-wrap .user-pic img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 100%
    }



}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .main-section .user-info-wrap .user-pic {
        background-color: transparent;
        background-image: url(../images/common/thumb-bg.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 103px;
        height: 103px;
        padding: 4px;
        background-image: none
    }
}

/*@media screen and (max-width: 768px) {*/
    /*.main-section .user-info-wrap .user-pic {*/
        /*width: 103px;*/
        /*height: 103px;*/
        /*padding: 0;*/
        /*background-image: none*/
    /*}*/
/*}*/



/*@media screen and (max-width: 768px) {*/
    /*.main-section .user-info-wrap .user-pic img {*/
        /*box-shadow: none*/
    /*}*/
/*}*/

.main-section .user-info-wrap .user-pic .user-grade {
    position: absolute;
    bottom: 29px;
    right: -13px;
    display: block;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .23);
    background-color: #ff9e00;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 5px 10px;
    border-radius: 17px
}

@media screen and (max-width: 1199px) {
    .main-section .user-info-wrap .user-pic .user-grade {
        position: absolute;
        right: -1.25rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #ff9e00;
        color: #fff;
        font-weight: 700;
        border-radius: .85417rem;
        bottom: 0;
        font-size: .75rem;
        width: 2.27083rem;
        height: 1.70833rem;
        box-shadow: none;
        text-align: center
    }
}



@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .main-section .user-info-wrap .user-pic .user-grade {
        bottom: 10px;
        font-size: 14px;
        font-weight: 700;
        padding: 2px 10px;
        box-shadow: none;

    }
}

/*@media screen and (max-width: 768px) {*/
    /*.main-section .user-info-wrap .user-pic .user-grade {*/
        /*bottom: 0;*/
        /*font-size: 12px;*/
        /*padding: 2px 10px;*/
        /*box-shadow: none*/
    /*}*/
/*}*/

.main-section .user-info-wrap .user-name {
    color: #fff;
    font-size: 26px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 10px
}

@media screen and (max-width: 1199px) {
    .main-section .user-info-wrap .user-name {
        margin-bottom: .3125rem;
        font-size: 1.3125rem;
        font-weight: 700
    }
}

/*@media screen and (max-width: 768px) {*/
    /*.main-section .user-info-wrap .user-name {*/
        /*font-size: 21px;*/
        /*font-weight: 700*/
    /*}*/
/*}*/

.main-section .user-info-wrap .user-desc {
    color: #fff;
    font-size: 18px;
    font-weight: 400;
    text-align: center
}

@media screen and (max-width: 1199px) {
    .main-section .user-info-wrap .user-desc {
        font-size: .875rem;
    }
}

/*@media screen and (max-width: 768px) {*/
    /*.main-section .user-info-wrap .user-desc {*/
        /*font-size: 15px*/
    /*}*/
/*}*/

.main-section .progress-wrap {
    width: 1220px;
    margin: 0 auto 25px;
    padding-top: 60px;
    position: relative
}
.main-section .progress-wrap .step-points-container{
    width:100%;
    height:11px;
    display: flex;
    justify-content: space-between;
}

.main-section .progress-wrap .step-points-container .step-point{
    width: 26px;
    height: 26px;
    background-color: #0291c1;
    border-radius: 100%;
    display:flex;
    justify-content: center;
}

.main-section .progress-wrap .step-points-container .step-point .step-point-active{
    width: 14px;
    height: 14px;
    background-color: #ffc600;
    border-radius: 100%;
    transform: translateY(7px);
    z-index:10;

}

.main-section .progress-wrap .grade-step {
    margin: 0;
    list-style: none;
    padding: 0
}

.main-section .progress-wrap .grade-step li {
    position: absolute;
    top: 25px;
    color: #2ac3f7;
    font-size: 14px;
    font-weight: 500;
    width:100px;
    -webkit-transform: translateX(-100%);
    -moz-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    -o-transform: translateX(-100%);
    transform: translateX(-100%)
}

.main-section .progress-wrap .grade-step li.current {
    color: #5b3003;
    -webkit-transform: translateY(-20px);
    -moz-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px)
}

.main-section .progress-wrap .grade-step li.current:before {
    content: '';
    position: absolute;
    width: 88px;
    height: 72px;
    background-color: transparent;
    background-image: url(../images/common/current-step-ballon.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    top: -10px;
    left: -17px;
    z-index: -1
}

.main-section .progress-wrap .grade-progress {
    background-color: #0291c1;
    /*background-image: url(../images/common/grade-progress-bg.png);*/
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    /*width: 1156px;*/
    height: 8px;
    position: relative;

    padding-bottom: 2px;

    /*margin-left: 31px*/
}

.main-section .progress-wrap .grade-progress .current-progress {
    background-color: #ffc600;
    /*background-image: url(../images/common/grade-progress-value.png);*/
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position-x: left;
    /*width: 1149px;*/
    height: 3.3px;
    position: absolute;
    top: 1.5px;
    left: 8px
}


@media screen and (max-width: 1199px) {

    .main-section .progress-wrap {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 auto;
        position: relative;
        width: 100%;
        -webkit-transform: none!important;
        -moz-transform: none!important;
        -ms-transform: none!important;
        -o-transform: none!important;
        transform: none!important;
        padding: 2.25rem 0 .77083rem
    }

    .main-section .progress-wrap .grade-step {
        margin: 0;
        list-style: none;
        padding: 0;
        -webkit-transform: none!important;
        -moz-transform: none!important;
        -ms-transform: none!important;
        -o-transform: none!important;
        transform: none!important
    }

    .main-section .progress-wrap .grade-step li {
        display: none;
        position:initial;
        top:initial;
    }

    .main-section .progress-wrap .grade-step li.current {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: .75rem;
        color: #fff;
        background-color: #08739b;
        width: 3.20833rem;
        height: 1.33333rem;
        left: 0!important;
        border-radius: .66667rem;
        transform: none;
    }

    .main-section .progress-wrap .grade-progress {
        display: none
    }

    .main-section .progress-wrap .grade-step li.current:before {
        content: initial;
        position: initial;
        width: initial;
        height: initial;
        background-color: initial;
        background-image: initial;
        background-repeat: initial;
        background-position-x: initial;
        background-position-y: initial;
        top: initial;
        left: initial;
        z-index: initial;
    }
}

.main-section .grade-list {
    list-style: none;
    padding: 0;
    margin: 0 0 3px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    min-width: 1220px
}

.main-section .grade-list > li {
    padding: 0 40px;
    text-align: center;
    position: relative
}
@media screen and (max-width: 1199px) {
    .main-section .grade-list {
        display:none;
        height: 0px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1260px) {
    .main-section .grade-list > li {
        padding: 0 30px;
    }
}

.main-section .grade-list > li > ul {
    margin: 0 auto;
    list-style: none;
    padding: 0;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center
}

.main-section .grade-list > li > ul > li {
    padding: 8px 3px;
    position: relative
}

.main-section .grade-list > li > ul > li > span {
    display: block;
    border-radius: 100%;
    width: 95px;
    height: 95px;
    background-color: transparent;
    background-image: url(../images/common/bg-default-class.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    line-height: 82px;
    color: #fff;
    font-size: 18px;
    text-align: center;
    position: relative
}

.main-section .grade-list > li > ul > li.current > span {
    background-color: transparent;
    border: 0 none;
    color: #fff;
    background-color: transparent;
    background-image: url(../images/common/bg-current-class.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    font-weight: 500
}

.main-section .grade-list > li > ul > li.current:before {
    content: '';
    position: absolute;
    display: block;
    background-color: transparent;
    background-image: url(../images/common/bg-current-grade.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 251px;
    height: 120px;
    top: 0;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%)
}

.main-section .section-footer {
    background-color: #fff;
    padding: 56px 0;
    position: relative;
    z-index: 2
}

.main-section .section-footer .btn-go-list {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/common/btn-go-list.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    display: block;
    padding: 0;
    border: 0 none;
    text-indent: -9999px;
    width: 245px;
    height: 81px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    margin: 0 auto
}

.main-section .section-footer .btn-go-list.disabled,
.main-section .section-footer .btn-go-list:disabled {
    opacity: .65
}

.main-section .section-footer .btn-go-list:not([disabled]):not(.disabled) {
    cursor: pointer
}

.main-section .section-footer .btn-go-list:hover {
    background-image: url(../images/common/btn-go-list-hover.png)
}

@media screen and (max-width: 1199px) {
    .main-section .section-footer .btn-go-list {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-image: url(../images-m/common/btn-go-list-m.png);
        background-size: 9.125rem 2.9375rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url(../images/common/btn-go-list.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        display: block;
        padding: 0;
        border: 0 none;
        text-indent: -9999px;
        width: 9.125rem;
        height: 2.9375rem;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        margin: 0 auto
    }

    .main-section .section-footer .btn-go-list.disabled,
    .main-section .section-footer .btn-go-list:disabled {
        opacity: .65
    }

    .main-section .section-footer .btn-go-list:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .main-section .section-footer .btn-go-list {
            background-image: url(../images-m/common/btn-go-list-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .main-section .section-footer .btn-go-list {
            background-image: url(../images-m/common/btn-go-list-m@3x.png)
        }
    }

}

.list-section {
    padding-top: 55px;
    min-height: calc(100vh - 80px);
    position: relative
}

.list-section:before {
    content: '';
    position: fixed;
    width: 100%;
    background-color: #f2eae7;
    background-image: url(../images/list/bg-list.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position: top center;
    height: 100%;
    top: 0;
    left: 0
}

@media screen and (max-width: 1199px) {
    .list-section {
        padding-top: 10px;
    }

    .list-section:before {
        background-image: initial;
        background-size: 26.25rem 18.75rem;
        background-color: #f2eae7;
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .list-section:before {
            background-image: initial;
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .list-section:before {
            background-image: initial;
        }
    }

}

.list-section .lesson-list {
    margin: 0 auto;
    padding: 0;
    list-style: none
}

.list-section .lesson-list:after {
    display: block;
    clear: both;
    content: ""
}

.list-section .lesson-list > li {
    float: left;
    padding: 0 15px;
    margin-bottom: 30px;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    transition: all 1s ease;
    opacity: 1
}

.list-section .lesson-list > li.nv {
    opacity: 0;
    -webkit-transform: translateY(50px);
    -moz-transform: translateY(50px);
    -ms-transform: translateY(50px);
    -o-transform: translateY(50px);
    transform: translateY(50px)
}

.list-section .lesson-list > li .list-item {
    border-radius: 26px;
    box-shadow: 0 18px 27px 0 rgba(0, 0, 0, .08);
    -webkit-transition: transform .5s;
    -moz-transition: transform .5s;
    -ms-transition: transform .5s;
    -o-transition: transform .5s;
    transition: transform .5s;
    transform-style: preserve-3d;
    position: relative
}

/*@media screen and (max-width: 768px) {*/
    /*.list-section .lesson-list > li .list-item {*/
        /*box-shadow: none*/
    /*}*/
/*}*/

.list-section .lesson-list > li .list-item .front {
    background: #fff;
    padding: 15px;
    text-align: center;
    position: absolute;
    width: 100%;
    height: 285px;
    backface-visibility: hidden;
    border-radius: 26px;
    border: 2px solid #fff
}

/*@media screen and (max-width: 768px) {*/
    /*.list-section .lesson-list > li .list-item .front {*/
        /*position: relative;*/
        /*height: auto*/
    /*}*/
/*}*/

.list-section .lesson-list > li .list-item .front .thumb {
    width: 100%;
    margin-bottom: 33px;
    margin-top: 10px;
}

.list-section .lesson-list > li .list-item .front .thumb img {
    display: block;
    width: 100%
}

.list-section .lesson-list .locked .list-item .front .thumb img {
    filter:brightness(0.5);
}

.list-section .lesson-list .locked .list-item .front h5 {
    background-color: rgb(181, 181, 181);
}

.list-section .lesson-list > li .list-item .front h5 {
    display: inline-block;
    color: #fff;
    font-size: 14px;
    font-family: CircularStd;
    font-weight: 700;
    background-color: #3dadd8;
    padding: 2px 13px;
    border-radius: 13px;
    margin-bottom: 10px
}

.list-section .lesson-list > li .list-item .front h2 {
    color: #252727;
    font-size: 18px;
    font-weight: 400;
    letter-spacing: -.04em
}

.list-section .lesson-list > li .list-item .front h3 {
    color: #252727;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: -.05em
}

.list-section .lesson-list > li .list-item .back {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg);
    height: 285px;
    border-radius: 26px;
    backface-visibility: hidden
}

/*@media screen and (max-width: 768px) {*/
    /*.list-section .lesson-list > li .list-item .back {*/
        /*position: absolute;*/
        /*height: 100%;*/
        /*top: 0;*/
        /*left: 0;*/
        /*width: 100%*/
    /*}*/
/*}*/

/*@media screen and (max-width: 768px) {*/
    /*.list-section .lesson-list > li .list-item .back ul {*/
        /*-webkit-display: flex;*/
        /*display: -ms-flex;*/
        /*display: flex;*/
        /*flex-direction: column*/
    /*}*/
/*}*/

.list-section .lesson-list > li .list-item .back ul li {
    position: relative
}

/*@media screen and (max-width: 768px) {*/
    /*.list-section .lesson-list > li .list-item .back ul li {*/
        /*flex: 1*/
    /*}*/
/*}*/

.list-section .lesson-list > li .list-item .back ul li a {
    position: relative;
    display: block;
    height: 57px;
    line-height: 57px;
    text-align: center;
    width: 100%;
    background-color: #ff9e00;
    color: #fff;
    font-size: 17px;
    font-weight: 500
}

/*@media screen and (max-width: 768px) {*/
    /*.list-section .lesson-list > li .list-item .back ul li a {*/
        /*height: 100%*/
    /*}*/
/*}*/

.list-section .lesson-list > li .list-item .back ul li a:hover {
    background-color: #ff8400
}

.list-section .lesson-list > li .list-item .back ul li a:hover:before {
    background-color: #ff8400
}

.list-section .lesson-list > li .list-item .back ul li a.disabled {
    color: #ffcc87;
    background-color: #ff9e00
}

.list-section .lesson-list > li .list-item .back ul li a.disabled:hover {
    color: #ffcc87;
    background-color: #ff9e00
}

.list-section .lesson-list > li .list-item .back ul li + li:before {
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    top: -1px;
    background-color: #f8880d;
    display: block
}

.list-section .lesson-list > li .list-item .back ul li + li a:before {
    content: '';
    width: 100%;
    height: 1px;
    left: 0;
    top: 0;
    background-color: #ffaf2e;
    display: block
}

.list-section .lesson-list > li .list-item .back ul li:first-child a {
    border-top-left-radius: 26px;
    border-top-right-radius: 26px
}

.list-section .lesson-list > li .list-item .back ul li:last-child a {
    border-bottom-right-radius: 26px;
    border-bottom-left-radius: 26px
}

.list-section .lesson-list > li.hover .list-item {
    -webkit-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    transform: rotateY(180deg)
}

.list-section .lesson-list > li.current .list-item .front {
    border-color: #ff9e00
}

.list-section .lesson-list > li.current .list-item .front h5 {
    background-color: #ff9e00
}

.list-section .lesson-list > li.locked {
    position: relative;
}

.list-section .lesson-list > li.locked:before {
    content: '';
    width: 53px;
    height: 53px;
    background-color: transparent;
    background-image: url(../images/list/list-locked.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    position: absolute;
    z-index: 10;
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    -moz-transform: translateX(-50%) translateY(-50%);
    -ms-transform: translateX(-50%) translateY(-50%);
    -o-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%)
}



@media screen and (max-width: 1199px) {

    /*.list-section .lesson-list > li {*/
        /*float: initial;*/
        /*padding: 0.125rem;*/
        /*margin-bottom: 0.625rem;*/
    /*}*/

    /*.list-section .lesson-list > li .list-item {*/
        /*border-radius: 26px;*/
        /*box-shadow: 0 18px 27px 0 rgba(0, 0, 0, .08);*/
        /*-webkit-transition: transform .5s;*/
        /*-moz-transition: transform .5s;*/
        /*-ms-transition: transform .5s;*/
        /*-o-transition: transform .5s;*/
        /*transition: transform .5s;*/
        /*transform-style: preserve-3d;*/
        /*position: relative;*/
        /*width: 20rem;*/
        /*height: 19.58333rem;*/
        /*margin: 0 auto*/
    /*}*/


    .list-section {
        padding-top: .41667rem;
        min-height: calc(100vh - 3.75rem);
        position: relative
    }

    .list-section:before {
        content: '';
        position: fixed;
        width: 100%;
        background-image: url(../images-m/list/bg-list-m.jpg);
        background-size: 26.25rem 18.75rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: #f2eae7;
        background-position: top center;
        height: 100%;
        top: 0;
        left: 0
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .list-section:before {
            background-image: url(../images-m/list/bg-list-m@2x.jpg)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .list-section:before {
            background-image: url(../images-m/list/bg-list-m@3x.jpg)
        }
    }

    .list-section .lesson-list {
        margin: 0 auto;
        padding: 0;
        list-style: none
    }

    .list-section .lesson-list:after {
        display: block;
        clear: both;
        content: ""
    }

    .list-section .lesson-list>li {
        display: block;
        float:initial;
        padding: 0 1.25rem;
        margin-bottom: .625rem;
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        transition: all 1s ease;
        opacity: 1
    }

    .list-section .lesson-list>li.nv {
        opacity: 0;
        -webkit-transform: translateY(50px);
        -moz-transform: translateY(50px);
        -ms-transform: translateY(50px);
        -o-transform: translateY(50px);
        transform: translateY(50px)
    }

    .list-section .lesson-list>li .list-item {
        border-radius: 26px;
        box-shadow: 0 18px 27px 0 rgba(0, 0, 0, .08);
        -webkit-transition: transform .5s;
        -moz-transition: transform .5s;
        -ms-transition: transform .5s;
        -o-transition: transform .5s;
        transition: transform .5s;
        transform-style: preserve-3d;
        position: relative;
        width: 20rem;
        height: 19.58333rem;
        margin: 0 auto
    }

    .list-section .lesson-list>li .list-item .front {
        background: #fff;
        padding: 15px;
        text-align: center;
        position: absolute;
        width: 100%;
        height: 19.58333rem;
        backface-visibility: hidden;
        border-radius: 26px;
        border: 2px solid #fff
    }

    .list-section .lesson-list>li .list-item .front .thumb {
        width: 100%;
        margin-bottom: 23px
    }

    .list-section .lesson-list>li .list-item .front .thumb img {
        display: block;
        width: 100%
    }

    .list-section .lesson-list>li .list-item .front h5 {
        display: inline-block;
        color: #fff;
        font-size: 14px;
        font-family: CircularStd;
        font-weight: 700;
        background-color: #3dadd8;
        padding: 2px 13px;
        border-radius: 13px;
        margin-bottom: 10px
    }

    .list-section .lesson-list>li .list-item .front h2 {
        color: #252727;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: -.04em
    }

    .list-section .lesson-list>li .list-item .back {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg);
        height: 19.58333rem;
        border-radius: 26px;
        backface-visibility: hidden;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%
    }

    .list-section .lesson-list>li .list-item .back ul {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        height: 100%
    }

    .list-section .lesson-list>li .list-item .back ul li {
        position: relative;
        /*flex: 1*/
    }

    .list-section .lesson-list>li .list-item .back ul li a {
        position: relative;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3.91667rem;
        text-align: center;
        width: 100%;
        background-color: #ff9e00;
        color: #fff;
        font-size: 17px;
        font-weight: 500
    }

    @media screen and (max-width:768px) {
        .list-section .lesson-list>li .list-item .back ul li a {
            height: 100%
        }
    }

    .list-section .lesson-list>li .list-item .back ul li a:hover {
        background-color: #ff8400
    }

    .list-section .lesson-list>li .list-item .back ul li a:hover:before {
        background-color: #ff8400
    }

    .list-section .lesson-list>li .list-item .back ul li a.disabled {
        color: #ffcc87;
        background-color: #ff9e00
    }

    .list-section .lesson-list>li .list-item .back ul li a.disabled:hover {
        color: #ffcc87;
        background-color: #ff9e00
    }

    .list-section .lesson-list>li .list-item .back ul li+li:before {
        content: '';
        width: 100%;
        height: 1px;
        left: 0;
        top: -1px;
        background-color: #f8880d;
        display: block;
        position: absolute
    }

    .list-section .lesson-list>li .list-item .back ul li+li a:before {
        content: '';
        width: 100%;
        height: 1px;
        left: 0;
        top: 0;
        background-color: #ffaf2e;
        display: block;
        position: absolute
    }

    .list-section .lesson-list>li .list-item .back ul li:first-child a {
        border-top-left-radius: 26px;
        border-top-right-radius: 26px
    }

    .list-section .lesson-list>li .list-item .back ul li:last-child a {
        border-bottom-right-radius: 26px;
        border-bottom-left-radius: 26px
    }

    .list-section .lesson-list>li.hover .list-item {
        -webkit-transform: rotateY(180deg);
        -moz-transform: rotateY(180deg);
        -ms-transform: rotateY(180deg);
        -o-transform: rotateY(180deg);
        transform: rotateY(180deg)
    }

    .list-section .lesson-list>li.current .list-item .front {
        border-color: #ff9e00
    }

    .list-section .lesson-list>li.current .list-item .front h5 {
        background-color: #ff9e00
    }

    .list-section .lesson-list>li.locked {
        position: relative
    }

    .list-section .lesson-list>li.locked:before {
        content: '';
        width: 4.0625rem;
        height: 4.0625rem;
        background-image: url(../images-m/list/list-locked.png);
        background-size: 4.0625rem 4.0625rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url(../images/list/list-locked.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        position: absolute;
        z-index: 10;
        left: 50%;
        top: 50%;
        -webkit-transform: translateX(-50%) translateY(-50%);
        -moz-transform: translateX(-50%) translateY(-50%);
        -ms-transform: translateX(-50%) translateY(-50%);
        -o-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%)
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .list-section .lesson-list>li.locked:before {
            background-image: url(../images-m/list/list-locked@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .list-section .lesson-list>li.locked:before {
            background-image: url(../images-m/list/list-locked@3x.png)
        }
    }

}

@media screen and (min-width: 1501px) {
    .list-section .lesson-list {
        width: 1500px
    }

    .list-section .lesson-list > li {
        width: 20%
    }
}

@media screen and (min-width: 1201px) and (max-width: 1500px) {
    .list-section .lesson-list {
        width: 1200px
    }

    .list-section .lesson-list > li {
        width: 25%
    }
}

@media screen and (min-width: 1200px) and (max-width: 1500px) {
    .list-section .lesson-list {
        width: 900px
    }

    .list-section .lesson-list > li {
        width: 33.3333333333%
    }
}

/*@media screen and (max-width: 900px) {*/
    /*.list-section .lesson-list {*/
        /*width: 600px*/
    /*}*/

    /*.list-section .lesson-list > li {*/
        /*width: 50%*/
    /*}*/
/*}*/

.view-section {
    position: relative;
    width: 100%;
    height: 100vh;
    min-width: 768px;
    overflow-y: auto
}

@media screen and (max-width: 1199px) {
    @media screen and (orientation:landscape) {
        .view-section {
            min-width:initial;
            width: 100vw;
            height: 100vh
        }
    }
}



.view-section .btn-window-close {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: rgba(255, 255, 255, 0);
    background-image: url(../images/lecture/btn-window-close.png);
    background-repeat: no-repeat;
    background-position-x: right;
    background-position-y: center;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 120;
    width: 97px;
    height: 54px;
    border: 0;
    /*padding: 0 0 0 265px;*/
    text-indent: -9999px;
    background-position-y: bottom;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .btn-window-close {
        padding: 0 0 0 219px;
    }
}

.view-section .btn-window-close.disabled,
.view-section .btn-window-close:disabled {
    opacity: .65
}

.view-section .btn-window-close:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .btn-window-close:hover {
    background-image: url(../images/lecture/btn-window-close-hover.png)
}

.view-section .btn-menu-open {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-menu-open.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position-x: right;
    position: absolute;
    top: 50%;
    right: 0;
    border: 0;
    padding: 0;
    width: 52px;
    height: 253px;
    text-indent: -9999px;
    z-index: 125;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.view-section .btn-menu-open.disabled,
.view-section .btn-menu-open:disabled {
    opacity: .65
}

.view-section .btn-menu-open:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .btn-menu-open:hover {
    background-image: url(../images/lecture/btn-menu-open-hover.png);
    width: 60px
}

.view-section .btn-menu-close {
    position: fixed;
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-menu-close.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    width: 78px;
    height: 37px;
    border: 0;
    padding: 0;
    text-indent: -9999px;
    z-index: 125;
    right: 77px;
    top: 10px;
    display: none
}

.view-section .btn-menu-close.disabled,
.view-section .btn-menu-close:disabled {
    opacity: .65
}

.view-section .btn-menu-close:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .btn-menu-close:hover {
    background-image: url(../images/lecture/btn-menu-close-hover.png)
}



.view-section .right-menu {
    position: absolute;
    overflow: auto;
    right: 0;
    top: 0;
    bottom: 0;
    width: 0;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    border-left: 1px solid #e6e6e6;
    background-color: #fff
}



.view-section .right-menu .logo {
    width: 100%;
    height: 32px;
    margin: 70px 0 0;
    background-color: transparent;
    background-image: url(../images/lecture/logo.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .right-menu .progress {
    position: relative;
    display: block;
    width: 167px;
    height: 167px;
    border-radius: 50%;
    background-color: #ffc000;
    margin: 20px auto 15px
}


@media screen and (max-width: 1199px) {

    .view-section .btn-window-close {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-image: url(../images-m/lecture/btn-window-close-m.png);
        background-size: .8125rem .8125rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        border: 0;
        background-color: transparent;
        left: 1.04167rem;
        top: .72917rem;
        position: fixed;
        z-index: 950;
        width: .8125rem;
        height: .8125rem;
        padding: 0;
        text-indent: -9999px;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease
    }

    .view-section .btn-window-close.disabled,
    .view-section .btn-window-close:disabled {
        opacity: .65
    }

    .view-section .btn-window-close:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .btn-window-close {
            background-image: url(../images-m/lecture/btn-window-close-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .btn-window-close {
            background-image: url(../images-m/lecture/btn-window-close-m@3x.png)
        }
    }

    .view-section .btn-menu-open {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-image: url(../images-m/lecture/btn-menu-open-m.png);
        background-size: 1rem .8125rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: transparent;
        position: absolute;
        top: .72917rem;
        right: 1.25rem;
        border: 0;
        padding: 0;
        width: 1rem;
        height: .8125rem;
        text-indent: -9999px;
        z-index: 950;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        transform:none;
    }

    .view-section .btn-menu-open.disabled,
    .view-section .btn-menu-open:disabled {
        opacity: .65
    }

    .view-section .btn-menu-open:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .btn-menu-open {
            background-image: url(../images-m/lecture/btn-menu-open-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .btn-menu-open {
            background-image: url(../images-m/lecture/btn-menu-open-m@3x.png)
        }
    }

    .view-section .btn-menu-close {
        position: fixed;
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-image: url(../images-m/lecture/btn-menu-close-m.png);
        background-size: .8125rem .8125rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        background-color: transparent;
        width: .8125rem;
        height: .8125rem;
        border: 0;
        padding: 0;
        text-indent: -9999px;
        z-index: 1010;
        top: .72917rem;
        display: none
    }


    .view-section.menu-opened .btn-menu-open {
        right: 13.45833rem
    }

    .view-section.menu-opened .btn-menu-close {
        display: block;
        right: 13.45833rem
    }

    .view-section .btn-menu-close.disabled,
    .view-section .btn-menu-close:disabled {
        opacity: .65
    }

    .view-section .btn-menu-close:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .btn-menu-close {
            background-image: url(../images-m/lecture/btn-menu-close-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .btn-menu-close {
            background-image: url(../images-m/lecture/btn-menu-close-m@3x.png)
        }
    }

    .view-section .btn-menu-close:hover {
        background-image: inherit;
    }

    .view-section .btn-menu-open:hover {
        background-image: inherit;
    }

}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .right-menu .progress {
        width: 120px;
        height: 120px;
    }
}

.view-section .right-menu .progress:after {
    content: '';
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    -webkit-animation: colorload 2s;
    animation: colorload 2s
}

.view-section .right-menu .progress.percent-0:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(90deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-1:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(93.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-2:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(97.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-3:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(100.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-4:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(104.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-5:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(108deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-6:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(111.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-7:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(115.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-8:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(118.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-9:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(122.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-10:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(126deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-11:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(129.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-12:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(133.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-13:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(136.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-14:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(140.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-15:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(144deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-16:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(147.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-17:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(151.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-18:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(154.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-19:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(158.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-20:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(162deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-21:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(165.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-22:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(169.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-23:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(172.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-24:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(176.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-25:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(180deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-26:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(183.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-27:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(187.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-28:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(190.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-29:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(194.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-30:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(198deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-31:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(201.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-32:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(205.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-33:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(208.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-34:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(212.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-35:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(216deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-36:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(219.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-37:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(223.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-38:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(226.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-39:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(230.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-40:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(234deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-41:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(237.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-42:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(241.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-43:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(244.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-44:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(248.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-45:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(252deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-46:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(255.6deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-47:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(259.2deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-48:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(262.8deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-49:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(266.4deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-50:after {
    background-image: linear-gradient(90deg, #f1f2f7 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-50:after {
    background-image: linear-gradient(-90deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-51:after {
    background-image: linear-gradient(-86.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-52:after {
    background-image: linear-gradient(-82.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-53:after {
    background-image: linear-gradient(-79.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-54:after {
    background-image: linear-gradient(-75.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-55:after {
    background-image: linear-gradient(-72deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-56:after {
    background-image: linear-gradient(-68.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-57:after {
    background-image: linear-gradient(-64.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-58:after {
    background-image: linear-gradient(-61.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-59:after {
    background-image: linear-gradient(-57.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-60:after {
    background-image: linear-gradient(-54deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-61:after {
    background-image: linear-gradient(-50.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-62:after {
    background-image: linear-gradient(-46.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-63:after {
    background-image: linear-gradient(-43.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-64:after {
    background-image: linear-gradient(-39.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-65:after {
    background-image: linear-gradient(-36deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-66:after {
    background-image: linear-gradient(-32.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-67:after {
    background-image: linear-gradient(-28.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-68:after {
    background-image: linear-gradient(-25.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-69:after {
    background-image: linear-gradient(-21.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-70:after {
    background-image: linear-gradient(-18deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-71:after {
    background-image: linear-gradient(-14.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-72:after {
    background-image: linear-gradient(-10.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-73:after {
    background-image: linear-gradient(-7.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-74:after {
    background-image: linear-gradient(-3.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-75:after {
    background-image: linear-gradient(0deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-76:after {
    background-image: linear-gradient(3.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-77:after {
    background-image: linear-gradient(7.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-78:after {
    background-image: linear-gradient(10.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-79:after {
    background-image: linear-gradient(14.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-80:after {
    background-image: linear-gradient(18deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-81:after {
    background-image: linear-gradient(21.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-82:after {
    background-image: linear-gradient(25.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-83:after {
    background-image: linear-gradient(28.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-84:after {
    background-image: linear-gradient(32.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-85:after {
    background-image: linear-gradient(36deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-86:after {
    background-image: linear-gradient(39.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-87:after {
    background-image: linear-gradient(43.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-88:after {
    background-image: linear-gradient(46.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-89:after {
    background-image: linear-gradient(50.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-90:after {
    background-image: linear-gradient(54deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-91:after {
    background-image: linear-gradient(57.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-92:after {
    background-image: linear-gradient(61.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-93:after {
    background-image: linear-gradient(64.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-94:after {
    background-image: linear-gradient(68.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-95:after {
    background-image: linear-gradient(72deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-96:after {
    background-image: linear-gradient(75.6deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-97:after {
    background-image: linear-gradient(79.2deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-98:after {
    background-image: linear-gradient(82.8deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-99:after {
    background-image: linear-gradient(86.4deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress.percent-100:after {
    background-image: linear-gradient(90deg, #ffc000 50%, transparent 50%, transparent), linear-gradient(270deg, #ffc000 50%, #f1f2f7 50%, #f1f2f7)
}

.view-section .right-menu .progress .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 146px;
    height: 146px;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1;
    border-radius: 100%;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .right-menu .progress .inner {
        width: 100px;
        height: 100px;
    }
}

.view-section .right-menu .progress .inner .percent {
    font-weight: 500;
    font-family: CircularStd, sans-serif;
    color: #414141;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: flex-start
}

.view-section .right-menu .progress .inner .percent .number {
    font-size: 48px;
    display: block;
    line-height: 45px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .right-menu .progress .inner .percent .number {
        font-size: 42px;
    }
}

.view-section .right-menu .progress .inner .percent .bullet {
    font-size: 28px;
    display: block;
    line-height: 1em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .right-menu .progress .inner .percent .bullet {
        font-size: 24px;
        display: block;
        line-height: 1em
    }
}

.view-section .right-menu .progress .inner .steps {
    margin-top: 7px;
    color: #bebebe;
    font-size: 22px;
    font-weight: 700;
    text-align: center
}

.view-section .right-menu .divider {
    width: 100%;
    height: 1px;
    background: #efefef
}

.view-section .right-menu .nav-lists {
    padding: 12px
}

.view-section .right-menu .nav-lists > ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.view-section .right-menu .nav-lists > ul > li {
    border-radius: 20px
}

.view-section .right-menu .nav-lists > ul > li + li {
    margin-top: 6px
}

.view-section .right-menu .nav-lists > ul > li .nav-list-title {
    background-color: #ff9e00;
    width: 100%;
    display: block;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    line-height: 40px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative
}

@media screen and (max-width: 1199px) {
    .view-section .right-menu .nav-lists > ul > li .nav-list-title {
        font-weight: 500;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .right-menu .nav-lists > ul > li .nav-list-title {
        font-size: 13px;
        line-height: 30px;

    }
}

.view-section .right-menu .nav-lists > ul > li .nav-list-title:after {
    content: '';
    width: 11px;
    height: 8px;
    position: absolute;
    background-color: transparent;
    background-image: url(../images/lecture/nav-opened.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px
}

.view-section .right-menu .nav-lists > ul > li .nav-list-title:hover {
    background-color: #ffc000;
    color: #fff
}

.view-section .right-menu .nav-lists > ul > li.closed .nav-list-title {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    color: #fff000
}

.view-section .right-menu .nav-lists > ul > li.closed .nav-list-title:after {
    background-image: url(../images/lecture/nav-closed.png)
}
@media screen and (max-width: 1199px){
    .view-section .right-menu .nav-lists > ul > li.closed .nav-list-title {
        border-bottom-left-radius: initial;
        border-bottom-right-radius: initial;
        color: initial;
    }

    .view-section .right-menu .nav-lists > ul > li.closed .nav-list-title:after {
        background-image:initial;
    }
    .view-section .right-menu .nav-lists > ul > li .nav-list-title:hover {
        background-color: initial;
        color: initial;
    }
}
.view-section .right-menu .nav-lists > ul > li > .sub {
    margin-top: 2px
}

.view-section .right-menu .nav-lists > ul > li > .sub > li + li {
    border-top: 1px solid #ffb63f
}
@media screen and (max-width: 1199px) {
    .view-section .right-menu .nav-lists > ul > li > .sub > li + li {
        border-top: none;
    }
}

.view-section .right-menu .nav-lists > ul > li > .sub > li:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px
}
@media screen and (max-width: 1199px) {
    .view-section .right-menu .nav-lists > ul > li > .sub > li:last-child {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
    }
}



.view-section .right-menu .nav-lists > ul > li > .sub > li > a {
    display: block;
    line-height: 38px;
    background-color: #ff9e00;
    padding-left: 45px;
    padding-right: 10px;
    color: #fff;
    letter-spacing: -.02em;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-wrap: normal;
    overflow: hidden;
    background-color: #ff9e00;
    background-image: url(../images/lecture/icon-lecture.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position-x: 13px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .right-menu .nav-lists > ul > li > .sub > li > a {
        padding-left: 40px;
        padding-right: 10px;
        background-position-x: 10px
    }
}

.view-section .right-menu .nav-lists > ul > li > .sub > li:last-child > a {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px
}
@media screen and (max-width: 1199px) {
    .view-section .right-menu .nav-lists > ul > li > .sub > li:last-child > a {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px
    }
}

.view-section .right-menu .nav-lists > ul > li > .sub > li:hover > a {
    background-image: url(../images/lecture/icon-lecture-current.png)
}

.view-section .right-menu .nav-lists > ul > li > .sub > li.current {
    box-shadow: 0 5px 8px 0 rgba(0, 0, 0, .11);
    position: relative
}


@media screen and (max-width: 1199px) {
    .view-section .right-menu .nav-lists > ul > li > .sub > li.current {
        box-shadow: initial;
        position: initial;
    }
}


.view-section .right-menu .nav-lists > ul > li > .sub > li.current > a {
    background-color: #ffc000;
    background-image: url(../images/lecture/icon-lecture-current.png)
}

.view-section .right-menu .nav-lists > ul > li > .sub > li.disabled {
}

.view-section .right-menu .nav-lists > ul > li > .sub > li.disabled > a {
    background-color: #ffe6b2;
    color: #ff9e00;
    cursor: default;
    background-image: url(../images/lecture/icon-lecture-disabled.png)
}

.view-section .right-menu .nav-lists > ul > li > .sub > li.disabled:hover > a {
    /*background-image: url(../images/lecture/icon-lecture.png)*/
}

@media screen and (max-width: 1199px){
    .view-section .right-menu .nav-lists > ul > li > .sub > li.disabled > a {
        background-color: initial;
        color: #d2d2d2;
        cursor: default;
        background-image: initial;

    }
}



@media screen and (max-width: 1199px) {

    .view-section .right-menu {
        position: absolute;
        overflow: hidden;
        right: 0;
        top: 0;
        bottom: 0;
        width: 0;
        z-index: 1010;
        opacity: 0;
        visibility: hidden;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        border-left: 1px solid #e6e6e6;
        background-color: #fff;
        overflow-y: auto
    }

    .view-section .right-menu .logo {
        display: none
    }

    .view-section .right-menu .progress {
        display: none
    }

    .view-section .right-menu .divider {
        width: 100%;
        height: 1px;
        background: #efefef
    }

    .view-section .right-menu .nav-lists {
        background-color: #fff;
        padding:0;
    }

    .view-section .right-menu .nav-lists>ul {
        list-style: none;
        padding: 0;
        margin: 0
    }

    .view-section .right-menu .nav-lists>ul>li .nav-list-title {
        width: 100%;
        height: 2.29167rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 0;
        box-shadow: none;
        color: #666;
        font-size: .6875rem;
        background-color: #fff;
        border-bottom: 1px solid #e6e6e6;
        text-align: left;
        padding: 0 1.04167rem
    }

    .view-section .right-menu .nav-lists>ul>li>.sub {
        padding-left: 1.72917rem
    }

    .view-section .right-menu .nav-lists>ul>li>.sub>li>a {
        display: block;
        letter-spacing: -.02em;
        width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-wrap: normal;
        overflow: hidden;
        background-color: #fff;
        background-image: none;
        padding: 9px 0;
        color: #323232;
        font-size: 13px;
        line-height: 1.2em;
        border-bottom: 1px solid #e6e6e6
    }

    .view-section .right-menu .nav-lists>ul>li>.sub>li.current>a {
        color: #007f93;
        background-color: initial;
        background-image: initial;
    }
}


/*.view-section .right-menu .nav-lists>ul>li>.sub>li.disabled>a:hover {*/
/*background-image: url(../images/lecture/icon-lecture.png)*/
/*}*/

.view-section .view-container {
    width: 100%;
    height: 100%;
    background-color: #f7f8fc;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.view-section .view-header {
    width: 100%;
    height: 74px;
    position: relative;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center
}

.view-section .view-header h2 {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center
}

.view-section .view-header h2 .grade {
    background-color: transparent;
    background-image: url(../images/lecture/bg-grade.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 47px;
    height: 47px;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-weight: 700
}

@media screen and (max-width: 1199px) {
    .view-section .view-header h2 .grade {
       display:none;
    }
}


.view-section .view-header h2 .grade + .title {
    margin-left: 20px
}

.view-section .view-header h2 .title {
    color: #3f3d3d;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -.04em
}

.view-section .view-header .progress{
    display:none;
}

@media screen and (max-width: 1199px) {

    .view-section .view-header {
        width: 100%;
        height: 2.3rem;
        position: relative;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #f5f5f5;
        padding: 0 5rem 0 2.70833rem;
        z-index: 900
    }

    .view-section .view-header h2 {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center
    }

    .view-section .view-header h2 .grade {
        display: none
    }

    .view-section .view-header h2 .title {
        color: #222;
        font-size: .75rem;
        font-weight: 700;
        letter-spacing: -.04em
    }

    .view-section .view-header .progress {
        width: 15rem;
        height: .45833rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center
    }

    .view-section .view-header .progress:after,
    .view-section .view-header .progress:before {
        content: '';
        display: block;
        width: .45833rem;
        height: .45833rem;
        border-radius: 100%;
        background-color: #d4d4d4
    }

    .view-section .view-header .progress:before {
        background-color: #ff7e00
    }

    .view-section .view-header .progress .inner {
        -ms-flex: 1;
        -webkit-flex: 1;
        flex: 1;
        height: .20833rem;
        background-color: #d4d4d4
    }

    .view-section .view-header .progress .inner .percent {
        background-color: #ff7e00;
        height: .20833rem
    }

    .view-section .view-header .progress .inner .percent .bullet,
    .view-section .view-header .progress .inner .percent .number {
        display: none
    }

    .view-section .view-header .progress.percent-100:after {
        background-color: #ff7e00
    }

    .view-section .view-header .progress.percent-0 .inner .percent {
        width: 0%
    }

    .view-section .view-header .progress.percent-1 .inner .percent {
        width: 1%
    }

    .view-section .view-header .progress.percent-2 .inner .percent {
        width: 2%
    }

    .view-section .view-header .progress.percent-3 .inner .percent {
        width: 3%
    }

    .view-section .view-header .progress.percent-4 .inner .percent {
        width: 4%
    }

    .view-section .view-header .progress.percent-5 .inner .percent {
        width: 5%
    }

    .view-section .view-header .progress.percent-6 .inner .percent {
        width: 6%
    }

    .view-section .view-header .progress.percent-7 .inner .percent {
        width: 7%
    }

    .view-section .view-header .progress.percent-8 .inner .percent {
        width: 8%
    }

    .view-section .view-header .progress.percent-9 .inner .percent {
        width: 9%
    }

    .view-section .view-header .progress.percent-10 .inner .percent {
        width: 10%
    }

    .view-section .view-header .progress.percent-11 .inner .percent {
        width: 11%
    }

    .view-section .view-header .progress.percent-12 .inner .percent {
        width: 12%
    }

    .view-section .view-header .progress.percent-13 .inner .percent {
        width: 13%
    }

    .view-section .view-header .progress.percent-14 .inner .percent {
        width: 14%
    }

    .view-section .view-header .progress.percent-15 .inner .percent {
        width: 15%
    }

    .view-section .view-header .progress.percent-16 .inner .percent {
        width: 16%
    }

    .view-section .view-header .progress.percent-17 .inner .percent {
        width: 17%
    }

    .view-section .view-header .progress.percent-18 .inner .percent {
        width: 18%
    }

    .view-section .view-header .progress.percent-19 .inner .percent {
        width: 19%
    }

    .view-section .view-header .progress.percent-20 .inner .percent {
        width: 20%
    }

    .view-section .view-header .progress.percent-21 .inner .percent {
        width: 21%
    }

    .view-section .view-header .progress.percent-22 .inner .percent {
        width: 22%
    }

    .view-section .view-header .progress.percent-23 .inner .percent {
        width: 23%
    }

    .view-section .view-header .progress.percent-24 .inner .percent {
        width: 24%
    }

    .view-section .view-header .progress.percent-25 .inner .percent {
        width: 25%
    }

    .view-section .view-header .progress.percent-26 .inner .percent {
        width: 26%
    }

    .view-section .view-header .progress.percent-27 .inner .percent {
        width: 27%
    }

    .view-section .view-header .progress.percent-28 .inner .percent {
        width: 28%
    }

    .view-section .view-header .progress.percent-29 .inner .percent {
        width: 29%
    }

    .view-section .view-header .progress.percent-30 .inner .percent {
        width: 30%
    }

    .view-section .view-header .progress.percent-31 .inner .percent {
        width: 31%
    }

    .view-section .view-header .progress.percent-32 .inner .percent {
        width: 32%
    }

    .view-section .view-header .progress.percent-33 .inner .percent {
        width: 33%
    }

    .view-section .view-header .progress.percent-34 .inner .percent {
        width: 34%
    }

    .view-section .view-header .progress.percent-35 .inner .percent {
        width: 35%
    }

    .view-section .view-header .progress.percent-36 .inner .percent {
        width: 36%
    }

    .view-section .view-header .progress.percent-37 .inner .percent {
        width: 37%
    }

    .view-section .view-header .progress.percent-38 .inner .percent {
        width: 38%
    }

    .view-section .view-header .progress.percent-39 .inner .percent {
        width: 39%
    }

    .view-section .view-header .progress.percent-40 .inner .percent {
        width: 40%
    }

    .view-section .view-header .progress.percent-41 .inner .percent {
        width: 41%
    }

    .view-section .view-header .progress.percent-42 .inner .percent {
        width: 42%
    }

    .view-section .view-header .progress.percent-43 .inner .percent {
        width: 43%
    }

    .view-section .view-header .progress.percent-44 .inner .percent {
        width: 44%
    }

    .view-section .view-header .progress.percent-45 .inner .percent {
        width: 45%
    }

    .view-section .view-header .progress.percent-46 .inner .percent {
        width: 46%
    }

    .view-section .view-header .progress.percent-47 .inner .percent {
        width: 47%
    }

    .view-section .view-header .progress.percent-48 .inner .percent {
        width: 48%
    }

    .view-section .view-header .progress.percent-49 .inner .percent {
        width: 49%
    }

    .view-section .view-header .progress.percent-50 .inner .percent {
        width: 50%
    }

    .view-section .view-header .progress.percent-51 .inner .percent {
        width: 51%
    }

    .view-section .view-header .progress.percent-52 .inner .percent {
        width: 52%
    }

    .view-section .view-header .progress.percent-53 .inner .percent {
        width: 53%
    }

    .view-section .view-header .progress.percent-54 .inner .percent {
        width: 54%
    }

    .view-section .view-header .progress.percent-55 .inner .percent {
        width: 55%
    }

    .view-section .view-header .progress.percent-56 .inner .percent {
        width: 56%
    }

    .view-section .view-header .progress.percent-57 .inner .percent {
        width: 57%
    }

    .view-section .view-header .progress.percent-58 .inner .percent {
        width: 58%
    }

    .view-section .view-header .progress.percent-59 .inner .percent {
        width: 59%
    }

    .view-section .view-header .progress.percent-60 .inner .percent {
        width: 60%
    }

    .view-section .view-header .progress.percent-61 .inner .percent {
        width: 61%
    }

    .view-section .view-header .progress.percent-62 .inner .percent {
        width: 62%
    }

    .view-section .view-header .progress.percent-63 .inner .percent {
        width: 63%
    }

    .view-section .view-header .progress.percent-64 .inner .percent {
        width: 64%
    }

    .view-section .view-header .progress.percent-65 .inner .percent {
        width: 65%
    }

    .view-section .view-header .progress.percent-66 .inner .percent {
        width: 66%
    }

    .view-section .view-header .progress.percent-67 .inner .percent {
        width: 67%
    }

    .view-section .view-header .progress.percent-68 .inner .percent {
        width: 68%
    }

    .view-section .view-header .progress.percent-69 .inner .percent {
        width: 69%
    }

    .view-section .view-header .progress.percent-70 .inner .percent {
        width: 70%
    }

    .view-section .view-header .progress.percent-71 .inner .percent {
        width: 71%
    }

    .view-section .view-header .progress.percent-72 .inner .percent {
        width: 72%
    }

    .view-section .view-header .progress.percent-73 .inner .percent {
        width: 73%
    }

    .view-section .view-header .progress.percent-74 .inner .percent {
        width: 74%
    }

    .view-section .view-header .progress.percent-75 .inner .percent {
        width: 75%
    }

    .view-section .view-header .progress.percent-76 .inner .percent {
        width: 76%
    }

    .view-section .view-header .progress.percent-77 .inner .percent {
        width: 77%
    }

    .view-section .view-header .progress.percent-78 .inner .percent {
        width: 78%
    }

    .view-section .view-header .progress.percent-79 .inner .percent {
        width: 79%
    }

    .view-section .view-header .progress.percent-80 .inner .percent {
        width: 80%
    }

    .view-section .view-header .progress.percent-81 .inner .percent {
        width: 81%
    }

    .view-section .view-header .progress.percent-82 .inner .percent {
        width: 82%
    }

    .view-section .view-header .progress.percent-83 .inner .percent {
        width: 83%
    }

    .view-section .view-header .progress.percent-84 .inner .percent {
        width: 84%
    }

    .view-section .view-header .progress.percent-85 .inner .percent {
        width: 85%
    }

    .view-section .view-header .progress.percent-86 .inner .percent {
        width: 86%
    }

    .view-section .view-header .progress.percent-87 .inner .percent {
        width: 87%
    }

    .view-section .view-header .progress.percent-88 .inner .percent {
        width: 88%
    }

    .view-section .view-header .progress.percent-89 .inner .percent {
        width: 89%
    }

    .view-section .view-header .progress.percent-90 .inner .percent {
        width: 90%
    }

    .view-section .view-header .progress.percent-91 .inner .percent {
        width: 91%
    }

    .view-section .view-header .progress.percent-92 .inner .percent {
        width: 92%
    }

    .view-section .view-header .progress.percent-93 .inner .percent {
        width: 93%
    }

    .view-section .view-header .progress.percent-94 .inner .percent {
        width: 94%
    }

    .view-section .view-header .progress.percent-95 .inner .percent {
        width: 95%
    }

    .view-section .view-header .progress.percent-96 .inner .percent {
        width: 96%
    }

    .view-section .view-header .progress.percent-97 .inner .percent {
        width: 97%
    }

    .view-section .view-header .progress.percent-98 .inner .percent {
        width: 98%
    }

    .view-section .view-header .progress.percent-99 .inner .percent {
        width: 99%
    }

    .view-section .view-header .progress.percent-100 .inner .percent {
        width: 100%
    }

}

.view-section .view-body {
    position: relative;
    height: calc(100% - 74px - 120px);
}

.view-section .view-body .btn-tip-toggle {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    position: absolute;
    right: 20px;
    bottom: 20px;
    width: 48px;
    height: 52px;
    text-indent: -9999px;
    border: 0;
    padding: 0;
    z-index: 103;
    background-color: transparent;
    background-image: url(../images/lecture/btn-tip-closed.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

.view-section .view-body .btn-tip-toggle.disabled,
.view-section .view-body .btn-tip-toggle:disabled {
    opacity: .65
}

.view-section .view-body .btn-tip-toggle:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .view-body .btn-tip-toggle:hover {
    background-image: url(../images/lecture/btn-tip-closed-hover.png)
}


@media screen and (max-width: 1199px) {

    .view-section .view-body .btn-tip-toggle {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        position: fixed;
        top: .58333rem;
        right: 2.91667rem;
        width: 1.375rem;
        height: 1.375rem;
        text-indent: -9999px;
        border: 0;
        padding: 0;
        z-index: 950;
        background-image: url(../images-m/lecture/btn-tip-m.png);
        background-size: 1.375rem 1.375rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: transparent;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease
    }

    .view-section .view-body .btn-tip-toggle.disabled,
    .view-section .view-body .btn-tip-toggle:disabled {
        opacity: .65
    }

    .view-section .view-body .btn-tip-toggle:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-body .btn-tip-toggle {
            background-image: url(../images-m/lecture/btn-tip-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-body .btn-tip-toggle {
            background-image: url(../images-m/lecture/btn-tip-m@3x.png)
        }
    }
}


.view-section .view-body .lecture-step {
    position: absolute;
    left: 36px;
    bottom: 20px;
    color: #bebebe;
    font-size: 22px;
    font-family: CircularStd, sans-serif;
    font-weight: 700
}
@media screen and (max-width: 1199px) {
    .view-section .view-body .lecture-step {
        display:none;
    }
}

.view-section .view-body .lecture-step .current {
    color: #00759c;
    font-size: 40px;
    font-weight: 700;
    padding-bottom: 20px;
    display: inline-block;
    vertical-align: bottom;
    margin-right: 5px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .view-body .lecture-step .current {
        font-size: 36px;
    }
}

.view-section .view-body .lecture-step .total {
    padding-top: 20px;
    margin-left: 5px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .view-body .lecture-step .total {
        padding-top: 18px;
    }
}

.view-section .view-body .content-wrap {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    overflow-y: auto
}

.view-section .view-body .content-wrap.justify-start {
    align-items: flex-start
}

@media screen and (max-width: 1199px){
    .view-section .view-body .content-wrap.justify-start {
        justify-content: flex-start;
        align-items:center;
    }
}


.view-section .view-body .content-wrap.align-start {
    justify-content: flex-start
}

.view-section .view-body .tip-container {
    display: none;
    position: absolute;
    right: 10px;
    padding-top: 35px;
    min-height: 225px;
    bottom: 0;
    padding-right: 230px;
    background-color: transparent;
    /*background-image: url(../images/lecture/bg-tip.png);*/
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position: bottom right;
    z-index: 102;
}

.view-section .view-body .tip-bg-1 {
    background-image: url(../images/lecture/bg-tip-1.png)
}
.view-section .view-body .tip-bg-2 {
    background-image: url(../images/lecture/bg-tip-2.png);
    /*padding-top: 48px;*/
    /*padding-right: 210px;*/
}

.view-section .view-body .tip-bg-3 {
    background-image: url(../images/lecture/bg-tip-3.png);
    padding-top: 45px;
    padding-right: 235px;
}

.view-section .view-body .tip-bg-4 {
    background-image: url(../images/lecture/bg-tip-4.png)
}



@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .view-body .tip-container {
        background-size: auto 150px;
        padding-top: 25px;
        min-height: 180px;
        padding-right: 145px;
        max-width: 600px;
        right:5px;
    }
}

.view-section .view-body .tip-container .message-box {
    padding: 15px 25px;
    background-color: #ffc000;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .03);
    border-radius: 8px;
    position: relative;
    margin-right: 13px;
    color: #313030;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.4375em;
    letter-spacing: -.02em;
    z-index: 999;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .view-body .tip-container .message-box {
        padding: 12px 20px;
        font-size: 15px;
        min-height: 72px;
    }
}

.view-section .view-body .tip-container .message-box strong {
    color: #3751ff;
    font-weight: 500
}

.view-section .view-body .tip-container .message-box .divider {
    background-color: rgba(123, 123, 123, 0.45);
    height: 1px;
    width: 100%;
    display: block;
    border: 0 none;
    margin: 10px 0 15px
}

.view-section .view-body .tip-container .message-box:after {
    content: '';
    position: absolute;
    width: 13px;
    height: 13px;
    background-color: transparent;
    background-image: url(../images/lecture/tip-message-box-arrow_yellow.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    top: 50%;
    right: -13px
}

.view-section .view-body .tip-container .message-box .tip {
    padding-left: 45px;
    background-color: transparent;
    /*background-image: url(../images/lecture/tip-message.png);*/
    background-image: url(../images/lecture/tip-message_yellow.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position: top left;
    color: #565656;
    font-size: 15px;
    min-height: 26px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .view-body .tip-container .message-box .tip {
        font-size: 14px
    }
}

.view-section .view-body.tip-opened .btn-tip-toggle {
    background-image: url(../images/lecture/btn-tip-opened.png)
}

.view-section .view-body.tip-opened .btn-tip-toggle:hover {
    background-image: url(../images/lecture/btn-tip-opened-hover.png)
}

.view-section .view-body.tip-opened .tip-container {
    display: block;
}

@media screen and (max-width: 1199px){

    .view-section .view-body .tip-container {
        display: none;
        position: absolute;
        right: 10px;
        padding-top: 0;
        min-height: initial;
        top: 20px;
        bottom:initial;
        padding-right: 0;
        background-color: transparent;
        /*background-image: url(../images/lecture/bg-tip.png);*/
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-position: bottom right;
        z-index: 102;
    }

    .view-section .view-body .tip-bg-1 {
        background-image: initial;
    }
    .view-section .view-body .tip-bg-2 {
        background-image: initial;
        /*padding-top: 48px;*/
        /*padding-right: 210px;*/
    }

    .view-section .view-body .tip-bg-3 {
        background-image: initial;
        padding-top: initial;
        padding-right: initial;
    }

    .view-section .view-body .tip-bg-4 {
        background-image: initial;
    }

    .view-section .view-body .tip-container .message-box {
        padding: 10px 12px;
        background-color: #ffc000;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .03);
        border-radius: 8px;
        position: relative;
        margin-right: 13px;
        color: #313030;
        font-size: 13px;
        font-weight: 400;
        line-height: 1.4375em;
        letter-spacing: -.02em;
        z-index: 999;
    }



    .view-section .view-body .tip-container .message-box strong {
        color: #3751ff;
        font-weight: 500
    }

    .view-section .view-body .tip-container .message-box .divider {
        background-color: rgba(123, 123, 123, 0.45);
        height: 1px;
        width: 100%;
        display: block;
        border: 0 none;
        margin: 10px 0 15px
    }

    .view-section .view-body .tip-container .message-box:after {
        content: '';
        position: absolute;
        width: 13px;
        height: 13px;
        background-color: transparent;
        background-image: url(../images/lecture/tip-message-box-arrow_yellow-m.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        top: -13px;
        right: 27px
    }

    .view-section .view-body .tip-container .message-box .tip {
        padding-left: 40px;
        background-color: transparent;
        /*background-image: url(../images/lecture/tip-message.png);*/
        background-image: url(../images-m/lecture/tip-message_yellow-m.png);
        background-repeat: no-repeat;
        /*background-position-x: center;*/
        /*background-position-y: center;*/
        background-position: top left;
        background-size: 30px;
        color: #565656;
        font-size: 13px;
        min-height: 26px;
    }


    .view-section .view-body.tip-opened .btn-tip-toggle {
        background-image: url(../images/lecture/btn-tip-opened.png)
    }

    .view-section .view-body.tip-opened .btn-tip-toggle:hover {
        background-image: url(../images/lecture/btn-tip-opened-hover.png)
    }

    .view-section .view-body.tip-opened .tip-container {
        display: block;
    }
}



.view-section .view-footer {
    width: 100%;
    height: 120px;
    background-color: #024050;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .view-section .view-body {
        height: calc(100% - 74px - 100px);
    }

    .view-section .view-footer {
        height: 100px;
    }
}

.view-section .view-footer .f-btn {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    height: 120px;
    border: 0;
    background-color: #024050;
    /*width: 90px;*/
    min-width: 200px;
    /*최소 넒이*/
    text-indent: -9999px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .view-section .view-footer .f-btn {
        height: 100px;
    }

}

.view-section .view-footer .f-btn.disabled,
.view-section .view-footer .f-btn:disabled {
    opacity: .65
}

.view-section .view-footer .f-btn:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .view-footer .f-center {
    -webkit-flex: 0.2;
    -ms-flex: 0.2;
    flex: 0.2;
    /*margin: 0 auto;*/
    /*좌우 버튼을 중앙으로 모으기 위해 수정함*/
    width: 200px;
    border-left: 1px solid #1d5463;
    border-right: 1px solid #1d5463
}

.view-section .view-footer .f-center-2 {
    -webkit-flex: 0.2;
    -ms-flex: 0.2;
    flex: 0.4;
    margin: 0 auto;
    width: 600px;
    border-left: 1px solid #1d5463;
    border-right: 1px solid #1d5463;
    padding: 0 40px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .view-footer .f-center-2 {
        min-width: 600px;
    }
}

.view-section .view-footer .f-center-grouping {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 0.2 1;
    display: flex;
    justify-content: center;

}

.view-section .view-footer .f-center-multiple {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 0.2;
    min-width: 260px;
    border-left: 1px solid #1d5463;
    border-right: 1px solid #1d5463
}

@media screen and (max-width: 1199px){
    .view-section .view-footer .f-center-multiple {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 0.2;
        min-width: 100px;
        border-left: 1px solid #1d5463;
        border-right: 1px solid #1d5463
    }
}


.view-section .view-footer .recording-controller {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    /*align-items: center;*/
    justify-content: center
}

.view-section .view-footer .recording-controller .timer-wrap .timer-value {
    color: #fff;
    font-size: 36px;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: .04em;
    margin-bottom: 10px;
    margin-top: 10px;
    min-height: 24px;
}


.view-section .view-footer .recording-controller .timer-wrap .timer-value .record-kor{
    font-size: 28px;
    font-family: CircularStd, "Noto Sans KR", sans-serif;
}

@media screen and (max-width: 1199px){
    .view-section .view-footer .recording-controller .timer-wrap .timer-value .record-kor{
        font-size: 0.9rem;
    }
}


.view-section .view-footer .recording-controller .timer-wrap {
    align-self: center;
    min-width: 200px;
}

.view-section .view-footer .recording-controller .timer-wrap h5 {
    color: #fff;
    font-size: 12px;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.02em
}

.view-section .view-footer .recording-controller .record-visualizer {
    margin: 0 40px 0 45px
}

.view-section .view-footer .btn-record-done {
    background-color: transparent;
    background-image: url(../images/lecture/btn-record-done.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-record-done:hover {
    background-color: #013846;
    opacity: .6
}

.view-section .view-footer .btn-prev {
    background-color: transparent;
    background-image: url(../images/lecture/btn-prev.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-prev:hover {
    background-color: #013846;
    background-image: url(../images/lecture/btn-prev-hover.png)
}

.view-section .view-footer .btn-check-correct {
    background-color: transparent;
    background-image: url("../images/lecture/btn-check-correct.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.view-section .view-footer .btn-check-correct:hover {
    background-image: url(../images/lecture/btn-check-correct-hover.png);
}

.view-section .view-footer .btn-prev-page {
    background-color: transparent;
    background-image: url(../images/lecture/btn-next-page.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-prev-page:hover {
    background-image: url(../images/lecture/btn-next-page-hover.png)
}

.view-section .view-footer .btn-next {
    background-color: transparent;
    background-image: url(../images/lecture/btn-next.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-next:hover {
    background-color: #013846;
    background-image: url(../images/lecture/btn-next-hover.png)
}

.view-section .view-footer .btn-retest {
    background-color: transparent;
    background-image: url(../images/lecture/btn-retest.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-retest:hover {
    background-image: url(../images/lecture/btn-retest-hover.png)
}

.view-section .view-footer .btn-stop {
    background-color: transparent;
    background-image: url(../images/lecture/stop_temp.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-stop:hover {
    background-image: url(../images/lecture/stop_temp_hover.png)
}

.view-section .view-footer .btn-test {
    background-color: transparent;
    background-image: url(../images/lecture/btn-test.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-test:hover {
    background-image: url(../images/lecture/btn-test-hover.png)
}

.view-section .view-footer .btn-recording {
    background-color: transparent;
    background-image: url(../images/lecture/btn-recording.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-recording:hover {
    background-image: url(../images/lecture/btn-recording-hover.png)
}

.view-section .view-footer .btn-play {
    background-color: transparent;
    background-image: url(../images/lecture/btn-play.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-play:hover {
    background-image: url(../images/lecture/btn-play-hover.png)
}

.view-section .view-footer .btn-re-play {
    background-color: transparent;
    background-image: url(../images/lecture/btn-re-play.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-re-play:hover {
    background-image: url(../images/lecture/btn-re-play-hover.png)
}

.view-section .view-footer .btn-listen {
    background-color: transparent;
    background-image: url(../images/lecture/btn-listen.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-listen:hover {
    background-image: url(../images/lecture/btn-listen-hover.png)
}

.view-section .view-footer .btn-next-page {
    background-color: transparent;
    background-image: url(../images/lecture/btn-next-page.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-next-page:hover {
    background-image: url(../images/lecture/btn-next-page-hover.png)
}

.view-section .view-footer .btn-listen-all {
    background-color: transparent;
    background-image: url(../images/lecture/btn-listen-all.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-listen-all:hover {
    background-image: url(../images/lecture/btn-listen-all-hover.png)
}

.view-section .view-footer .btn-listen-text {
    background-color: transparent;
    background-image: url(../images/lecture/btn-listen-text.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .view-footer .btn-listen-text:hover {
    background-image: url(../images/lecture/btn-listen-text-hover.png)
}


@media screen and (max-width: 1199px) {

    .view-section .view-body {
        height: calc(100% - 2.3rem - 3.125rem);
    }

    .view-section .view-footer {
        width: 100%;
        height: 3.125rem;
        background-color: #024050;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex
    }

    .view-section .view-footer .f-btn {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        height: 3.125rem;
        border: 0;
        background-color: #024050;
        width: 90px;
        min-width: 120px;
        text-indent: -9999px;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease
    }

    .view-section .view-footer .f-btn.disabled,
    .view-section .view-footer .f-btn:disabled {
        opacity: .65
    }

    .view-section .view-footer .f-btn:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .view-section .view-footer .f-center {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: auto
    }

    .view-section .view-footer .recording-controller {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-item:initial;
        justify-content: center
    }

    .view-section .view-footer .recording-controller .timer-wrap .timer-value {
        color: #fff;
        font-size: .9375rem;
        font-family: CircularStd, sans-serif;
        font-weight: 500;
        text-align: center;
        letter-spacing: .04em;
        margin-bottom: 0;
        margin-top:0;
    }

    .view-section .view-footer .recording-controller .timer-wrap h5 {
        color: #fff;
        font-size: .45833rem;
        font-family: CircularStd, sans-serif;
        font-weight: 500;
        text-align: center;
        letter-spacing: -.02em
    }

    .view-section .view-footer .recording-controller .record-visualizer {
        margin: 0 40px 0 45px
    }

    .view-section .view-footer .btn-record-done {
        background-image: url(../images-m/lecture/btn-record-done-m.png);
        background-size: 2.5625rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-record-done {
            background-image: url(../images-m/lecture/btn-record-done-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-record-done {
            background-image: url(../images-m/lecture/btn-record-done-m@3x.png)
        }
    }

    .view-section .view-footer .btn-stop {
        background-size: 1.8rem 1.8rem;
    }

    .view-section .view-footer .btn-prev {
        background-image: url(../images-m/lecture/btn-prev-m.png);
        background-size: 1.1875rem 1.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-prev {
            background-image: url(../images-m/lecture/btn-prev-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-prev {
            background-image: url(../images-m/lecture/btn-prev-m@3x.png)
        }
    }

    .view-section .view-footer .btn-next {
        background-image: url(../images-m/lecture/btn-next-m.png);
        background-size: 1.1875rem 1.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-next {
            background-image: url(../images-m/lecture/btn-next-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-next {
            background-image: url(../images-m/lecture/btn-next-m@3x.png)
        }
    }

    .view-section .view-footer .btn-retest {
        background-image: url(../images-m/lecture/btn-retest-m.png);
        background-size: 2.875rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-retest {
            background-image: url(../images-m/lecture/btn-retest-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-retest {
            background-image: url(../images-m/lecture/btn-retest-m@3x.png)
        }
    }

    .view-section .view-footer .btn-test {
        background-image: url(../images-m/lecture/btn-test-m.png);
        background-size: 1.625rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-test {
            background-image: url(../images-m/lecture/btn-test-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-test {
            background-image: url(../images-m/lecture/btn-test-m@3x.png)
        }
    }

    .view-section .view-footer .btn-recording {
        background-image: url(../images-m/lecture/btn-recording-m.png);
        background-size: 2.75rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-recording {
            background-image: url(../images-m/lecture/btn-recording-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-recording {
            background-image: url(../images-m/lecture/btn-recording-m@3x.png)
        }
    }

    .view-section .view-footer .btn-play {
        background-image: url(../images-m/lecture/btn-play-m.png);
        background-size: 1.4375rem 1.4375rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-play {
            background-image: url(../images-m/lecture/btn-play-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-play {
            background-image: url(../images-m/lecture/btn-play-m@3x.png)
        }
    }

    .view-section .view-footer .btn-re-play {
        background-image: url(../images-m/lecture/btn-re-play-m.png);
        background-size: 2.125rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-re-play {
            background-image: url(../images-m/lecture/btn-re-play-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-re-play {
            background-image: url(../images-m/lecture/btn-re-play-m@3x.png)
        }
    }

    .view-section .view-footer .btn-listen {
        background-image: url(../images-m/lecture/btn-listen-m.png);
        background-size: 2.9375rem 2.125rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-listen {
            background-image: url(../images-m/lecture/btn-listen-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-listen {
            background-image: url(../images-m/lecture/btn-listen-m@3x.png)
        }
    }

    .view-section .view-footer .btn-next-page {
        background-image: url(../images-m/lecture/btn-next-page-m.png);
        background-size: 1.4375rem 1.75rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-next-page {
            background-image: url(../images-m/lecture/btn-next-page-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-next-page {
            background-image: url(../images-m/lecture/btn-next-page-m@3x.png)
        }
    }

    .view-section .view-footer .btn-listen-all {
        background-image: url(../images-m/lecture/btn-listen-all-m.png);
        background-size: 2.5rem 2.25rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-listen-all {
            background-image: url(../images-m/lecture/btn-listen-all-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-listen-all {
            background-image: url(../images-m/lecture/btn-listen-all-m@3x.png)
        }
    }

    .view-section .view-footer .btn-listen-text {
        background-image: url(../images-m/lecture/btn-listen-text-m.png);
        background-size: 2.1875rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-listen-text {
            background-image: url(../images-m/lecture/btn-listen-text-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-listen-text {
            background-image: url(../images-m/lecture/btn-listen-text-m@3x.png)
        }
    }

    .view-section .view-footer .btn-check-correct {
        background-image: url(../images-m/lecture/btn-check-correct-m.png);
        background-size: 2.1875rem 2.1875rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .view-footer .btn-check-correct {
            background-image: url(../images-m/lecture/btn-check-correct-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .view-footer .btn-check-correct {
            background-image: url(../images-m/lecture/btn-check-correct-m@3x.png)
        }
    }
}

.view-section.menu-opened .btn-menu-open {
    display: none
}

.view-section.menu-opened .btn-menu-close {
    display: block
}

.view-section.menu-opened .view-container {
    width: calc(100% - 266px);
    height:100%;
}

@media screen and (max-width: 1199px) {
    .view-section.menu-opened .view-container {
        width: calc(100% - 12.20833rem)
    }
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section.menu-opened .view-container {
        width: calc(100% - 220px)
    }
}

.view-section.menu-opened .right-menu {
    width: 266px;
    opacity: 1;
    visibility: visible
}


@media screen and (max-width: 1199px) {
    .view-section.menu-opened .right-menu {
        width: 12.20833rem;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section.menu-opened .right-menu {
        width: 220px;
    }
}

.view-section .intro-title {
    color: #058ebc;
    font-size: 93px;
    font-family: CircularStd, sans-serif;
    font-weight: 900;
    text-align: center;
    letter-spacing: -.02em;
    line-height: .88172em;
    margin: 0 0 35px
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .intro-title {
        font-size: 72px;
        margin: 0 0 30px
    }
}

.view-section .intro-desc {
    color: #3c3c3c;
    font-size: 26px;
    font-weight: 400;
    letter-spacing: -.02em;
    line-height: 1.42308em;
    text-align: center;
    margin-bottom: 65px
}


@media screen and (max-width: 1199px) {
    .view-section .intro-title {
        color: #058ebc;
        font-size: 2.5rem;
        font-family: CircularStd, sans-serif;
        font-weight: 900;
        text-align: center;
        letter-spacing: -.02em;
        line-height: .88172em;
        margin: 0 0 .9375rem
    }

    .view-section .intro-desc {
        color: #3c3c3c;
        font-size: .75rem;
        font-weight: 400;
        letter-spacing: -.02em;
        line-height: 1.42308em;
        text-align: center;
        margin-bottom: 1.35417rem
    }
}

.view-section .word-list {
    list-style: none;
    padding: 0;
    margin: 40px auto;
    width: 100%;
    max-width: 1100px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list {
        list-style: none;
        padding: 0;
        margin: 40px auto;
        width: 90%;
        max-width: 880px;
    }

}

/*@media screen and (max-width: 1109px) {*/
    /*.view-section .word-list {*/
        /*width: 90%*/
    /*}*/
/*}*/

.view-section .word-list li {
    display: block;
    padding: 35px 0;
    border: 2px solid #dcdde1;
    border-top-width: 1px;
    border-bottom-width: 1px;
    cursor: pointer;
    position: relative
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li {
        padding: 20px 0;
    }
}

.view-section .word-list li:first-child {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px
}

.view-section .word-list li:last-child {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px
}

.view-section .word-list li .answer,
.view-section .word-list li .question,
.view-section .word-list li .txt,
.view-section .word-list li .word,
.view-section .word-list li .ism-expression,
.view-section .word-list li .ism-expression-kr,
.view-section .word-list li .ism-txt {
    text-align: center;
    line-height: 1.2em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li .answer,
    .view-section .word-list li .question,
    .view-section .word-list li .txt,
    .view-section .word-list li .word
    .view-section .word-list li .ism-expression,
    .view-section .word-list li .ism-expression-kr,
    .view-section .word-list li .ism-txt {
        line-height: 1.1em
    }

}

.view-section .word-list li .decl {
    color: #ff7200;
    position: absolute;
    top: 20%;
    left: 5%;
    font-size: 30px;
}

.view-section .word-list li .qest {
    color: #00759c;
    position: absolute;
    top: 20%;
    left: 5%;
    font-size: 30px;
}

@media screen and (max-width: 1199px){
    .view-section .word-list li .decl {
        color: #ff7200;
        position: absolute;
        top: 10%;
        left: 3%;
        font-size: 1rem;
    }

    .view-section .word-list li .qest {
        color: #00759c;
        position: absolute;
        top: 10%;
        left: 3%;
        font-size: 1rem;
    }

}


.view-section .word-list li .word {
    color: #222;
    font-size: 90px;
    font-family: CircularStd, sans-serif;
    font-weight: 500
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li .word {
        font-size: 78px;
    }

    .view-section .word-list li .m3word {
        font-size: 48px;
    }

}

.view-section .word-list li .ism-expression {
    font-size: 52px;
    line-height: 1em;
    color: #222;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 0 160px 20px 160px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li .ism-expression {
        font-size: 32px;
    }
}

.view-section .word-list li .ism-expression-kr {
    font-size: 38px;
    line-height: 1em;
    color: #222;
    font-family: CircularStd, "Noto Sans KR", sans-serif;
    font-weight: 500;
    text-align: center;
    margin: 0 160px 20px 160px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li .ism-expression-kr {
        font-size: 28px;
    }
}

@media screen and (max-width: 1199px){
    .view-section .word-list li .ism-expression {
        font-size: 2rem;
    }

    .view-section .word-list li .ism-expression-kr {
        font-size: 1.3rem;
    }
}


.view-section .word-list li .txt {
    color: #838383;
    font-size: 34px;
    font-weight: 500
}

.view-section .word-list li .txt-big {
    font-size: 60px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li .txt {
        font-size: 32px;
    }

    .view-section .word-list li .m3txt {
        font-size: 22px;
    }


    .view-section .word-list li .txt-big {
        font-size: 50px;
    }
}

.view-section .word-list li .ism-txt {
    color: #838383;
    font-size: 36px;
    margin: 0 120px;

}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list li .ism-txt {
        font-size: 32px;
    }

}

@media screen and (max-width: 1199px){
    .view-section .word-list li .ism-txt {
        font-size: 1.2rem;
    }
}


.view-section .word-list li.active {
    background: #fff;
    border-color: #00759c;
    /*cursor: default*/
}

.view-section .word-list li.active .answer,
.view-section .word-list li.active .question,
.view-section .word-list li.active .txt,
.view-section .word-list li.active .word,
.view-section .word-list li.active .ism-expression,
.view-section .word-list li.active .ism-expression-kr,
.view-section .word-list li.active .ism-txt {
    color: #00759c
}

.view-section .word-list li.active .label {
    background-color: #00759c !important
}

.view-section .word-list li:hover {
    border-color: #00759c
}

.view-section .word-list li .score {
    position: absolute;
    right: -25px;
    top: -32px;
    z-index: 11;
    opacity: 0;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px)
}

.view-section .word-list.word-list-grid {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap
}

.view-section .word-list.word-list-grid li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: calc(50% - 6px);
    height: 200px;
    margin: 0 3px 6px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 20px;
    border-radius: 18px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid li {
        width: calc(50% - 6px);
        height: 130px;
        margin: 0 3px 6px;
        padding: 0 20px;
        border-radius: 18px
    }
}

.view-section .word-list.word-list-grid li .word {
    font-size: 48px;
    line-height: 1em;
    margin-bottom: 20px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid li .word {
        font-size: 36px;
        line-height: 1em;
        margin-bottom: 14px;
    }
}

.view-section .word-list.word-list-grid li .expression {
    font-size: 38px;
    line-height: 1em;
    margin-bottom: 20px;
    color: #222;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    text-align: center;
}

@media screen and (max-width: 1199px) {
    .view-section .word-list.word-list-grid li .expression {
        font-size: 1.104rem;
        /*line-height: 1em;*/
        margin-bottom: 0;
        font-weight: 500;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid li .expression {
        font-size: 32px;
        line-height: 1em;
        margin-bottom: 10px;
    }
}

.view-section .word-list.word-list-grid li .txt {
    color: #838383;
    font-size: 24px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid li .txt {
        color: #838383;
        font-size: 22px;
    }
}

/*12단어 외우기 그리드 수정*/

.view-section .word-list.word-list-grid-12 {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    max-width: 1320px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-12 {

       max-width: 1200px;
    }
}

.view-section .word-list.word-list-grid-12 li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: calc(50% - 6px);
    height: 105px;
    margin: 0 3px 6px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5px;
    border-radius: 18px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-12 li {
        width: calc(50% - 6px);
        height: 74px;
        margin: 0 3px 6px;
        padding: 0 5px;
        border-radius: 18px
    }
}

.view-section .word-list.word-list-grid-12 li .word {
    font-size: 32px;
    line-height: 0.5em;
    margin-bottom: 10px
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-12 li .word {
        font-size: 26px;
        line-height: 0.5em;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .word-list.word-list-grid-12 li .word {
        font-size: 22px;
    }
}

.view-section .word-list.word-list-grid-12 li .txt {
    color: #838383;
    font-size: 20px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-12 li .txt {
        color: #838383;
        font-size: 17px;
        font-weight: 400;
    }
}



/*스펠링 외우기 그리드 수정 3*3*/

.view-section .word-list.word-list-grid-9 {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    max-width: 1320px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-9 {

        max-width: 1200px;
    }
}

.view-section .word-list.word-list-grid-9 li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: calc(33% - 6px);
    height: 105px;
    margin: 0 3px 6px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 5px;
    border-radius: 18px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-9 li {
        width: calc(33% - 6px);
        height: 74px;
        margin: 0 3px 6px;
        padding: 0 5px;
        border-radius: 18px
    }
}

.view-section .word-list.word-list-grid-9 li .word {
    font-size: 32px;
    line-height: 0.5em;
    margin-bottom: 10px
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-9 li .word {
        font-size: 26px;
        line-height: 0.5em;
        margin-bottom: 10px;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .word-list.word-list-grid-9 li .word {
        font-size: 22px;
    }
}

.view-section .word-list.word-list-grid-9 li .txt {
    color: #838383;
    font-size: 20px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.word-list-grid-9 li .txt {
        color: #838383;
        font-size: 17px;
        font-weight: 400;
    }
}



/*6단어 외우기 등에서 사이즈 조절 전 css*/

/*.view-section .word-list.word-list-grid li .word {*/
/*font-size: 38px;*/
/*line-height: 1em;*/
/*margin-bottom: 20px*/
/*}*/

/*.view-section .word-list.word-list-grid li .txt {*/
/*color: #838383;*/
/*font-size: 24px*/
/*}*/

.view-section .word-list.word-list-grid li .score {
    width: 91px;
    height: 99px;
    background-size: 100%;
    font-size: 34px;
    padding-bottom: 20px
}

.view-section .word-list.question-answer > li:hover {
    border-color: #00759c
}

.view-section .word-list.question-answer > li:hover .answer,
.view-section .word-list.question-answer > li:hover .question {
    color: #00759c
}

.view-section .word-list.question-answer > li:hover .label {
    background-color: #00759c
}

.view-section .word-list.question-answer .question {
    color: #222;
    font-size: 80px;
    font-family: CircularStd, sans-serif;
    font-weight: 400
}

.view-section .word-list.question-answer .answer {
    color: #222;
    font-size: 50px;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    padding: 18px 0
}

.view-section .word-list.question-answer .answer,
.view-section .word-list.question-answer .question {
    position: relative
}

.view-section .word-list.question-answer .answer .label,
.view-section .word-list.question-answer .question .label {
    color: #fff;
    font-size: 59px;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    background-color: #484848;
    text-align: center;
    line-height: 1;
    padding: 15px 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    position: absolute;
    left: 50px;
    top: 0
}

@media screen and (max-width: 1199px) {
    .view-section .word-list {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-direction: column;
        height: 100%
    }

    .view-section .word-list li {
        -webkit-flex: 1;
        flex: 1;
        display: block;
        border: 2px solid #dcdde1;
        border-top-width: 1px;
        border-bottom-width: 1px;
        cursor: pointer;
        position: relative;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding:0;
    }

    .view-section .word-list li .answer,
    .view-section .word-list li .question,
    .view-section .word-list li .txt,
    .view-section .word-list li .word {
        text-align: center;
        line-height: 1.2em
    }

    .view-section .word-list li .word {
        color: #222;
        font-size: 1.875rem;
        font-family: CircularStd, sans-serif;
        font-weight: 500
    }

    .view-section .word-list li .txt {
        color: #838383;
        font-size: .70833rem;
        font-weight: 500
    }

    .view-section .word-list li.active {
        background: #fff;
        border-color: #00759c;
        cursor: default
    }

    .view-section .word-list li.active .answer,
    .view-section .word-list li.active .question,
    .view-section .word-list li.active .txt,
    .view-section .word-list li.active .word {
        color: #00759c
    }

    .view-section .word-list li.active .label {
        background-color: #00759c!important
    }

    .view-section .word-list li:hover {
        border-color: #00759c
    }

    .view-section .word-list li .score {
        position: absolute;
        right: 1.02083rem;
        top: 50%;
        z-index: 11;
        opacity: 0;
        -webkit-transform: translateY(-50%)!important;
        -moz-transform: translateY(-50%)!important;
        -ms-transform: translateY(-50%)!important;
        -o-transform: translateY(-50%)!important;
        transform: translateY(-50%)!important
    }

    .view-section .word-list.word-list-grid {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row
    }

    .view-section .word-list.word-list-grid li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        width: 50%;
        height: 33.3333%;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        flex: none;
        border-radius:0;
    }

    .view-section .word-list.word-list-grid li:nth-child(2n+1) {
        border-right-width: 1px;
    }
    .view-section .word-list.word-list-grid li:nth-child(2n) {
        border-left-width: 1px;
    }

    .view-section .word-list.word-list-grid li .word {
        font-size: 1.10417rem;
        line-height: 1em;
        margin-bottom: .41667rem
    }

    .view-section .word-list.word-list-grid li .txt {
        color: #838383;
        font-size: .75rem
    }

    .view-section .word-list.word-list-grid li .score {
        width: 2.1875rem;
        height: 2.4375rem;
        background-size: 100%;
        font-size: 1.04167rem;
        padding-bottom: .3125rem
    }

    /**********************/
    .view-section .word-list.word-list-grid-12 {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        max-width:initial;
        /*justify-content: flex-start;*/
        align-content:flex-start;
    }

    .view-section .word-list.word-list-grid-12 li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        width: 50%;
        height: 16.6666%;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0;
        flex: none;
        border-radius:0;
    }

    /*13단어 이상은 잘리도록 유도함*/
    .view-section .word-list.word-list-grid-12.word-list-grid-13 li{
        height: 18%;
    }

    .view-section .word-list.word-list-grid-12 li:nth-child(2n+1) {
        border-right-width: 1px;
    }
    .view-section .word-list.word-list-grid-12 li:nth-child(2n) {
        border-left-width: 1px;
    }

    .view-section .word-list.word-list-grid-12 li .word {
        font-size: 1.10417rem;
        line-height: 1em;
        margin-bottom: 0.1rem
    }

    .view-section .word-list.word-list-grid-12 li .txt {
        color: #838383;
        font-size: .75rem
    }

    .view-section .word-list.word-list-grid-12 li .score {
        width: 2.1875rem;
        height: 2.4375rem;
        background-size: 100%;
        font-size: 1.04167rem;
        padding-bottom: .3125rem
    }





    .view-section .word-list.question-answer>li {
        background-color: #f7f8fc
    }

    .view-section .word-list.question-answer>li.active {
        background-color: #fff
    }

    .view-section .word-list.question-answer>li:hover {
        border-color: #00759c
    }

    .view-section .word-list.question-answer>li:hover .answer,
    .view-section .word-list.question-answer>li:hover .question {
        color: #00759c
    }

    .view-section .word-list.question-answer>li:hover .label {
        background-color: #00759c
    }

    .view-section .word-list.question-answer .question {
        color: #222;
        font-size: 1.875rem;
        font-family: CircularStd, sans-serif;
        font-weight: 400
    }

    .view-section .word-list.question-answer .answer {
        color: #222;
        font-size: 1.58333rem;
        font-family: CircularStd, sans-serif;
        font-weight: 500;
        padding: 18px 0
    }

    .view-section .word-list.question-answer .answer,
    .view-section .word-list.question-answer .question {
        position: relative;
        padding-left: 7.60417rem;
        text-align: left
    }

    .view-section .word-list.question-answer .answer .label,
    .view-section .word-list.question-answer .question .label {
        color: #fff;
        font-size: 1.22917rem;
        font-family: CircularStd, sans-serif;
        font-weight: 500;
        background-color: #484848;
        text-align: left;
        line-height: 1;
        padding: .3125rem .4375rem;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        display: block;
        position: absolute;
        left: 50px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .view-section .word-list.voice-box {
        height: auto
    }

    .view-section .word-list.voice-box>li {
        border: none;
        text-align: center
    }

    .view-section .word-list.voice-box>li .word {
        font-size: 2.91667rem
    }

    .view-section .word-list.voice-box>li .txt {
        color: #00759c;
        font-size: 1.125rem
    }

    .view-section .word-list.voice-box>li .btn-wrap {
        display: block;
        position: relative;
        margin: 1.875rem 0
    }

    .view-section .word-list.voice-box>li .btn-wrap:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        border-top: 2px dashed #dcdde1
    }

}

.view-section .word-list.voice-box > li {
    border: none;
    text-align: center
}

.view-section .word-list.voice-box > li .txt {
    color: #00759c
}

.view-section .word-list.voice-box > li .btn-wrap {
    width: 500px;
    display: block;
    position: relative;
    border-top: 2px dashed #dcdde1;
    margin: 80px auto;
    padding: 30px 0
}

@media screen and (max-width: 1199px) {
    .view-section .word-list.voice-box > li .btn-wrap {
       width:initial;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .word-list.voice-box > li .btn-wrap {
        margin: 60px auto 40px auto;
    }
}

.view-section .word-list.voice-box > li .btn-wrap .btn-play-sound {
    position: absolute;
    left: 50%;
    margin-left: -30px;
    top: 0;
    margin-top: -30px
}

@media screen and (max-width: 1199px) {

    .view-section .word-list.voice-box {
        height: auto
    }

    .view-section .word-list.voice-box>li {
        border: none;
        text-align: center
    }

    .view-section .word-list.voice-box>li .word {
        font-size: 2.91667rem
    }

    .view-section .word-list.voice-box>li .txt {
        color: #00759c;
        font-size: 1.125rem
    }

    .view-section .word-list.voice-box>li .btn-wrap {
        display: block;
        position: relative;
        margin: 1.875rem 0;
        padding: 0;
        border-top:initial;

    }

    .view-section .word-list.voice-box>li .btn-wrap:before {
        content: '';
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        height: 1px;
        width: 100%;
        border-top: 2px dashed #dcdde1
    }

    .view-section .word-list.voice-box>li .btn-wrap .btn-play-sound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        background-color: #f7f8fc;
        margin-left: initial;
        margin-top: initial;
    }


}

.view-section .voice-result-box {
    padding: 65px 55px 58px;
    min-width: 400px;
    min-height: 170px;
    text-align: center;
    -webkit-transform: translateY(100px);
    -moz-transform: translateY(100px);
    -ms-transform: translateY(100px);
    -o-transform: translateY(100px);
    transform: translateY(100px);
    opacity: 0;
    background-color: #fff;
    border: 2px solid #ff7200;
    border-radius: 20px;
    box-shadow: 0 14px 16px 0 rgba(0, 0, 0, .08);
    position: relative
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .voice-result-box {
        padding: 60px 50px 53px;
        min-width: 360px;
        min-height: 140px;
    }

}

.view-section .voice-result-box:before {
    content: '';
    display: block;
    position: absolute;
    top: -22px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    width: 195px;
    height: 43px;
    background-color: transparent;
    background-image: url(../images/lecture/voice-result-box-title.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .voice-result-box > p {
    font-size: 32px;
    font-weight: 500;
    color: #a8a8a8
}

.view-section .voice-result-box > p .orange {
    color: #ff7200;
    font-size: 76px;
    font-weight: 500
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .voice-result-box > p .orange {
        color: #ff7200;
        font-size: 64px;
        font-weight: 500
    }
}

.view-section .voice-result-box > p .gray {
    color: #595959;
    font-size: 32px;
    font-weight: 400
}

@media screen and (max-width: 1199px) {

    .view-section .voice-result-box {
        padding: 1.5625rem 2rem 1rem;
        text-align: center;
        opacity: 0;
        -webkit-transform: none!important;
        -moz-transform: none!important;
        -ms-transform: none!important;
        -o-transform: none!important;
        transform: none!important;
        background-color: #fff;
        border: 2px solid #ff7200;
        border-radius: 20px;
        box-shadow: 0 14px 16px 0 rgba(0, 0, 0, .08);
        position: relative;
        min-height:5rem;
        min-width:10rem;
    }

    .view-section .voice-result-box:before {
        content: '';
        display: block;
        position: absolute;
        top: -.6875rem;
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
        width: 6.6875rem;
        height: 1.25rem;
        background-image: url(../images-m/lecture/voice-result-box-title-m.png);
        background-size: 6.6875rem 1.25rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .voice-result-box:before {
            background-image: url(../images-m/lecture/voice-result-box-title-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .voice-result-box:before {
            background-image: url(../images-m/lecture/voice-result-box-title-m@3x.png)
        }
    }

    .view-section .voice-result-box>p {
        font-size: .8125rem;
        font-weight: 500;
        color: #a8a8a8
    }

    .view-section .voice-result-box>p .orange {
        color: #ff7200;
        font-size: 1.58333rem;
        font-weight: 500
    }

    .view-section .voice-result-box>p .gray {
        color: #595959;
        font-size: .8125rem;
        font-weight: 400
    }
}

.view-section .result-list {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 1200px
}

@media screen and (max-width: 1199px) {
    .view-section .result-list {
        display:flex;
        flex-direction:column;
        height:100%;
        width:100%;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .result-list {
        width: 90%;
        max-width: 1100px
    }
}


.view-section .result-list li {
    display: block;
    margin: 0 0 9px;
    border-radius: 12px;
    border: 2px solid #00759c;
    background-color: #fff;
    padding: 25px 8px 8px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .result-list li {
        padding: 22px 8px 8px;


    }
}

@media screen and (max-width: 1199px) {
    .view-section .result-list li {
        display:flex;
        flex:1;
        position:relative;
        padding: 10px 8px;
        margin:0;
        border-bottom-width: 1px;
        border-top-width: 1px;
        justify-content:center;
        flex-direction:column;
    }

}


.view-section .result-list li .paragraph {
    color: #00759c;
    font-family: CircularStd, sans-serif;
    font-size: 38px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 5px
}

@media screen and (max-width: 1199px) {
    .view-section .result-list li .paragraph {
        font-size: 1.875rem;
        line-height: 1.3em;
        margin-bottom:0;
    }
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .result-list li .paragraph {
        font-size: 36px;
        line-height: 1em;
    }
}

.view-section .result-list li .paragraph-txt {
    color: #00759c;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px
}

@media screen and (max-width: 1199px) {
    .view-section .result-list li .paragraph-txt {
        font-weight: 500;
        font-size: 0.9rem;
        margin-bottom: 2px;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .result-list li .paragraph-txt {
        font-size: 18px;
        margin-bottom: 10px
    }
}

.view-section .result-list li .my-txt {
    border-radius: 25px;
    border: 2px solid #51cef8;
    text-align: center;
    font-size: 28px;
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    padding: 15px 0;
    color: #0598c9
}

@media screen and (max-width: 1199px) {
    .view-section .result-list li .my-txt {
        position:absolute;
        top: 25%;
        right: 0;
        border:0px;
        /*border-radius: 0px;*/
        /*border: 2px solid #51cef8;*/
        text-align: center;
        /*font-size: 28px;*/
        font-family: CircularStd, sans-serif;
        /*font-weight: 500;*/
        padding:0;
        /*color: #0598c9;*/
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 3rem;
        color: #fff;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: -.02em;
        background-image: url(../images-m/lecture/score-blue-m.png);
        /*background-size: 2.5625rem 2.8125rem;*/
        background-size: contain;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .result-list li .my-txt {
        font-size: 22px;
        padding: 12px 0;
    }
}

.view-section .result-list li .my-txt.my-txt-wrong {
    border-color: #ff8c96
}

.view-section .result-list li .my-txt.my-txt-wrong strong {
    color: #f63d4d;
    font-weight: 500
}

.view-section .result-list.result-list-grid {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap
}

@media screen and (max-width: 1199px) {
    .view-section .result-list.result-list-grid {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
    }

}


.view-section .result-list.result-list-grid li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: calc(50% - 6px);
    height: 220px;
    margin: 0 3px 6px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 18px
}
@media screen and (max-width: 1199px) {
    .view-section .result-list.result-list-grid li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        width: 50%;
        height: 33.3333%;
        margin: 0;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border-radius: 0;
        flex:none;
    }
    .view-section .result-list.result-list-grid li .paragraph {
        font-size: 1.104rem;
    }


    .view-section .result-list.result-list-grid li:nth-child(2n+1) {
        /*margin-right:-1px;*/
        border-right-width: 1px;
    }
    .view-section .result-list.result-list-grid li:nth-child(2n) {
        /*margin-right:-1px;*/
        border-left-width: 1px;
    }



}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .result-list.result-list-grid li {
        width: calc(50% - 6px);
        height: 160px;
        margin: 0 3px 6px;
        border-radius: 18px
    }
}

.view-section .result-list.result-list-grid li .my-txt {
    width: 100%
}

@media screen and (max-width: 1199px) {
    .view-section .result-list.result-list-grid li .my-txt {
        position:absolute;
        top: 25%;
        right: 0;
        border:0px;
        /*border-radius: 0px;*/
        /*border: 2px solid #51cef8;*/
        text-align: center;
        /*font-size: 28px;*/
        font-family: CircularStd, sans-serif;
        /*font-weight: 500;*/
        padding:0 0 0 0;
        /*color: #0598c9;*/
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 4rem;
        height: 3rem;
        color: #fff;
        font-size: 1.25rem;
        font-weight: 700;
        letter-spacing: -.02em;
        background-image: url(../images-m/lecture/score-blue-m.png);
        /*background-size: 2.5625rem 2.8125rem;*/
        background-size: contain;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }
}

.view-section .single-memorize h2 {
    color: #222;
    font-size: 80px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 70px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .single-memorize h2 {
        font-size: 58px;
    }
}


.view-section .single-memorize h4 {
    color: #222;
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 70px;
    max-width: 1000px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .single-memorize h4 {
        font-size: 40px;
    }
}

.view-section .single-memorize p {
    color: #0da3d5;
    font-size: 50px;
    font-weight: 500;
    font-family: CircularStd, sans-serif;
    text-align: center
}

.view-section .single-memorize p strong {
    font-weight: 500;
    color: #f63d4d
}

.view-section .single-memorize p.black {
    color: #222
}


@media screen and (max-width: 1199px) {
    .view-section .single-memorize h2 {
        font-size: 1.667rem;
        margin-bottom:0;
    }

    .view-section .single-memorize h2+p {
        margin-top: .9375rem
    }

    .view-section .single-memorize h4 {
        color: #222;
        font-size: 1.5rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
    }

    .view-section .single-memorize p {
        color: #0da3d5;
        font-size: 1.25rem;
        font-weight: 500;
        font-family: CircularStd, sans-serif;
        text-align: center
    }

    .view-section .single-memorize p strong {
        font-weight: 500;
        color: #f63d4d
    }

    .view-section .single-memorize p.black {
        color: #222
    }

    .view-section .single-memorize+.answer-result-box {
        margin-top: 2.5rem
    }

}


.view-section .answer-result-box {
    width: 100%;
    max-width: 1100px;
    background: #fff;
    border: 2px solid #00759c;
    border-radius: 20px;
    min-height: 240px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.view-section .answer-result-box.tke1 {
    min-height: 180px;
}
.view-section .answer-result-box.tp {
    min-height: 180px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .answer-result-box {
        max-width: 600px;
        min-height: 180px;
        padding: 20px;
    }
}

.view-section .answer-result-box .answer-result {
    color: #0598c9;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.02em;
    line-height: 1.5;
}

.view-section .answer-result-box .answer-result strong {
    color: #f63d4d;
    font-weight: 500
}

.view-section .answer-result-box .answer-score {
    color: #0da3d5;
    font-size: 80px;
    font-weight: 700;
    font-family: CircularStd, sans-serif;
    text-align: center;
    letter-spacing: -.02em
}

@media screen and (max-width: 1199px){
    .view-section .answer-result-box .answer-score {
        /*display: none;*/
        color: #0da3d5;
        font-size: 2.08333rem;
        line-height: 1.5em;
        font-weight: 700;
        font-family: CircularStd, sans-serif;
        text-align: center;
        letter-spacing: -.02em;
    }

    .view-section .answer-result-box {
        width: 90%;
        margin: 0 auto;
        background: #fff;
        border: 1px solid #00759c;
        border-radius: 20px;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        min-height: 5rem;
        padding: 10px;
        max-width: initial;
    }

    .view-section .answer-result-box .answer-result {
        color: #0598c9;
        font-size: 1.16667rem;
        padding: 1.25rem 0;
        font-weight: 500;
        text-align: center;
        letter-spacing: -.02em
    }

    .view-section .answer-result-box .answer-result strong {
        color: #f63d4d;
        font-weight: 500
    }



}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .answer-result-box .answer-score {
        font-size: 68px;
    }
}

.view-section .btn-lecture-start {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-lecture-start.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    display: block;
    padding: 0;
    border: 0 none;
    text-indent: -9999px;
    width: 314px;
    height: 104px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    margin: 0 auto
}

.view-section .btn-lecture-start.disabled,
.view-section .btn-lecture-start:disabled {
    opacity: .65
}

.view-section .btn-lecture-start:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .btn-lecture-start:hover {
    opacity: .8
}

@media screen and (max-width: 1199px) {

    .view-section .btn-lecture-start {
        background-image: url(../images-m/lecture/btn-lecture-start-m.png);
        background-size: 9.125rem 2.9375rem;
        width: 9.125rem;
        height: 2.9375rem;
    }



    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .btn-lecture-start {
            background-image: url(../images-m/lecture/btn-lecture-start-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .btn-lecture-start {
            background-image: url(../images-m/lecture/btn-lecture-start-m@3x.png)
        }
    }


}

.view-section .btn-lecture-end {
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url(../images/lecture/btn-lecture-end.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    display: block;
    padding: 0;
    border: 0 none;
    text-indent: -9999px;
    width: 314px;
    height: 105px;
    -webkit-transition: all .3s ease;
    -moz-transition: all .3s ease;
    -ms-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    margin: 0 auto
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .btn-lecture-end {
        width: 261px;
        height: 88px;
        background-size: contain;
    }
}

.view-section .btn-lecture-end.disabled,
.view-section .btn-lecture-end:disabled {
    opacity: .65
}

.view-section .btn-lecture-end:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .btn-lecture-end:hover {
    opacity: .8
}


@media screen and (max-width: 1199px) {

    .view-section .btn-lecture-end {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        background-image: url(../images-m/lecture/btn-lecture-end-m.png);
        background-size: 9.125rem 2.9375rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: transparent;
        display: block;
        padding: 0;
        border: 0 none;
        text-indent: -9999px;
        width: 9.125rem;
        height: 2.9375rem;
        -webkit-transition: all .3s ease;
        -moz-transition: all .3s ease;
        -ms-transition: all .3s ease;
        -o-transition: all .3s ease;
        transition: all .3s ease;
        margin: 0 auto
    }

    .view-section .btn-lecture-end.disabled,
    .view-section .btn-lecture-end:disabled {
        opacity: .65
    }

    .view-section .btn-lecture-end:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .btn-lecture-end {
            background-image: url(../images-m/lecture/btn-lecture-end-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .btn-lecture-end {
            background-image: url(../images-m/lecture/btn-lecture-end-m@3x.png)
        }
    }

    .view-section .btn-lecture-end:hover {
        opacity: .8
    }

}

.view-section .sound-box {
    width: 100%;
    max-width: 1180px;
    padding: 0 40px;
}

.view-section .sound-box .sound-deco-img {
    display: block;
    margin: 0 auto 20px auto;
    height: 400px;
    object-fit: none;
    object-position: 50% 20%;

}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .sound-box .sound-deco-img {
        width: 520px;
        height: auto;
        object-fit: cover;
        margin: 0 auto;
    }
}

@media screen and (max-width: 1199px){
    .view-section .sound-box {
        width: 90%;
        /*max-width: 60%;*/
        padding: 0;
        height:100%;
    }

    .view-section .sound-box .sound-deco-img {
        display: block;
        margin: 0 auto 20px auto;
        max-width: 55%;
        height:auto;
        object-fit:contain;
        object-position:initial;
    }

}

.view-section .sound-box .sound-box-text {
    color: #222;
    font-size: 30px;
    padding: 25px 60px;
    border: 1px solid #dcdde1;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    max-width: 1100px;
    min-height: 107px;
    text-align: center;
    line-height: 1.35;
    position: relative;
    background-color: #f7f8fc
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text {
        font-size: 24px;
        padding: 25px 35px 20px 35px;
        max-width: 860px;
        margin: 0 auto;
        transform: translateY(-45px);
    }
}

@media screen and (max-width: 1199px){

}


.view-section .sound-box .sound-box-text2 {
    color: #222;
    font-size: 30px;
    padding: 40px 60px 25px 60px;
    border: 1px solid #dcdde1;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    border-radius: 12px;
    max-width: 1100px;
    min-height: 107px;
    text-align: center;
    line-height: 1.35;
    position: relative;
    background-color: #f7f8fc
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text2 {
        font-size: 24px;
        padding: 25px 35px 20px 35px;
        max-width: 860px;
        margin: 20px auto 0 auto;
    }
}

@media screen and (max-width: 1199px){
    .view-section .sound-box .sound-box-text2 {
        font-size: 1rem;
        padding: 1rem 2rem;
        margin:0 auto;
        border: 1px solid #dcdde1;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        max-width: 90%;
        min-height: 4rem;
        line-height: 1.35;
        transform: translateY(-0.8rem);
    }

    .view-section .sound-box .sound-box-text.sp1 {
        font-size: 1rem;
        padding: 1rem 2rem;
        margin:0 auto;
        border: 1px solid #dcdde1;
        -webkit-border-radius: 12px;
        -moz-border-radius: 12px;
        border-radius: 12px;
        max-width: 90%;
        min-height: 4rem;
        line-height: 1.35;
        transform: translateY(-0.8rem);
    }

}

.view-section .sound-box .sound-box-text > strong {
    font-size: 24px;
    color: #00759c;
    letter-spacing: -1px;
    display: block;
    margin-top: 25px;
    font-weight: 400
}

.view-section .sound-box .sound-box-text .sound-box-divider {
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    position: relative
}

.view-section .sound-box .sound-box-text .sound-box-divider:after {
    content: '';
    width: 100%;
    border-top: 2px dashed #dcdde1;
    position: absolute;
    left: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.view-section .sound-box .sound-box-text .sound-title {
    color: #00759c;
    font-size: 44px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.02em;
    line-height: 1.2em;
    margin-bottom: 20px
}

.view-section .sound-box .sound-box-text2 .sound-title {
    color: #00759c;
    font-size: 44px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.02em;
    line-height: 1.2em;
    margin-bottom: 20px
}

@media screen and (max-width: 1199px){
    .view-section .sound-box .sound-box-text2 .sound-title {
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 1.5rem;
        margin-bottom: 0.5rem;
    }
}

.view-section .sound-box .sound-box-text .description {
    color: #626262;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.42308em;
    letter-spacing: -.02em
}

.view-section .sound-box .sound-box-text2 .description {
    color: #626262;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.42308em;
    letter-spacing: -.02em
}
@media screen and (max-width: 1199px){
    .view-section .sound-box .sound-box-text2 .description {
        color: #626262;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1rem;
        letter-spacing: -.02em
    }
}

.view-section .sound-box .sound-box-text .btn-play-sound {
    position: relative;
    margin: 15px auto;
    z-index: 2
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text .btn-play-sound {
        width: 40px;
        height: 40px;
        background-size: contain;
    }
}

.view-section .sound-box .sound-box-text .abc-score {
    font-size: 40px;
    font-weight: 500;
    color: #00759c;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text .abc-score {
        font-size: 30px;
        font-weight: 500;
        color: #00759c;
    }
}

@media screen and (max-width: 1199px){
    .view-section .sound-box .sound-box-text .abc-score {
        font-size: 2rem;
        font-weight: 500;
        line-height: 1.5rem;
        color: #00759c;
    }


}

@media screen and (max-width: 1199px){

    .view-section .sound-box .sound-box-text {
        color: #222;
        font-size: .875rem;
        padding: .625rem;
        max-width: 70%;
        margin: 0 auto;
        text-align: center;
        line-height: 1.35;
        position: relative;
        transform:translateY(-4rem);
        border:none;
    }

    .view-section .sound-box .sound-box-text>strong {
        font-size: .8125rem;
        color: #00759c;
        letter-spacing: -1px;
        display: block;
        margin-top: .5rem;
        font-weight: 400
    }

    .view-section .sound-box .sound-box-text .sound-box-divider {
        max-width: 500px;
        width: 100%;
        margin: 0 auto;
        position: relative
    }

    .view-section .sound-box .sound-box-text .sound-box-divider:after {
        content: '';
        width: 100%;
        border:none;
        /*border-top: 2px dashed #dcdde1;*/
        /*position: absolute;*/
        /*left: 0;*/
        /*top: 50%;*/
        /*-webkit-transform: translateY(-50%);*/
        /*-moz-transform: translateY(-50%);*/
        /*-ms-transform: translateY(-50%);*/
        /*-o-transform: translateY(-50%);*/
        /*transform: translateY(-50%)*/
    }

    .view-section .sound-box .sound-box-text .sound-title {
        color: #00759c;
        font-size: 1.3125rem;
        font-weight: 500;
        text-align: center;
        letter-spacing: -.02em;
        line-height: 1.2em;
        margin-bottom: .3125rem
    }

    .view-section .sound-box .sound-box-text .description {
        color: #626262;
        font-weight: 500;
        font-size: .8125rem;
        line-height: 1.42308em;
        letter-spacing: -.02em
    }

    .view-section .sound-box .sound-box-text .btn-play-sound {
        position: relative;
        margin: 15px auto 10px auto;
        z-index: 2;
        background-color: #fff
    }

}


@media screen and (max-width: 1199px){
    .view-section .figure-1ms  img{
        height: 70vh;
        width: auto;
    }
}



.view-section .complete .complete-figure {
    margin: 0 auto 30px
}

.view-section .complete .complete-figure img {
    display: block;
    margin: 0 auto
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .complete .complete-figure img {
        width: 200px;
        height: auto;

    }
}

.view-section .complete .complete-title {
    font-size: 72px;
    font-weight: 700;
    text-align: center;
    color: #058ebc;
    letter-spacing: -.02em;
    line-height: 1.13889em;
    margin-bottom: 24px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .complete .complete-title {
        font-size: 52px;
    }
}

.view-section .complete .complete-subtitle {
    color: #3c3c3c;
    font-size: 26px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -.02em;
    line-height: 1.42308em;
    margin-bottom: 10px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .complete .complete-subtitle {
        font-size: 22px;
    }
}

.view-section .complete .complete-desc {
    color: #858585;
    font-size: 20px;
    text-align: center;
    font-weight: 400;
    letter-spacing: -.02em;
    line-height: 1.85em;
    margin-bottom: 45px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .complete .complete-desc {
        font-size: 18px;
        margin-bottom: 30px;
    }
}

@media screen and (max-width: 1199px){
    .view-section .complete .complete-figure {
        margin: 0 auto .3125rem
    }

    .view-section .complete .complete-figure img {
        display: block;
        margin: 0 auto;

    }

    .view-section .complete .complete-title {
        font-size: 2.08333rem;
        font-weight: 700;
        text-align: center;
        color: #058ebc;
        letter-spacing: -.02em;
        line-height: 1.13889em;
        margin-bottom: .3125rem
    }

    .view-section .complete .complete-subtitle {
        color: #3c3c3c;
        font-size: .875rem;
        text-align: center;
        font-weight: 400;
        letter-spacing: -.02em;
        line-height: 1.42308em;
        margin-bottom: .3125rem
    }

    .view-section .complete .complete-desc {
        color: #858585;
        font-size: .75rem;
        text-align: center;
        font-weight: 400;
        letter-spacing: -.02em;
        line-height: 1.85em;
        margin-bottom: .9375rem
    }
}


.view-section .btn-record-wrap {
    width: 100%;
    text-align: center
}

.view-section .btn-record-wrap > li {
    display: inline-block;
    margin: 0 10px
}

.view-section .direct-translation {
    color: #222;
    font-size: 50px;
    font-weight: 500;
    letter-spacing: -.02em;
    line-height: 1.48em;
    text-align: center;
    max-width: 1200px;
}

@media screen and (max-width: 1199px){
    .view-section .direct-translation {
        color: #222;
        font-size: 1.41667rem;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 1.48em;
        text-align: center;
        max-width: 80%;
    }
}


@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .direct-translation {
        max-width: 1100px;
    }
}

.view-section .direct-translation.type1 {
    font-family: CircularStd, sans-serif
}

.view-section .direct-translation.type2 {
    color: #00759c
}

.view-section .recording-list {
    list-style: none;
    padding: 0;
    margin: 40px auto;
    width: 100%;
    max-width: 1100px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .recording-list {
        max-width: 880px
    }
}

.view-section .recording-list li {
    display: block;
    border: 1px solid #dcdde1;
    position: relative;
    z-index: 1;
    padding: 25px 40px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .recording-list li {
        padding: 18px 30px
    }
}

.view-section .recording-list li:first-child {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px
}

.view-section .recording-list li:last-child {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px
}

.view-section .recording-list li + li {
    margin-top: -1px
}

.view-section .recording-list li p {
    color: #424141;
    font-weight: 500;
    font-family: CircularStd, sans-serif;
    font-size: 30px;
    letter-spacing: -.02em;
    line-height: 1.23333em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .recording-list li p {
        font-weight: 400;
        font-size: 24px;
    }
}

.view-section .recording-list li:hover {
    z-index: 3;
    border-color: #00759c
}

.view-section .recording-list li.active {
    z-index: 5;
    color: #fff;
    border-color: #00759c;
    background-color: #00759c
}

.view-section .recording-list li.active p {
    color: #fff
}

@media screen and (max-width: 1199px){

    .view-section .recording-list {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        display:flex;
        flex-direction: column;
        flex-wrap:wrap;
        justify-content: flex-start;
    }

    .view-section .recording-list li {
        display: flex;
        border: 1px solid #dcdde1;
        position: relative;
        z-index: 1;
        padding: 0px 40px;
        flex:1;
        flex-direction: column;
        justify-content: center;
    }

    .view-section .recording-list li+li {
        margin-top: -1px
    }

    .view-section .recording-list li p {
        color: #424141;
        font-weight: 500;
        font-family: CircularStd, sans-serif;
        font-size: .9375rem;
        letter-spacing: -.02em;
        line-height: 1.23333em
    }

    .view-section .recording-list li:hover {
        z-index: 3;
        border-color: #00759c
    }

    .view-section .recording-list li.active {
        z-index: 5;
        color: #fff;
        border-color: #00759c;
        background-color: #00759c
    }

    .view-section .recording-list li.active p {
        color: #fff
    }

    .view-section .recording-list li:first-child {
        border-top-left-radius: initial;
        border-top-right-radius: initial;
    }
    .view-section .recording-list li:last-child {
        border-bottom-left-radius: initial;
        border-bottom-right-radius: initial;
    }

}

.view-section .listening-list {
    list-style: none;
    padding: 20px 0;
    margin: 0 auto;
    width: 100%;
    max-width: 1100px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .listening-list {
        max-width: 880px
    }

}

.view-section .listening-list li {
    display: block;
    border: 1px solid #dcdde1;
    position: relative;
    z-index: 1;
    padding: 16px 85px 16px 40px
}

.view-section .listening-list li:first-child {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px
}

.view-section .listening-list li:last-child {
    border-bottom-left-radius: 18px;
    border-bottom-right-radius: 18px
}

.view-section .listening-list li + li {
    margin-top: -1px
}

.view-section .listening-list li .btn-play-sound {
    position: absolute;
    top: 50%;
    right: 22px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .listening-list li .btn-play-sound {
        width: 40px;
        height: 40px;
        background-size: contain;
    }
}

.view-section .listening-list li h2 {
    font-family: CircularStd, sans-serif;
    font-weight: 500;
    font-size: 26px;
    line-height: 1.42308em;
    letter-spacing: -.02em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .listening-list li h2 {
        line-height: 1.3em;
    }
}

.view-section .listening-list li h2 + p {
    margin-top: 10px
}

.view-section .listening-list li p {
    color: #6b6a6a;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: -.02em;
    line-height: 1.85em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .listening-list li p {
        line-height: 1.5em
    }
}

.view-section .listening-list li:hover {
    z-index: 3;
    border-color: #00759c
}

.view-section .listening-list li.active {
    z-index: 5;
    color: #00759c;
    border-color: #00759c;
    background-color: #fff
}

.view-section .listening-list.listening-list-small li h2 {
    font-size: 30px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .listening-list.listening-list-small li h2 {
        font-size: 24px
    }
}

@media screen and (max-width: 1199px){
    .view-section .listening-list {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        width: 100%;
        max-width: 1100px
    }

    .view-section .listening-list li {
        display: block;
        border: 1px solid #dcdde1;
        position: relative;
        z-index: 1;
        padding: .5rem 85px .5rem 40px;
        background-color: #f7f8fc
    }


    .view-section .listening-list li:first-child {
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }

    .view-section .listening-list li:last-child {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }

    .view-section .listening-list li+li {
        margin-top: -1px
    }

    .view-section .listening-list li .btn-play-sound {
        position: absolute;
        background-color: transparent;
        top: 50%;
        right: 22px;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    .view-section .listening-list li h2 {
        font-family: CircularStd, sans-serif;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.42308em;
        letter-spacing: -.02em
    }

    .view-section .listening-list li h2+p {
        margin-top: .3125rem
    }

    .view-section .listening-list li p {
        color: #6b6a6a;
        font-weight: 500;
        font-size: .75rem;
        letter-spacing: -.02em;
        line-height: 1.85em
    }

    .view-section .listening-list li:hover {
        z-index: 3;
        border-color: #00759c
    }

    .view-section .listening-list li.active {
        z-index: 5;
        color: #00759c;
        border-color: #00759c;
        background-color: #fff
    }

    .view-section .listening-list.listening-list-small li h2 {
        font-size: .9375rem
    }
}


.view-section .talk-list {
    margin: 0 auto;
    padding: 0 0 40px 0;
    list-style: none;
    width: 100%;
    max-width: 1100px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .talk-list {
        padding: 0 0 40px 0;
        width: 100%;
        max-width: 880px
    }
}

.view-section .talk-list .datetime {
    margin: 50px 0;
    width: 100%;
    text-align: center;
    position: relative
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .talk-list .datetime {
        margin: 40px 0;
        text-align: center;
        position: relative
    }
}

.view-section .talk-list .datetime:after {
    position: absolute;
    top: 50%;
    left: 0;
    content: '';
    height: 2px;
    width: 100%;
    background-color: #e5e5e5
}

.view-section .talk-list .datetime span {
    padding: 0 18px;
    color: #6e6d69;
    display: inline-block;
    margin: 0 auto;
    line-height: 1em;
    background-color: #f7f8fc;
    position: relative;
    z-index: 2
}

@media screen and (max-width: 1199px){
    .view-section .talk-list {
        margin: 0 auto;
        padding: 0 1.375rem 1.25rem;
        list-style: none;
        width: 100%;
        background-color: #f1f2f7;

    }

    .view-section .talk-list .datetime {
        margin: 1.04167rem 0;
        width: 100%;
        text-align: center;
        position: relative
    }

    .view-section .talk-list .datetime:after {
        position: absolute;
        top: 50%;
        left: 0;
        content: '';
        height: 2px;
        width: 100%;
        background-color: #e5e5e5
    }

    .view-section .talk-list .datetime span {
        padding: 0 18px;
        color: #6e6d69;
        display: inline-block;
        margin: 0 auto;
        line-height: 1em;
        background-color: #f1f2f7;
        position: relative;
        z-index: 2;
        font-size: 1rem;
    }
}


/*.view-section .talk-list .block {*/
/*-webkit-display: flex;*/
/*display: -ms-flex;*/
/*display: flex;*/
/*flex-direction: row;*/
/*align-items: flex-start*/
/*}*/

/*.view-section .talk-list .block+.block {*/
/*!*margin-top: 30px*!*/
/*margin: 30px 15px 0 15px;*/
/*}*/

/*.view-section .talk-list .block .profile {*/
/*display: block*/
/*}*/

/*.view-section .talk-list .block .chat-bubble {*/
/*position: relative;*/
/*margin-left: 20px;*/
/*background-color: #fff;*/
/*box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);*/
/*padding: 15px 25px;*/
/*border-radius: 10px*/
/*}*/

/*.view-section .talk-list .block .chat-bubble:after {*/
/*display: block;*/
/*clear: both;*/
/*content: ""*/
/*}*/

/*.view-section .talk-list .block .chat-bubble:after {*/
/*content: '';*/
/*display: block;*/
/*position: absolute;*/
/*width: 13px;*/
/*height: 13px;*/
/*top: 29px;*/
/*left: -13px;*/
/*background-color: transparent;*/
/*background-image: url(../images/lecture/bubble-arrow-left.png);*/
/*background-repeat: no-repeat;*/
/*background-position-x: center;*/
/*background-position-y: center*/
/*}*/

/*.view-section .talk-list .block .chat-bubble h2 {*/
/*color: #393939;*/
/*font-weight: 500;*/
/*font-size: 24px;*/
/*letter-spacing: -.02em;*/
/*line-height: 1.27778em*/
/*}*/

/*.view-section .talk-list .block .chat-bubble h2+p {*/
/*margin-top: 10px*/
/*}*/

/*.view-section .talk-list .block .chat-bubble p {*/
/*color: #6b6b6b;*/
/*font-size: 18px;*/
/*font-weight: 500;*/
/*letter-spacing: -.02em;*/
/*line-height: 1.27778em*/
/*}*/

/*.view-section .talk-list .block .chat-bubble .balloon {*/
/*position: absolute;*/
/*background-color: transparent;*/
/*background-image: url(../images/lecture/ballon-left.png);*/
/*background-repeat: no-repeat;*/
/*background-position-x: center;*/
/*background-position-y: center;*/
/*width: 78px;*/
/*height: 86px;*/
/*top: -64px;*/
/*left: -8px;*/
/*-webkit-display: flex;*/
/*display: -ms-flex;*/
/*display: flex;*/
/*justify-content: center;*/
/*align-items: center;*/
/*padding-bottom: 20px;*/
/*color: #ffc000;*/
/*font-size: 30px;*/
/*font-weight: 700;*/
/*font-family: CircularStd, sans-serif*/
/*}*/

/*.view-section .talk-list .block .chat-bubble .btn-practice {*/
/*display: inline-block;*/
/*margin: 0;*/
/*font-weight: 400;*/
/*text-align: center;*/
/*white-space: nowrap;*/
/*user-select: none;*/
/*text-decoration: none;*/
/*outline: 0;*/
/*vertical-align: middle;*/
/*display: block;*/
/*width: 127px;*/
/*height: 35px;*/
/*background-color: transparent;*/
/*background-image: url(../images/lecture/btn-practice.png);*/
/*background-repeat: no-repeat;*/
/*background-position-x: center;*/
/*background-position-y: center;*/
/*text-indent: -9999px;*/
/*border: 0;*/
/*margin-top: 10px;*/
/*float: right*/
/*}*/

/*.view-section .talk-list .block .chat-bubble .btn-practice.disabled,*/
/*.view-section .talk-list .block .chat-bubble .btn-practice:disabled {*/
/*opacity: .65*/
/*}*/

/*.view-section .talk-list .block .chat-bubble .btn-practice:not([disabled]):not(.disabled) {*/
/*cursor: pointer*/
/*}*/

/*.view-section .talk-list .block.right-block {*/
/*flex-direction: row-reverse*/
/*}*/

/*.view-section .talk-list .block.right-block .chat-bubble {*/
/*margin-left: 0;*/
/*margin-right: 20px;    */
/*}*/

/*.view-section .talk-list .block.right-block .chat-bubble:after {*/
/*left: auto;*/
/*right: -13px;*/
/*background-image: url(../images/lecture/bubble-arrow-right.png)*/
/*}*/

/*.view-section .talk-list .block.right-block .chat-bubble .balloon {*/
/*left: auto;*/
/*right: -8px;*/
/*background-image: url(../images/lecture/ballon-right.png);*/
/*color: #1c9dc9*/
/*}*/
/*!*.view-section .talk-list .block.right-block .chat-bubble h2,*!*/
/*!*.view-section .talk-list .block.right-block .chat-bubble p {*!*/
/*!*color: #fff*!*/
/*!*}*!*/

/*.view-section .talk-list .block.clicked .chat-bubble {*/
/*background-color: #37bce9*/
/*}*/

/*.view-section .talk-list .block.clicked .chat-bubble:after {*/
/*background-image: url(../images/lecture/bubble-arrow-b.png)*/
/*}*/

/*.view-section .talk-list .block.clicked .chat-bubble h2,*/
/*.view-section .talk-list .block.clicked .chat-bubble p {*/
/*color: #fff*/
/*}*/

/*.view-section .talk-list .block.clicked .chat-bubble .btn-practice {*/
/*background-color: transparent;*/
/*background-image: url(../images/lecture/btn-practice.png);*/
/*background-repeat: no-repeat;*/
/*background-position-x: center;*/
/*background-position-y: center*/
/*}*/

/*.view-section .talk-list .block.clicked.right-block .chat-bubble:after {*/
/*background-image: url(../images/lecture/bubble-arrow-right-b.png)*/
/*}*/

.view-section .talk-list .block {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-direction: row;
    align-items: flex-start
}

.view-section .talk-list .block + .block {
    margin-top: 30px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .talk-list .block + .block {
        margin-top: 20px
    }
}

.view-section .talk-list .block .profile {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background-color: #ffffff;
    border: 1px solid #d4d4d4;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .talk-list .block .profile {
        width: 60px;
        height: 60px;
    }
}

.view-section .talk-list .block .chat-bubble {
    position: relative;
    margin-left: 20px;
    background-color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
    padding: 15px 25px;
    border-radius: 10px;
    min-width: 150px;
    min-height: 60px;
}

.view-section .talk-list .block .chat-bubble:after {
    display: block;
    clear: both;
    content: ""
}

.view-section .talk-list .block .chat-bubble:after {
    content: '';
    display: block;
    position: absolute;
    width: 13px;
    height: 13px;
    top: 29px;
    left: -13px;
    background-color: transparent;
    background-image: url(../images/lecture/bubble-arrow-left.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .talk-list .block .chat-bubble h2 {
    color: #393939;
    font-weight: 500;
    font-size: 32px;
    letter-spacing: -.02em;
    line-height: 1.27778em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .talk-list .block .chat-bubble h2 {
        font-weight: 500;
        font-size: 26px;
        letter-spacing: -.02em;
        line-height: 1.27778em
    }
}

.view-section .talk-list .block .chat-bubble h2 + p {
    margin-top: 10px
}

.view-section .talk-list .block .chat-bubble p {
    color: #6b6b6b;
    font-size: 24px;
    font-weight: 500;
    letter-spacing: -.02em;
    line-height: 1.27778em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .view-section .talk-list .block .chat-bubble p {
        font-size: 20px;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 1.27778em
    }
}

.view-section .talk-list .block .chat-bubble .balloon {
    position: absolute;
    background-color: transparent;
    background-image: url(../images/lecture/ballon-left.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 78px;
    height: 86px;
    top: -64px;
    left: -8px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 20px;
    color: #ffc000;
    font-size: 30px;
    font-weight: 700;
    font-family: CircularStd, sans-serif
}

.view-section .talk-list .block .chat-bubble .btn-practice {
    opacity: 1;
    display: inline-block;
    margin: 0;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    display: block;
    width: 131px;
    height: 38px;
    background-color: transparent;
    background-image: url(../images/lecture/btn-practice-gray.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    text-indent: -9999px;
    border: 0;
    margin-top: 10px;
    float: right;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
}

.view-section .talk-list .block .chat-bubble .btn-practice:hover {
    background-image: url(../images/lecture/btn-practice-gray-hover.png);
}

.view-section .talk-list .block .chat-bubble .btn-practice.disabled,
.view-section .talk-list .block .chat-bubble .btn-practice:disabled {
    opacity: .65
}

.view-section .talk-list .block .chat-bubble .btn-practice:not([disabled]):not(.disabled) {
    cursor: pointer
}

.view-section .talk-list .block.right-block {
    flex-direction: row-reverse
}

.view-section .talk-list .block.right-block .chat-bubble {
    margin-left: 0;
    margin-right: 20px
}

.view-section .talk-list .block.right-block .chat-bubble:after {
    left: auto;
    right: -13px;
    background-image: url(../images/lecture/bubble-arrow-right.png)
}

.view-section .talk-list .block.right-block .chat-bubble .balloon {
    left: auto;
    right: -8px;
    background-image: url(../images/lecture/ballon-right.png);
    color: #1c9dc9
}

.view-section .talk-list .block.clicked .chat-bubble {
    background-color: #37bce9
}

.view-section .talk-list .block.clicked .chat-bubble:after {
    background-image: url(../images/lecture/bubble-arrow-b.png)
}

.view-section .talk-list .block.clicked .chat-bubble h2,
.view-section .talk-list .block.clicked .chat-bubble p {
    color: #fff
}

.view-section .talk-list .block.clicked .chat-bubble .btn-practice {
    background-color: transparent;
    background-image: url(../images/lecture/btn-practice.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.view-section .talk-list .block.clicked.right-block .chat-bubble:after {
    background-image: url(../images/lecture/bubble-arrow-right-b.png)
}

@media screen and (max-width: 1199px) {

    .view-section .talk-list .block {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-direction: row;
        align-items: flex-start
    }

    .view-section .talk-list .block+.block {
        margin-top: .625rem
    }

    .view-section .talk-list .block .profile {
        display: block;
        width: 2.58333rem;
        height: 2.58333rem
    }

    .view-section .talk-list .block .chat-bubble {
        position: relative;
        margin-left: 20px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .1);
        padding: 15px 25px;
        border-radius: 10px;
        background-color: #fff
    }

    .view-section .talk-list .block .chat-bubble:after {
        display: block;
        clear: both;
        content: ""
    }

    .view-section .talk-list .block .chat-bubble:after {
        content: '';
        display: block;
        position: absolute;
        width: .5rem;
        height: .5rem;
        top: 29px;
        left: -.5rem;
        background-image: url(../images-m/lecture/bubble-arrow-left-m.png);
        background-size: .5rem .5rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-left-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-left-m@3x.png)
        }
    }

    .view-section .talk-list .block .chat-bubble h2 {
        color: #393939;
        font-weight: 500;
        font-size: 1rem;
        letter-spacing: -.02em;
        line-height: 1.27778em
    }

    .view-section .talk-list .block .chat-bubble h2+p {
        margin-top: .1875rem
    }

    .view-section .talk-list .block .chat-bubble p {
        color: #6b6b6b;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 1.27778em
    }

    .view-section .talk-list .block .chat-bubble .balloon {
        position: absolute;
        background-image: url(../images-m/lecture/ballon-left-m.png);
        background-size: 2.0625rem 2.25rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        width: 2.0625rem;
        height: 2.25rem;
        top: -1.33333rem;
        left: -8px;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: .3125rem;
        color: #ffc000;
        font-size: .875rem;
        font-weight: 700;
        font-family: CircularStd, sans-serif
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block .chat-bubble .balloon {
            background-image: url(../images-m/lecture/ballon-left-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block .chat-bubble .balloon {
            background-image: url(../images-m/lecture/ballon-left-m@3x.png)
        }
    }

    .view-section .talk-list .block .chat-bubble .btn-practice {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        display: block;
        width: 4.875rem;
        height: 1.25rem;
        background-image: url(../images-m/lecture/btn-practice-m.png);
        background-size: 4.875rem 1.25rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        background-color: transparent;
        background-image: url(../images/lecture/btn-practice.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        text-indent: -9999px;
        border: 0;
        margin-top: 10px;
        float: right
    }

    .view-section .talk-list .block .chat-bubble .btn-practice.disabled,
    .view-section .talk-list .block .chat-bubble .btn-practice:disabled {
        opacity: .65
    }

    .view-section .talk-list .block .chat-bubble .btn-practice:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block .chat-bubble .btn-practice {
            background-image: url(../images-m/lecture/btn-practice-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block .chat-bubble .btn-practice {
            background-image: url(../images-m/lecture/btn-practice-m@3x.png)
        }
    }

    .view-section .talk-list .block.right-block {
        flex-direction: row-reverse
    }

    .view-section .talk-list .block.right-block .chat-bubble {
        margin-left: 0;
        margin-right: 20px
    }

    .view-section .talk-list .block.right-block .chat-bubble:after {
        left: auto;
        right: -.5rem;
        background-image: url(../images-m/lecture/bubble-arrow-right-m.png);
        background-size: .5rem .5rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block.right-block .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-right-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block.right-block .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-right-m@3x.png)
        }
    }

    .view-section .talk-list .block.right-block .chat-bubble .balloon {
        left: auto;
        right: -8px;
        background-image: url(../images-m/lecture/ballon-right-m.png);
        background-size: 2.0625rem 2.25rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        color: #1c9dc9
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block.right-block .chat-bubble .balloon {
            background-image: url(../images-m/lecture/ballon-right-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block.right-block .chat-bubble .balloon {
            background-image: url(../images-m/lecture/ballon-right-m@3x.png)
        }
    }

    .view-section .talk-list .block.clicked .chat-bubble {
        background-color: #37bce9
    }

    .view-section .talk-list .block.clicked .chat-bubble:after {
        background-image: url(../images-m/lecture/bubble-arrow-left-b-m.png);
        background-size: .5rem .5rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block.clicked .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-left-b-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block.clicked .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-left-b-m@3x.png)
        }
    }

    .view-section .talk-list .block.clicked .chat-bubble h2,
    .view-section .talk-list .block.clicked .chat-bubble p {
        color: #fff
    }

    .view-section .talk-list .block.clicked.right-block .chat-bubble:after {
        background-image: url(../images-m/lecture/bubble-arrow-right-b-m.png);
        background-size: .5rem .5rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .view-section .talk-list .block.clicked.right-block .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-right-b-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .view-section .talk-list .block.clicked.right-block .chat-bubble:after {
            background-image: url(../images-m/lecture/bubble-arrow-right-b-m@3x.png)
        }
    }

}

.mask {
    display: none;
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: .5;
    -moz-opacity: .5;
    background: #4a4a4a
}

.help-mask {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    /*opacity: .5;*/
    -moz-opacity: .5;
    background: #4a4a4a
}

.help-poplayer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 101;
    /*display: none;*/
}

.help-poplayer .help-box {
    position: fixed;
    right: 15px;
    top: 78px;
    background-color: #fff;
    width: 294px;
    box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .16);
    border-radius: 10px;
    border: 1px solid #106a8d;
    z-index: 102
}

.help-poplayer .help-box:before {
    content: '';
    background-color: transparent;
    background-image: url(../images/common/box-triangle.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    display: block;
    width: 17px;
    height: 10px;
    position: absolute;
    top: -9px;
    right: 78px;
    z-index: 102;
    background-size: 100%
}

/*@media screen and (max-width: 768px) {*/
    /*.help-poplayer .help-box:before {*/
        /*right: 38px*/
    /*}*/
/*}*/

/*@media screen and (max-width: 768px) {*/
    /*.help-poplayer .help-box {*/
        /*top: 58px*/
    /*}*/
/*}*/

.help-poplayer .help-box ul {
    list-style: none;
    padding: 0;
    margin: 0
}

.help-poplayer .help-box ul > li {
    padding: 18px 25px
}

.help-poplayer .help-box ul > li + li {
    border-top: 1px dashed #cfcfcf
}

.help-poplayer .help-box ul > li h4 {
    color: #309ac2;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.02em;
    margin: 0 0 5px
}

.help-poplayer .help-box ul > li p {
    color: #676767;
    font-size: 14px;
    line-height: 1.57143em;
    letter-spacing: -.02em
}

.speak-poplayer {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 810px;
    height: 735px;
    margin-left: -505px;
    margin-top: -364px;
    z-index: 101;
    display: none;
    background-color: #fff;
    -webkit-border-radius: 60px;
    -moz-border-radius: 60px;
    border-radius: 60px;
    padding-bottom: 60px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer {
        width: 700px;
        height: 636px;
        margin-left: -350px;
        margin-top: -318px;
        padding-bottom: 60px
    }
}

.speak-poplayer > h4 {
    color: #fff;
    text-align: center;
    font-size: 40px;
    padding: 20px 70px 20px 30px;
    -webkit-border-radius: 60px 60px 0 0;
    -moz-border-radius: 60px 60px 0 0;
    border-radius: 60px 60px 0 0;
    background-color: #00759c;
    position: relative
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer > h4 {
        font-size: 30px;
        padding: 18px 70px 18px 30px;
    }
}

.speak-poplayer .btn-pop-close {
    position: absolute;
    right: 30px;
    top: 30px;
    background-color: transparent;
    background-image: url(../images/common/btn-popup-close.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    text-indent: -99999px;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
    width: 38px;
    height: 38px;
    cursor: pointer
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer .btn-pop-close {
        position: absolute;
        right: 40px;
        top: 25px;
    }
}

.speak-poplayer .btn-pop-close:focus,
.speak-poplayer .btn-pop-close:hover {
    outline: 0
}

.speak-poplayer .speak-list {
    background-color: #fff;
    height: 580px;
    overflow-y: scroll
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer .speak-list {
        height: 500px;
    }
}

.speak-poplayer .speak-list li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    width: 100%;
    align-items: center;
    padding: 18px 35px;
    border-bottom: 1px solid #ececec
}

.speak-poplayer .speak-list li p {
    flex: auto;
    color: #292929;
    font-size: 26px;
    line-height: 1.5;
    font-weight: 600;
    max-width: 640px;
}

.speak-poplayer .speak-list .l5-comprehension {
    max-width: initial;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer .speak-list li {
        padding: 14px 28px;
    }

    .speak-poplayer .speak-list li p {
        font-size: 22px;
        line-height: 1.4;
        max-width: 580px;
    }

    .speak-poplayer .speak-list .l5-comprehension {
        max-width: initial;
    }
}

.speak-poplayer .speak-list li p > span {
    color: #787878;
    font-size: 24px;
    font-weight: 400
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer .speak-list li p > span {
        color: #787878;
        font-size: 20px;
    }
}

.speak-poplayer .speak-list li .btn-play-sound {
    width: 80px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .speak-poplayer .speak-list li .btn-play-sound {
        width: 50px;
        background-size: contain;
    }
}


@media screen and (max-width: 1199px) {

    .mask {
        display: none;
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        opacity: .5;
        -moz-opacity: .5;
        background: #4a4a4a
    }

    .help-mask{
        z-index: 100;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        /*opacity: .5;*/
        -moz-opacity: .5;
        background: #4a4a4a
    }

    .help-poplayer {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 101;
        /*display: none*/
    }

    .help-poplayer .help-box {
        position: fixed;
        right: 15px;
        top: 3.75rem-2px;
        background-color: #fff;
        width: 294px;
        box-shadow: 0 5px 5px 0 rgba(0, 0, 0, .16);
        border-radius: 10px;
        border: 1px solid #106a8d;
        z-index: 102
    }

    .help-poplayer .help-box:before {
        content: '';
        background-color: transparent;
        background-image: url(../images/common/box-triangle.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        display: block;
        width: 17px;
        height: 10px;
        position: absolute;
        top: -9px;
        z-index: 102;
        background-size: 100%;
        right: 38px
    }

    @media screen and (max-width:768px) {
        .help-poplayer .help-box {
            top: 3.75rem - 2px;
        }
    }

    .help-poplayer .help-box ul {
        list-style: none;
        padding: 0;
        margin: 0
    }

    .help-poplayer .help-box ul>li {
        padding: 18px 25px
    }

    .help-poplayer .help-box ul>li+li {
        border-top: 1px dashed #cfcfcf
    }

    .help-poplayer .help-box ul>li h4 {
        color: #309ac2;
        font-size: 16px;
        font-weight: 500;
        letter-spacing: -.02em;
        margin: 0 0 5px
    }

    .help-poplayer .help-box ul>li p {
        color: #676767;
        font-size: 14px;
        line-height: 1.57143em;
        letter-spacing: -.02em
    }

    .speak-poplayer {
        position: fixed;
        top: 50%;
        left: 50%;
        width: 28.75rem;
        height: 16.66667rem;
        z-index: 101;
        display: none;
        background-color: #fff;
        -webkit-border-radius: 1.25rem;
        -moz-border-radius: 1.25rem;
        border-radius: 1.25rem;
        padding-bottom: 60px;
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        margin-left:initial;
        margin-top:initial;
    }

    .speak-poplayer>h4 {
        color: #fff;
        text-align: center;
        font-size: .875rem;
        padding: .625rem 1.45833rem .625rem .625rem;
        -webkit-border-radius: 1.25rem 1.25rem 0 0;
        -moz-border-radius: 1.25rem 1.25rem 0 0;
        border-radius: 1.25rem 1.25rem 0 0;
        background-color: #00759c;
        position: relative
    }

    .speak-poplayer .btn-pop-close {
        position: absolute;
        right: .5625rem;
        top: .6875rem;
        background-image: url(../images-m/common/btn-popup-close-m.png);
        background-size: 1.375rem 1.375rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        text-indent: -99999px;
        background-color: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: none;
        width: 1.375rem;
        height: 1.375rem;
        cursor: pointer
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .speak-poplayer .btn-pop-close {
            background-image: url(../images-m/common/btn-popup-close-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .speak-poplayer .btn-pop-close {
            background-image: url(../images-m/common/btn-popup-close-m@3x.png)
        }
    }

    .speak-poplayer .btn-pop-close:focus,
    .speak-poplayer .btn-pop-close:hover {
        outline: 0
    }

    .speak-poplayer .speak-list {
        background-color: #fff;
        overflow-y: scroll;
        height: 14.22917rem;
        border-radius: 0 0 1.25rem 1.25rem
    }

    .speak-poplayer .speak-list li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        width: 100%;
        align-items: center;
        padding: .625rem 1.10417rem;
        border-bottom: 1px solid #ececec
    }

    .speak-poplayer .speak-list li p {
        flex: auto;
        color: #292929;
        font-size: .875rem;
        line-height: 1.5;
        font-weight: 600
    }

    .speak-poplayer .speak-list li p>span {
        color: #787878;
        font-size: .8125rem;
        font-weight: 400
    }
}




.score-poplayer {
    background-color: transparent;
    background-image: url(../images/common/popup-score-back.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 460px;
    height: 460px;
    z-index: 101;
    display: block;
    -webkit-transform: translateY(-200%);
    -moz-transform: translateY(-200%);
    -ms-transform: translateY(-200%);
    -o-transform: translateY(-200%);
    transform: translateY(-200%);
    opacity: 0
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-poplayer {
        background-size: contain;
        width: 360px;
        height: 360px;
    }
}

.score-poplayer .btn-pop-close {
    position: absolute;
    right: 65px;
    top: 50px;
    background-color: transparent;
    background-image: url(../images/common/popup-score-btn-close.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    text-indent: -99999px;
    background-color: #fff;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    border: none;
    width: 47px;
    height: 47px;
    cursor: pointer
}

.score-poplayer .btn-pop-close:focus,
.score-poplayer .btn-pop-close:hover {
    outline: 0
}

.score-poplayer .content {
    height: 430px
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-poplayer .content {
        height: 340px;
    }
}

.score-poplayer .content > p {
    text-align: center;
    color: #fff;
    font-size: 154px;
    padding: 180px 0 125px 0
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-poplayer .content > p {
        padding: 160px 0 80px 0;
        font-size: 124px;
    }
}

.score-poplayer .content .btn-answer {
    background-color: transparent;
    background-image: url(../images/common/popup-score-btn.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 271px;
    height: 89px;
    display: block;
    text-indent: -9999px;
    margin: 0 auto;
    border:0;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-poplayer .content .btn-answer {
        background-size: contain;
        width: 231px;
        height: 69px;
        display: block;
        text-indent: -9999px;
        margin: 0 auto
    }
}


@media screen and (max-width:1199px){

    .score-poplayer {
        background-image: url(../images-m/common/popup-score-back-m.png);
        background-size: 15rem 15rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        width: 15rem;
        height: 15rem;
        z-index: 101;
        display: block;
        -webkit-transform: translateY(-200%);
        -moz-transform: translateY(-200%);
        -ms-transform: translateY(-200%);
        -o-transform: translateY(-200%);
        transform: translateY(-200%);
        opacity: 0
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .score-poplayer {
            background-image: url(../images-m/common/popup-score-back-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .score-poplayer {
            background-image: url(../images-m/common/popup-score-back-m@3x.png)
        }
    }

    .score-poplayer .btn-pop-close {
        position: absolute;
        right: 65px;
        top: 50px;
        text-indent: -99999px;
        background-color: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        border: none;
        width: 5.5625rem;
        height: 1.75rem;
        cursor: pointer
    }


    .score-poplayer .content {
        height: 10rem;
    }

    .score-poplayer .content>p {
        text-align: center;
        color: #fff;
        font-size: 4.5rem;
        padding-top: 4.5rem;
        padding-bottom: 2.5rem;
        line-height: 1em
    }

    .score-poplayer .content .btn-answer {
        background-image: url(../images-m/common/popup-score-btn-m.png);
        background-size: 8.2rem 2.7rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat;
        width: 8.2rem;
        height: 2.7rem;
        display: block;
        text-indent: -9999px;
        margin: 0 auto
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .score-poplayer .content .btn-answer {
            background-image: url(../images-m/common/popup-score-btn-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .score-poplayer .content .btn-answer {
            background-image: url(../images-m/common/popup-score-btn-m@3x.png)
        }
    }

}

.score-help-block {
    color: #00759c;
    font-size: 22px;
    font-weight: 500;
    text-align: center;
    margin-top: 35px;
    line-height: 1.68182em
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-help-block {
        font-size: 20px;
    }
}

.score {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 139px;
    height: 152px;
    color: #fff;
    font-size: 60px;
    font-weight: 700;
    letter-spacing: -.02em;
    padding-bottom: 32px;
    background-color: transparent;
    background-image: url(../images/lecture/score-blue.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.score.score-blue {
    background-image: url(../images/lecture/score-blue.png)
}

.score.score-yellow {
    background-image: url(../images/lecture/score-yellow.png)
}

.score.score-orange {
    background-image: url(../images/lecture/score-orange.png)
}

.toggle-switcher {
    position: absolute;
    top: 35px;
    right: 35px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center
}

.toggle-switcher .label {
    margin: 0 10px;
    font-size: 14px;
    color: #616265
}

.toggle-switcher input[type=checkbox] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0
}

.toggle-switcher input[type=checkbox] + .toggler {
    display: block;
    width: 83px;
    height: 46px;
    background-color: transparent;
    background-image: url(../images/lecture/toggle-off.png);
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center
}

.toggle-switcher input[type=checkbox]:checked + .toggler {
    background-image: url(../images/lecture/toggle-on.png)
}

.toggle-container {
    width: 100%;
    padding-left: 5vw
}

.toggle-container .toggle-wrap {
    display: -webkit-inline-flex;
    display: -ms-inline-flexbox;
    display: inline-flex;
    flex-direction: column;
    z-index: 2;
    position: relative
}

.toggle-container .toggle-wrap p {
    font-size: 28px;
    font-weight: 500;
    color: #424141;
    letter-spacing: -.02em;
    line-height: 1.71429em;
    width: auto
}

.toggle-container .no-toggle-wrap {
    position: absolute;
    right: 5vw;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.toggle-container .no-toggle-wrap .score {
    position: absolute;
    top: 5px;
    right: -10px;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%)
}

.toggle-container.toggle-off {
    width: auto;
    padding: 0
}

.toggle-container.toggle-off .toggle-wrap {
    display: none
}

.toggle-container.toggle-off .no-toggle-wrap {
    float: none;
    position: relative;
    right: auto;
    top: auto;
    -webkit-transform: none;
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    transform: none
}

/**
추가학습 이후 추가된 CSS
 */

.top-tip {
    position: absolute;
    top: 35px;
    left: 90px;
    color: #222222;
    font-size: 22px;
    font-weight: 400;
    padding-left: 38px;
    background-color: transparent;
    background-image: url("../images/icons/icon-ballon.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position: 0 0;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .top-tip {
        position: absolute;
        top: 0;
        left: 40px;
        font-size: 16px;
        padding-left: 38px;
    }
}

@media screen and (max-width: 1199px){
    .top-tip {
        position: absolute;
        top: .72917rem;
        left: 1.875rem;
        color: #222;
        font-size: .6875rem;
        font-weight: 400;
        padding-left: 1.14583rem;
        background-color: transparent;
        background-image: url(../images/icons/icon-ballon.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-position: 0 center;
        background-size: .95833rem .72917rem
    }

}



.top-tip.hide {
    display: none;
}

.common-popup {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1000;
    display: none;
}

.common-popup .mask {
    z-index: 1001;
    position: absolute;
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
    filter: progid:DxImageTransform.Microsoft.Alpha(opacity=50);
    opacity: 0.5;
    -moz-opacity: 0.5;
    background: #4a4a4a;
}

.common-popup .popup-wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*width: 648px;*/
    /*height: 576px;*/
    z-index: 1002;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
    border-radius: 56px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .common-popup .popup-wrap {
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
    }
}

.common-popup .popup-wrap-story {
    width: 648px;
    height: 576px;
}

.common-popup .popup-wrap .pop-header {
    background-color: #00759c;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.17);
    width: 100%;
    position: relative;
    border-top-left-radius: 56px;
    border-top-right-radius: 56px;
    height: 64.8px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.common-popup .popup-wrap .pop-header .pop-title {
    text-align: center;
    color: #fff;
    font-size: 20.8px;
    font-weight: 500;
}

.common-popup .popup-wrap .pop-header .btn-pop-close {
    position: absolute;
    top: 18.4px;
    right: 22.4px;
}

.common-popup .popup-wrap .pop-body {
    width: 100%;
    display: block;
    background-color: #fff;
    border-bottom-left-radius: 56px;
    border-bottom-right-radius: 56px;
    padding: 40px;
    overflow-y: auto;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .common-popup .popup-wrap .pop-body {
        padding: 4px;
    }

    .common-popup .popup-wrap .pop-body.l4sp {
        padding: 30px 30px 60px 30px;
    }

    .common-popup .popup-wrap .pop-body img {
        width: 800px;
    }
}


@media screen and (max-width: 1199px){

    .common-popup .popup-wrap {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 34.58333rem;
        height: 20.375rem;
        z-index: 1002;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .06);
        border-radius: 1.45833rem
    }

    .common-popup .popup-wrap .pop-header {
        background-color: #00759c;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .17);
        width: 100%;
        position: relative;
        border-top-left-radius: 1.45833rem;
        border-top-right-radius: 1.45833rem;
        height: 2.5rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center
    }

    .common-popup .popup-wrap .pop-header .pop-title {
        text-align: center;
        color: #fff;
        font-size: 1.02083rem;
        font-weight: 500
    }

    .common-popup .popup-wrap .pop-header .btn-pop-close {
        position: absolute;
        top: .9375rem;
        right: 1.45833rem
    }

    .common-popup .popup-wrap .pop-body {
        height: 16.66667rem;
        width: 100%;
        display: block;
        background-color: #fff;
        border-bottom-left-radius: 1.45833rem;
        border-bottom-right-radius: 1.45833rem;
        padding: 0;
        overflow-y: auto
    }

    .common-popup .popup-wrap .pop-body figure{
        text-align:center;
    }
    .common-popup .popup-wrap .pop-body figure>img {
        width: 95%;
    }
}



.view-section .sound-box .sound-box-text.type2 {
    max-width: 764px;
    margin: 0 auto;
    padding: 30px;
    min-height:98px;
    transform: none;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .sound-box .sound-box-text.type2 {
        max-width: 620px;
        margin: 0 auto;
        padding: 12px;
    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text.type2 {
        max-width: 764px;
        margin: 0 auto;
        padding: 15px;
    }
}

.view-section .sound-box .sound-box-text.type2 .word-kor {
    font-size: 44px;
    font-weight: 500;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .sound-box .sound-box-text.type2 .word-kor {
        font-size: 28px;
        font-weight: 500;

    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text.type2 .word-kor {
        font-size: 38px;
        font-weight: 500;
    }
}



.view-section .sound-box .sound-box-text.type2 .word-kor strong {
    color: #00759c;
}


.view-section .sound-box .sound-box-text.type2 .word-eng {
    font-size: 24px;
    font-weight: 500;
    color: #00759c;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .sound-box .sound-box-text.type2 .word-eng {
        font-size: 20px;
        font-weight: 500;
        color: #00759c;
    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .sound-box .sound-box-text.type2 .word-eng {
        font-size: 22px;
        font-weight: 500;
        color: #00759c;
    }
}

@media screen and (max-width: 1199px){
    .view-section .sound-box .sound-box-text.type2 {
        margin: 0 auto;
        padding: 5px;
        min-height: initial;
        line-height: 1.35;
    }

    .view-section .sound-box .sound-box-text.type2 .word-kor {
        font-size: 1rem;
        font-weight: 700
    }

    .view-section .sound-box .sound-box-text.type2 .word-eng {
        font-size: .8125rem;
        font-weight: 500
    }

}


.view-section .sound-box .voice-input-box {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {

    .view-section .sound-box .voice-input-box {
        margin-top: 10px;
    }

}
@media screen and (min-width: 1401px) and (max-width: 1600px) {

    .view-section .sound-box .voice-input-box {
        margin-top: 20px;
    }

}

.view-section .sound-box .voice-input-box .voice-before {
    color: #222222;
    font-size: 36px;
    font-weight: 500;
}

.view-section .sound-box .voice-input-box .voice-before + .voice-input {
    margin-left: 23px;
}

.view-section .sound-box .voice-input-box .voice-input {
    max-width: 800px;
    min-width: 360px;
    min-height: 89px;
    background-color: #ffffff;
    border: 2px solid #bfc0c2;
    color: #00759c;
    font-size: 1.5em;
    font-weight: 500;
    line-height: 1.5em;
    border-radius: 12px;
    padding: 10px 27px;
    display:flex;
    justify-content: center;
    align-items:center;

}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .sound-box .voice-input-box .voice-input {
        max-width: 800px;
        min-width: 340px;
        min-height: 68px;
        font-size: 28px;
        font-weight: 500;
        border-radius: 12px;
        padding: 10px 24px;
    }
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .sound-box .voice-input-box .voice-input {
        font-size: 24px;
        font-weight: 400;
        border-radius: 12px;
        padding: 8px 20px;
    }
}

.view-section .sound-box .voice-input-box .voice-input:focus {
    outline: none;
}

@media screen and (max-width: 1199px){
    .view-section .sound-box .voice-input-box {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: .83333rem
    }

    .view-section .sound-box .voice-input-box .voice-before {
        color: #222;
        font-size: .875rem;
        font-weight: 500
    }

    .view-section .sound-box .voice-input-box .voice-before+.voice-input {
        margin-left: .625rem
    }

    .view-section .sound-box .voice-input-box .voice-input {
        width: 9.6875rem;
        background-color: initial;
        border: none;
        border-bottom: 2px solid #222;
        color: #00759c;
        font-size: .875rem;
        font-weight: 500;
        line-height: 1.333em;
        padding: 0 .625rem;
        min-height: 1.5rem;
        border-radius:0;
        text-align:center;


    }

    .view-section .sound-box .voice-input-box .voice-input:focus {
        outline: 0
    }
}



.view-section .sound-box .ptp-img {
    display: block;
    margin: 20px auto 20px auto;
    height: 360px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .view-section .sound-box .ptp-img {
        display: block;
        margin: 0 auto 20px auto;
        height:250px;

    }
}

@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .view-section .sound-box .ptp-img {
        display: block;
        margin: 0 auto 10px auto;
        height:300px;
    }
}

@media screen and (max-width: 1199px){
    .view-section .sound-box .ptp-img {
        height:63%;
        width:auto;
        margin: 5px auto 0 auto;

    }
}



.exercise-question .question-wrap {
    display: none;
}

.exercise-question .tit {
    color: #222222;
    font-size: 78px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.02em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .exercise-question .tit {
        font-size: 54px;
    }
}

.exercise-question .tit strong {
    font-weight: 500;
    color: #f56e01;
}



.exercise-question .sub-tit {
    color: #00759c;
    font-size: 54px;
    text-align: center;
    line-height: 1.5em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .exercise-question .sub-tit {
        font-size: 38px;
    }
}


@media screen and (max-width: 1199px){
    .exercise-question .tit {
        color: #222;
        font-size: 2.5rem;
        font-weight: 500;
        text-align: center;
        letter-spacing: -.02em
    }

    .exercise-question .tit strong {
        font-weight: 500;
        color: #f56e01
    }
    .exercise-question .sub-tit {
        color: #00759c;
        font-size: 1.45833rem;
        text-align: center;
        line-height: 1.5em
    }

}


.exercise-question .btn-change-plain, .exercise-question .btn-change-question {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    width: 303px;
    height: 71px;
    display: block;
    padding: 0;
    margin: 0 auto;
    border: 0 none;
    background-color: transparent;
    text-indent: -9999px;
}

.exercise-question .btn-change-plain:disabled, .exercise-question .btn-change-plain.disabled, .exercise-question .btn-change-question:disabled, .exercise-question .btn-change-question.disabled {
    opacity: 0.65;
}

.exercise-question .btn-change-plain:not([disabled]):not(.disabled), .exercise-question .btn-change-question:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.exercise-question .btn-change-plain {
    background-color: transparent;
    background-image: url("../images/buttons/btn-change-plain.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.exercise-question .btn-change-question {
    background-color: transparent;
    background-image: url("../images/buttons/btn-change-question.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

@media screen and (max-width: 1199px){
    .exercise-question .btn-change-plain,
    .exercise-question .btn-change-question {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        width: 9.54167rem;
        height: 2.10417rem;
        display: block;
        padding: 0;
        margin: 0 auto;
        border: 0 none;
        background-color: transparent;
        text-indent: -9999px;
        background-size: 100% 99%
    }

    .exercise-question .btn-change-plain.disabled,
    .exercise-question .btn-change-plain:disabled,
    .exercise-question .btn-change-question.disabled,
    .exercise-question .btn-change-question:disabled {
        opacity: .65
    }

    .exercise-question .btn-change-plain:not([disabled]):not(.disabled),
    .exercise-question .btn-change-question:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .exercise-question .btn-change-plain {
        background-color: transparent;
        background-image: url(../images/buttons/btn-change-plain.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }

    .exercise-question .btn-change-question {
        background-color: transparent;
        background-image: url(../images/buttons/btn-change-question.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }
}


.exercise-question .desc-box {
    background-color: #fff;
    display: block;
    margin: 0 auto;
    padding: 20px 30px;
    border: 1px solid #d8d9de;
    border-radius: 10px;
    max-width: 1000px;
}

.exercise-question .desc-box .desc-sub {
    color: #3b3b3b;
    font-weight: 500;
    font-size: 18px;
    line-height: 1.38889em;
    margin: 0 0 25px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .exercise-question .desc-box .desc-sub {
        margin: 0 0 15px;
    }
}

.exercise-question .desc-box .desc-content {
    line-height: 1.70833em;
    color: #292929;
    font-weight: 400;
}

.exercise-question .desc-box .desc-content strong {
    display: inline-block;
    font-weight: 400;
    padding: 0 17px;
    background: #ffe5b7;
    border-radius: 5px;
    line-height: 1.70833em;
    margin-right: 9px;
}

@media screen and (max-width: 1199px){

    .exercise-question .desc-box {
        background-color: #fff;
        display: block;
        margin: 0 auto;
        padding: .625rem .72917rem;
        border: 1px solid #d8d9de;
        border-radius: 10px
    }

    .exercise-question .desc-box .desc-sub {
        color: #3b3b3b;
        font-weight: 500;
        font-size: .6875rem;
        line-height: 1.38889em;
        margin: 0 0 .52083rem
    }

    .exercise-question .desc-box .desc-content {
        line-height: 1.70833em;
        color: #292929;
        font-weight: 400;
        font-size: .625rem
    }

    .exercise-question .desc-box .desc-content strong {
        display: inline-block;
        font-weight: 400;
        padding: 0 .35417rem;
        background: #ffe5b7;
        border-radius: 5px;
        line-height: 1.70833em;
        margin-right: .1875rem
    }
}


.exercise-question.plain .hide {
    opacity: 0;
    visibility: hidden;
}

.exercise-question.plain .show {
}

.test-question {
    position: relative;
    margin-top:80px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .test-question {
        position: relative;
        margin-top:50px;
    }
}


.test-question .test-header {
    position: relative;
    margin: 0 0 20px;
}

.test-question .test-header.l5 {
    margin: 0;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .test-question .test-header.l5 {
        margin: 0;
    }
}

@media screen and (max-width: 1199px){
    .test-question .test-header.l5 {
        margin-top: 9vh;
    }
}


.test-question .test-header .tit {
    color: #222222;
    font-size: 44px;
    text-align: center;
    font-weight: 500;
}

.test-question .test-header .tit strong {
    color: #00759c;
    font-weight: 500;
}

.test-question .test-header .hint {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
}

.test-question .test-header .hint .btn-test-hint {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    background-color: transparent;
    background-image: url("../images/icons/icon-test-hint.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position-x: right;
    padding: 0;
    padding-right: 20px;
    border: 0;
    height: 29px;
}

.test-question .test-header .hint .btn-test-hint:disabled, .test-question .test-header .hint .btn-test-hint.disabled {
    opacity: 0.65;
}

.test-question .test-header .hint .btn-test-hint:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.test-question .test-header .hint .btn-test-hint .text {
    line-height: 29px;
    color: #fa6a00;
    display: inline-block;
    font-size: 16px;
    font-weight: 400;
    padding: 0 15px;
    border: 1px solid #fa6a00;
    border-radius: 14px;
}

.test-question .test-header .hint .hint-content {
    display: none;
    line-height: 29px;
    font-size: 16px;
    font-weight: 400;
    padding: 0 15px;
    border: 1px solid #fa6a00;
    border-radius: 14px;
    background-color: #fa6a00;
    color: #fff;
}

.test-question .test-header .hint.opened .hint-content {
    display: block;
}

.test-question .test-header .hint.opened .btn-test-hint .text {
    display: none;
}

.test-question .test-header .btn-play-sound {
    margin: 0 auto;
    display: block;
}

.test-question .sorting-card {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
}

.test-question .sorting-card .s-card {
    margin: 0 5px;
}

.test-question .s-card {
    position: relative;
    min-width: 175px;
    height: 71px;
    display: block;
    border-radius: 6px;
    font-size: 34px;
    font-weight: 500;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border: 2px solid #15a6ca;
    color: #222222;
    padding:0 16px;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .test-question .s-card {
        min-width: 120px;
        height: 48px;
        font-size: 24px;
        font-weight: 400;
        padding:0 10px;

    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .test-question .s-card {
        min-width: 150px;
        height: 52px;
        font-size: 26px;
        font-weight: 400;
        padding:0 10px;

    }
}

.test-question .s-card .btn-remove-card {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    position: absolute;
    display: none;
    width: 31px;
    height: 30px;
    text-indent: -9999px;
    border: 0 none;
    padding: 0;
    top: -14px;
    left: 50%;
    transform: translateX(-50%);
    background-color: transparent;
    background-image: url("../images/buttons/btn-remove-card.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.test-question .s-card .btn-remove-card:disabled, .test-question .s-card .btn-remove-card.disabled {
    opacity: 0.65;
}

.test-question .s-card .btn-remove-card:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.test-question.small-card .s-card {
    min-width: 74px;
}

.test-question .answer-wrap {
    height: 200px;
    width: 1109px;
    background: #fff;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #CCCCCF;
    border-radius: 10px;
    position: relative;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .test-question .answer-wrap {
        height: 140px;
        width: 880px;
    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .test-question .answer-wrap {
        height: 140px;
        width: 980px;
    }
}

.test-question .answer-wrap.border-none {
    background-color: transparent;
    border: 0 none;
}

.test-question .answer-wrap.shorter {
    height: 140px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .test-question .answer-wrap.shorter {
        height: 100px;
    }
}

@media screen and (max-width: 1199px){

    .test-question .answer-wrap {
        height: 4.16667rem;
        width: 100%;
        background: #fff;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative
    }

    .test-question .answer-wrap.shorter {
        height: 4rem;
    }
}

.test-question .answer-wrap.style2 {
    height: auto;
    padding: 28.8px 28.8px;
    flex-wrap: wrap;
    justify-content: left;
    background: #f7f8fc;
    border: 1px solid #DCDDE1;
    border-radius: 10px;
    width: 882.4px;
    box-shadow: inset 0px 5px 8px 0px rgba(0, 0, 0, 0.07);
    min-height: 124px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .test-question .answer-wrap.style2 {
        padding: 26px 26px;
    }
}

.test-question .answer-wrap.style2 .sorting-card {
    width: 100%;
    flex-wrap: wrap;
}

.test-question .answer-wrap.style2 .sorting-card .s-card {
    min-width: 142.4px;
    height: 59.2px;
    margin: 0 2px 5px;
}

.test-question .answer-wrap.style2 .sorting-card .s-card:last-child .btn-remove-card {
    display: block;
}

.test-question .answer-wrap .btn-play-sound {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -30px;
}

.test-question .answer-wrap .sorting-card .s-card.empty {
    background-color: #F6F6F8;
    border: 2px dotted #88898b;
}

.test-question .answer-wrap .sorting-card .s-card.wrong {
    background-color: #ffe0bf;
    color: #ff5400;
    border: 2px solid #ff8400;
}

.test-question .answer-wrap .sorting-card .s-card.wrong .btn-remove-card {
    display: block;
}

.test-question .answer-wrap .sorting-card .s-card.correct {
    border: 2px solid #15a6ca;
    background-color: #15a6ca;
    color: #fff;
}

.test-question .answer-wrap .sorting-card .plain {
    color: #222222;
    font-size: 34px;
    font-weight: 500;
    margin: 0 10px;
}

.test-question .option-wrap {
    border: 1px solid #DCDDE1;
    box-shadow: inset 0px 5px 8px 0px rgba(0, 0, 0, 0.04);
    width: 1103px;
    height: 207px;
    background-color: #F7F8FC;
    border-radius: 10px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .test-question .option-wrap {
        width: 880px;
        height: 140px;
    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .test-question .option-wrap {
        width: 980px;
        height: 140px;
    }
}

.test-question .option-wrap .badge {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translateX(-50%);
    line-height: 32px;
    display: block;
    border: 1px solid #DCDDE1;
    border-radius: 16px;
    text-align: center;
    color: #6b6c6e;
    font-size: 16px;
    font-weight: 500;
    padding: 0 32px;
    background-color: #f7f8fc;
}

.test-question .option-wrap .sorting-card .s-card {
    background-color: #fff;
    cursor: pointer;
    border: 2px solid #15a6ca;
    color: #222222;
}

.test-question .option-wrap .sorting-card .s-card.empty {
    color: #d5d6da;
    border-style: dashed;
    cursor: default;
}

.test-question .option-wrap.by5 {
    padding-top: 48px;
    padding-bottom: 48px;
    height: auto;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .test-question .option-wrap.by5 {
        padding-top: 30px;
        padding-bottom: 20px;
    }
}

.test-question .option-wrap.by5 .sorting-card {
    padding-left: 72px;
    padding-right: 72px;
    flex-wrap: wrap;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .test-question .option-wrap.by5 .sorting-card {
        padding-left: 40px;
        padding-right: 40px;
    }
}

.test-question .option-wrap.by5 .sorting-card .s-card {
    margin-bottom: 8px;
}

.test-question .option-wrap.style2 {
    width: 882.4px;
    height: auto;
    flex-wrap: wrap;
    border: 0 none;
    box-shadow: none;
}

.test-question .option-wrap.style2 .sorting-card {
    padding: 28.8px 72px;
    flex-wrap: wrap;
    justify-content: left;
}

.test-question .option-wrap.style2 .sorting-card .s-card {
    min-width: 142.4px;
    height: 59.2px;
    margin-bottom: 8px;
    margin-left: 2px;
    margin-right: 2px;
}

.test-question .my-answer-list li {
    display: block;
    text-align: center;
    color: #555555;
    font-size: 28px;
    font-weight: 400;
    line-height: 1.5em;
}

.test-question .my-answer-list li strong {
    color: #00759c;
    font-size: 28.8px;
    font-weight: 500;
}

@media screen and (max-width: 1199px){

    .test-question {
        position: relative;
        width: 100%;
        margin-top:initial;
    }

    .test-question .test-header {
        position: relative;
        margin: 0 0 .41667rem
    }

    .test-question .test-header .tit {
        color: #222;
        font-size: 1.125rem;
        text-align: center;
        font-weight: 500
    }

    .test-question .test-header .tit strong {
        color: #00759c;
        font-weight: 500
    }

    .test-question .test-header .btn-play-sound {
        margin: 0 auto;
        display: block;
        background-color: #fff
    }

    .test-question .sorting-card {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center
    }

    .test-question .sorting-card .s-card {
        margin: 0 5px
    }

    .test-question .s-card {
        position: relative;
        width: 4.58333rem;
        height: 1.8125rem;
        display: block;
        border-radius: 6px;
        font-size: .9375rem;
        font-weight: 500;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #fff;
        border: 2px solid #15a6ca;
        color: #222;
        min-width:initial;
    }

    .test-question .s-card .btn-remove-card {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        position: absolute;
        display: none;
        width: .75rem;
        height: .75rem;
        text-indent: -9999px;
        border: 0 none;
        padding: 0;
        top: -.375rem;
        left: 50%;
        transform: translateX(-50%);
        background-color: transparent;
        background-image: url(../images/buttons/btn-remove-card.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100% 99%
    }

    .test-question .s-card .btn-remove-card.disabled,
    .test-question .s-card .btn-remove-card:disabled {
        opacity: .65
    }

    .test-question .s-card .btn-remove-card:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .test-question.small-card .s-card {
        width: 2.04167rem;
        height: 2.04167rem;
        min-width: initial;
    }

    .test-question .answer-wrap {
        height: 4.16667rem;
        width: 100%;
        background: #fff;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border:none;
        border-radius:0;

    }

    .test-question .answer-wrap.border-none {
        background-color: transparent;
        border: 0 none
    }

    .test-question .answer-wrap.style2 {
        height: auto;
        padding: 1rem;
        flex-wrap: wrap;
        justify-content: left;
        background: #f7f8fc;
        border: 1px solid #dcdde1;
        border-radius: 10px;
        width: 100%;
        box-shadow: inset 0 5px 8px 0 rgba(0, 0, 0, .07);
        min-height: 25vh;
    }

    .test-question .answer-wrap.style2 .sorting-card {
        width: 100%;
        flex-wrap: wrap
    }

    .test-question .answer-wrap.style2 .sorting-card .s-card {
        width: 4.58333rem;
        height: 1.77083rem;
        margin: 0 2px 5px;
        min-width: initial;
    }

    .test-question .answer-wrap.style2 .sorting-card .s-card:last-child .btn-remove-card {
        display: block
    }

    .test-question .answer-wrap .btn-play-sound {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 100%;
        border: none;
        z-index: 10;
        color: #fff;
        background-color: #fff;
        -webkit-border-radius: 1.45833rem;
        -moz-border-radius: 1.45833rem;
        border-radius: 1.45833rem
    }

    .test-question .answer-wrap .sorting-card .s-card.empty {
        background-color: #f6f6f8;
        border: 2px dotted #88898b
    }

    .test-question .answer-wrap .sorting-card .s-card.wrong {
        background-color: #ffe0bf;
        color: #ff5400;
        border: 2px solid #ff8400
    }

    .test-question .answer-wrap .sorting-card .s-card.wrong .btn-remove-card {
        display: block
    }

    .test-question .answer-wrap .sorting-card .s-card.correct {
        border: 2px solid #15a6ca;
        background-color: #15a6ca;
        color: #fff
    }

    .test-question .answer-wrap .sorting-card .plain {
        color: #222;
        font-size: .9375rem;
        font-weight: 500;
        margin: 0 10px
    }

    .test-question .option-wrap {
        box-shadow: inset 0 5px 8px 0 rgba(0, 0, 0, .04);
        height: 100%;
        width: 100%;
        background-color: #f7f8fc;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        border:none;
        border-radius:0;
    }

    .test-question .option-wrap .badge {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);
        line-height: 32px;
        display: block;
        border: 1px solid #dcdde1;
        border-radius: 16px;
        text-align: center;
        color: #6b6c6e;
        font-size: 16px;
        font-weight: 500;
        padding: 0 32px;
        background-color: #f7f8fc
    }

    .test-question .option-wrap .sorting-card .s-card {
        background-color: #fff;
        cursor: pointer;
        border: 2px solid #15a6ca;
        color: #222
    }

    .test-question .option-wrap .sorting-card .s-card.empty {
        color: #d5d6da;
        border-style: dashed;
        cursor: default
    }

    .test-question .option-wrap.by5 {
        padding-top: 30px;
        padding-bottom: 30px;
        height: auto
    }

    .test-question .option-wrap.by5 .sorting-card {
        padding-left: 72px;
        padding-right: 72px;
        flex-wrap: wrap
    }

    .test-question .option-wrap.by5 .sorting-card .s-card {
        margin-bottom: 8px
    }

    .test-question .option-wrap.style2 {
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        border: 0 none;
        box-shadow: none
    }

    .test-question .option-wrap.style2 .sorting-card {
        padding: 2.75rem 10rem 2rem 10rem;
        flex-wrap: wrap;
        justify-content: left
    }

    .test-question .option-wrap.style2 .sorting-card .s-card {
        width: 4.58333rem;
        height: 1.77083rem;
        margin-bottom: 8px;
        margin-left: 2px;
        margin-right: 2px;
        min-width: initial;
    }

    .test-question .my-answer-list li {
        display: block;
        text-align: center;
        color: #555;
        font-size: 1.0rem;
        font-weight: 400;
        line-height: 1.5em
    }

    .test-question .my-answer-list li strong {
        color: #00759c;
        font-size: 1.2rem;
        font-weight: 500
    }

}


.conversation-practice {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .conversation-practice {
        padding-top:20px;
    }
}

.conversation-practice .line-canvas {
    position: relative;
    width: 115px;
    margin-left: -10px;
    margin-right: -10px;
    z-index: 1;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .conversation-practice .line-canvas {
        width: 85px;
    }
}

.conversation-practice ul {
    position: relative;
    z-index: 2;
}

.conversation-practice ul li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    margin: 0 0 33px;
    justify-content: flex-end;
}

.conversation-practice ul li .text {
    background: transparent;
    width: 520px;
    min-height: 74px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    color: #222222;
    font-size: 30px;
    font-weight: 500;
    border-radius: 6px;
    border: 2px dotted #15a6ca;
    padding: 10px 35px;
    cursor: pointer;
    line-height: 1.3;
}
@media screen and (min-width: 1200px) and (max-width: 1400px) {
    .conversation-practice ul li .text {
        width: 340px;
        min-height: 52px;
        font-size: 20px;
        padding: 10px 20px;
        font-weight:500;
    }
}
@media screen and (min-width: 1401px) and (max-width: 1600px) {
    .conversation-practice ul li .text {
        width: 400px;
        min-height: 52px;
        -webkit-display: flex;
        font-size: 26px;
        padding: 10px 25px;
    }
}



.conversation-practice ul li .btn-play-sound {
    margin: 0 10px;
}

.conversation-practice ul li .dot {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    display: block;
    border: 5px solid #bcbcbc;
    background-color: #bcbcbc;
}

.conversation-practice ul li.selected .text {
    border-style: solid;
    background-color: #15a6ca;
    color: #fff;
}

.conversation-practice ul li.selected .text {
    cursor: default;
}

.conversation-practice ul li.selected .dot {
    border-color: #00759c;
    background-color: #fff;
}

.conversation-practice ul li.disabled .text {
    color: #d5d6da;
    border-color: #b6b6b6;
    border-style: dotted;
}

.conversation-practice ul.left-list {
    justify-self: flex-start;
}

.conversation-practice ul.right-list {
    justify-self: flex-end;
}

.conversation-practice ul.right-list li {
    flex-direction: row-reverse;
}

@media screen and (max-width: 1199px){

    .conversation-practice {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex
    }

    .conversation-practice .line-canvas {
        position: relative;
        width: 115px;
        margin-left: -10px;
        margin-right: -10px;
        z-index: 1
    }

    .conversation-practice ul {
        position: relative;
        z-index: 2
    }

    .conversation-practice ul li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin: .83333rem 0
    }

    .conversation-practice ul li .text {
        background: 0 0;
        width: 10.83333rem;
        height: 2.08333rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        color: #222;
        font-size: .875rem;
        font-weight: 500;
        border-radius: 6px;
        border: 2px dotted #15a6ca;
        padding: 1.04167rem .20833rem;
        cursor: pointer;
        min-height:initial;
    }

    .conversation-practice ul li .btn-play-sound {
        margin: 0 10px;
        background-color: #fff
    }

    .conversation-practice ul li .dot {
        width: .41667rem;
        height: .41667rem;
        border-radius: 100%;
        display: block;
        border: 5px solid #bcbcbc;
        background-color: #bcbcbc
    }

    .conversation-practice ul li.selected .text {
        border-style: solid;
        background-color: #15a6ca;
        color: #fff
    }

    .conversation-practice ul li.selected .text {
        cursor: default
    }

    .conversation-practice ul li.selected .dot {
        border-color: #00759c;
        background-color: #fff
    }

    .conversation-practice ul li.disabled .text {
        color: #d5d6da;
        border-color: #b6b6b6;
        border-style: dotted
    }

    .conversation-practice ul.left-list {
        justify-self: flex-start
    }

    .conversation-practice ul.right-list {
        justify-self: flex-end
    }

    .conversation-practice ul.right-list li {
        flex-direction: row-reverse
    }

}


.alert-quiz, .alert-correct, .alert-wrong, .alert-overlap {
    display: none;
    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.alert-quiz.display, .alert-correct.display, .alert-wrong.display, .alert-overlap.display{
    display: block;
}

.alert-quiz .mask, .alert-correct .mask, .alert-wrong .mask , .alert-overlap .mask{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 1001;
    display: block;
    background: transparent;
}

.alert-quiz .alert-wrap, .alert-correct .alert-wrap, .alert-wrong .alert-wrap, .alert-overlap .alert-wrap {
    padding: 33.6px 40px 28px;
    width: 450.4px;
    z-index: 1002;
    position: absolute;
    left: 50%;
    transform: translateX(calc(-50% - 133px));
    bottom: 200px;
    background-color: #ff5a00;
    border-radius: 80px;
}

.alert-quiz .alert-wrap:before, .alert-correct .alert-wrap:before, .alert-wrong .alert-wrap:before, .alert-overlap .alert-overlap:before {
    position: absolute;
    content: '';
    display: block;
    left: 50%;
    transform: translateX(-50%);
    top: -40px;
    width: 108px;
    height: 108px;
    border-radius: 100%;
    background-color: #ff5a00;
    z-index: -1;
    background-image: url(../images/icons/pop-wrong-icon.png);
    background-repeat: no-repeat;
    background-size: 72.8px 80.8px;
    background-position: 25.6px 19.2px;
}

.alert-quiz .alert-wrap .tit, .alert-quiz .alert-wrap .tit1, .alert-quiz .alert-wrap .tit2, .alert-correct .alert-wrap .tit, .alert-correct .alert-wrap .tit1, .alert-correct .alert-wrap .tit2, .alert-wrong .alert-wrap .tit, .alert-wrong .alert-wrap .tit1, .alert-wrong .alert-wrap .tit2, .alert-overlap .alert-wrap .tit, .alert-overlap .alert-wrap .tit1, .alert-overlap .alert-wrap .tit2 {
    color: #fff;
    text-align: center;
    line-height: 1.5em;
}

.alert-quiz .alert-wrap .tit, .alert-correct .alert-wrap .tit, .alert-wrong .alert-wrap .tit , .alert-overlap .alert-wrap .tit {
    font-size: 30px;
    font-weight: 700;
    margin-bottom: 10px;
}

.alert-quiz .alert-wrap .tit1, .alert-correct .alert-wrap .tit1, .alert-wrong .alert-wrap .tit1 , .alert-overlap .alert-wrap .tit1{
    font-size: 26px;
    font-weight: 500;
}

.alert-quiz .alert-wrap .tit2, .alert-correct .alert-wrap .tit2, .alert-wrong .alert-wrap .tit2, .alert-overlap .alert-wrap .tit2 {
    font-size: 22px;
    font-weight: 400;
}

.alert-quiz .alert-wrap p, .alert-correct .alert-wrap p, .alert-wrong .alert-wrap p , .alert-overlap .alert-wrap p{
    color: #ffffff;
    font-size: 19.2px;
    font-weight: 500;
    letter-spacing: -.02em;
    line-height: 1.41667em;
    text-align: center;
}

.alert-quiz.relative, .alert-correct.relative, .alert-wrong.relative , .alert-overlap.relative{
    position: relative;
    top: auto;
    left: auto;
    z-index: 11;
    right: auto;
    bottom: auto;
}

.alert-quiz.relative .mask, .alert-correct.relative .mask, .alert-wrong.relative .mask, .alert-overlap.relative .mask {
    width: 0;
    height: 0;
    display: none;
}

.alert-quiz.relative .alert-wrap, .alert-correct.relative .alert-wrap, .alert-wrong.relative .alert-wrap , .alert-overlap.relative .alert-wrap{
    top: auto;
    bottom: auto;
    left: auto;
    right: auto;
    transform: none;
    position: relative;
}

.alert-correct .alert-wrap {
    background-color: #0cb4f2;
}

.alert-correct .alert-wrap:before {
    background-image: url(../images/icons/pop-correct-icon.png);
    background-color: #0cb4f2;
    background-position: center center;
}

.alert-quiz .alert-wrap {
    background-color: #7fb419;
}

.alert-quiz .alert-wrap:before {
    background-image: url(../images/icons/pop-quiz-icon.png);
    background-color: #7fb419;
}

@media screen and (max-width: 1199px){

    .alert-correct,
    .alert-quiz,
    .alert-wrong,
    .alert-overlap{
        display: none;
        position: fixed;
        z-index: 1000;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0
    }

    .alert-correct.display,
    .alert-quiz.display,
    .alert-wrong.display,
    .alert-overlap.display{
        display: block
    }

    .alert-correct .mask,
    .alert-quiz .mask,
    .alert-wrong .mask,
    .alert-overlap .mask{
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        right: 0;
        z-index: 1001;
        display: block;
        background: 0 0
    }

    .alert-correct .alert-wrap,
    .alert-quiz .alert-wrap,
    .alert-wrong .alert-wrap,
    .alert-overlap .alert-wrap{
        padding: 1.83333rem 2.5rem;
        width: 14.58333rem;
        z-index: 1002;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 4.16667rem;
        background-color: #ff5a00;
        border-radius: 2.5rem
    }

    .alert-correct .alert-wrap:before,
    .alert-quiz .alert-wrap:before,
    .alert-wrong .alert-wrap:before,
    .alert-overlap .alert-wrap:before{
        position: absolute;
        content: '';
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: -1.83333rem;
        width: 3.66667rem;
        height: 3.66667rem;
        border-radius: 100%;
        background-color: #ff5a00;
        z-index: -1;
        background-image: url(../images/icons/pop-wrong-icon.png);
        background-repeat: no-repeat;
        background-size: 2.5rem 2.5rem;
        background-position: .83333rem .83333rem
    }

    .alert-correct .alert-wrap .tit,
    .alert-correct .alert-wrap .tit1,
    .alert-correct .alert-wrap .tit2,
    .alert-quiz .alert-wrap .tit,
    .alert-quiz .alert-wrap .tit1,
    .alert-quiz .alert-wrap .tit2,
    .alert-wrong .alert-wrap .tit,
    .alert-wrong .alert-wrap .tit1,
    .alert-wrong .alert-wrap .tit2,
    .alert-overlap .alert-wrap .tit,
    .alert-overlap .alert-wrap .tit1,
    .alert-overlap .alert-wrap .tit2{
        color: #fff;
        text-align: center;
        line-height: 1.5em
    }

    .alert-correct .alert-wrap .tit,
    .alert-quiz .alert-wrap .tit,
    .alert-wrong .alert-wrap .tit,
    .alert-overlap .alert-wrap .tit{
        font-size: .9375rem;
        font-weight: 700;
        margin-bottom: 10px
    }

    .alert-correct .alert-wrap .tit1,
    .alert-quiz .alert-wrap .tit1,
    .alert-wrong .alert-wrap .tit1,
    .alert-overlap .alert-wrap .tit1{
        font-size: .875rem;
        font-weight: 500
    }

    .alert-correct .alert-wrap .tit2,
    .alert-quiz .alert-wrap .tit2,
    .alert-wrong .alert-wrap .tit2,
    .alert-overlap .alert-wrap .tit2{
        font-size: .6875rem;
        font-weight: 400
    }

    .alert-correct .alert-wrap p,
    .alert-quiz .alert-wrap p,
    .alert-wrong .alert-wrap p,
    .alert-overlap .alert-wrap p{
        color: #fff;
        font-size: .75rem;
        font-weight: 500;
        letter-spacing: -.02em;
        line-height: 1.41667em;
        text-align: center
    }

    .alert-correct.relative,
    .alert-quiz.relative,
    .alert-wrong.relative,
    .alert-overlap.relative{
        position: relative;
        top: auto;
        left: auto;
        z-index: auto;
        right: auto;
        bottom: auto
    }

    .alert-correct.relative .mask,
    .alert-quiz.relative .mask,
    .alert-wrong.relative .mask,
    .alert-overlap.relative .mask{
        width: 0;
        height: 0;
        display: none
    }

    .alert-correct.relative .alert-wrap,
    .alert-quiz.relative .alert-wrap,
    .alert-wrong.relative .alert-wrap,
    .alert-overlap.relative .alert-wrap{
        top: auto;
        bottom: auto;
        left: auto;
        right: auto;
        transform: none;
        position: relative
    }

    .alert-correct .alert-wrap {
        background-color: #0cb4f2
    }

    .alert-correct .alert-wrap:before {
        background-image: url(../images/icons/pop-correct-icon.png);
        background-color: #0cb4f2;
        background-position: center center
    }

    .alert-quiz .alert-wrap {
        background-color: #7fb419
    }

    .alert-quiz .alert-wrap:before {
        background-image: url(../images/icons/pop-quiz-icon.png);
        background-color: #7fb419
    }
}



.l2-rest .question {
    color: #222222;
    font-size: 60px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 70px;
    max-width: 1100px;
    word-break: keep-all;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l2-rest .question {
        font-size: 52px;
    }
}

.l2-rest .option-wrap {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
}

.l2-rest .option-wrap li {
    margin: 0 30px;
    display: block;
}

.option-wrap .option {
    display: block;
}

.option-wrap .option input[type="radio"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.option-wrap .option input[type="radio"] + span:before {
    content: '';
    display: block;
    width: 93px;
    height: 93px;
    background-color: transparent;
    background-image: url("../images/common/icon-speak-big.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.option-wrap .option input[type="radio"]:checked + span:before {
    background-color: transparent;
    background-image: url("../images/common/icon-checked-big.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

@media screen and (max-width: 1199px){

    .l2-rest .question {
        color: #222;
        font-size: 2.08333rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.45833rem
    }

    .l2-rest .option-wrap {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center
    }

    .l2-rest .option-wrap li {
        margin: 0 .83333rem;
        display: block
    }

    .option-wrap .option {
        display: block
    }

    .option-wrap .option input[type=radio] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0
    }

    .option-wrap .option input[type=radio]+span:before {
        content: '';
        display: block;
        margin: 0 auto;
        width: 1.9375rem;
        height: 1.9375rem;
        background-color: transparent;
        background-image: url(../images-m/common/icon-speak-big.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 1.9375rem 1.9375rem
    }

    .option-wrap .option input[type=radio]:checked+span:before {
        background-color: transparent;
        background-image: url(../images-m/common/icon-checked-big.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }

}


.talk-list .block .chat-bubble.primary {
    background-color: #37bce9;
}

.talk-list .block .chat-bubble.primary h2 {
    color: #fff;
}

.talk-list .block .chat-bubble.primary .guide {
    color: #fff;
}

.talk-list.talk-list-2 .block .chat-bubble h2 {
    font-size: 42.4px;
    font-weight: 500;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .talk-list.talk-list-2 .block .chat-bubble h2 {
        font-size: 34px;
    }
}

.talk-list.talk-list-2 .block.right-block .chat-bubble {
    min-width: 400px;
    min-height: 84px;
}

.talk-list.talk-list-2 .block.right-block .chat-bubble .guide {
    font-size: 32px;
    font-weight: 500;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .talk-list.talk-list-2 .block.right-block .chat-bubble .guide {
        font-size: 24px;
    }
}


@media screen and (max-width: 1199px){

    .talk-list.talk-list-2{
      background-color:initial;
    }

    .view-section .talk-list .block .chat-bubble {
        min-width: initial;
        min-height: initial;
    }


    .talk-list.talk-list-2 .block .chat-bubble h2 {
        font-size: .8125rem;
        font-weight: 500
    }
    .talk-list.talk-list-2 .block.right-block .chat-bubble {
        min-width: initial;
        min-height:initial;

    }

    .talk-list.talk-list-2 .block.right-block .chat-bubble.primary:after {
        background-image: url(../images-m/lecture/bubble-arrow-right-b-m.png);
        background-size: .5rem .5rem;
        background-position-x: center;
        background-position-y: center;
        background-repeat: no-repeat
    }

    @media all and (-webkit-min-device-pixel-ratio:1.5),
    all and (-o-min-device-pixel-ratio:3 / 2),
    all and (min--moz-device-pixel-ratio:1.5),
    all and (min-device-pixel-ratio:1.5) {
        .talk-list.talk-list-2 .block.right-block .chat-bubble.primary:after {
            background-image: url(../images-m/lecture/bubble-arrow-right-b-m@2x.png)
        }
    }

    @media all and (-webkit-min-device-pixel-ratio:2.5),
    all and (-o-min-device-pixel-ratio:5 / 2),
    all and (min--moz-device-pixel-ratio:2.5),
    all and (min-device-pixel-ratio:2.5) {
        .talk-list.talk-list-2 .block.right-block .chat-bubble.primary:after {
            background-image: url(../images-m/lecture/bubble-arrow-right-b-m@3x.png)
        }
    }

    .talk-list.talk-list-2 .block.right-block .chat-bubble .guide {
        font-size: .8125rem;
        font-weight: 500
    }
}



.top-score {
    position: absolute;
    top: 28px;
    right: 72px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:2;
}

.top-score .star-info {
    padding-left: 12.8px;
    vertical-align: middle;
    color: #626262;
    font-size: 12.8px;
    line-height: 14.4px;
    margin-right: 17.6px;
    position: relative;
}

.top-score .star-info:before {
    content: '';
    width: 5.6px;
    height: 5.6px;
    border-radius: 100%;
    background-color: #15a6ca;
    display: block;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}

.top-score .star-info:before .star-info {
    padding-left: 12.8px;
    vertical-align: middle;
    color: #626262;
    font-size: 12.8px;
}

.top-score .star-info:before .star-info strong {
    color: #ff6600;
    font-weight: 700;
}

.top-score .star-info strong {
    color: #ff6600;
    font-weight: 700;
}

.top-score .star {
    display: block;
    width: 34.4px;
    height: 32px;
    background-size: 100%;
}

.top-score .star + .star {
    margin-left: 7px;
}

.top-score .star.star-empty {
    background-color: transparent;
    background-image: url("../images/icons/star-empty.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.top-score .star.star-score {
    background-color: transparent;
    background-image: url("../images/icons/star-score.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

@media screen and (max-width: 1199px){

    .top-score {
        position: absolute;
        top: .83333rem;
        right: 1.875rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 10
    }

    .top-score .star-info {
        padding-left: 12.8px;
        vertical-align: middle;
        color: #626262;
        font-size: 12.8px;
        line-height: 14.4px;
        margin-right: 17.6px;
        position: relative
    }

    .top-score .star-info:before {
        content: '';
        width: 5.6px;
        height: 5.6px;
        border-radius: 100%;
        background-color: #15a6ca;
        display: block;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%)
    }

    .top-score .star-info:before .star-info {
        padding-left: 12.8px;
        vertical-align: middle;
        color: #626262;
        font-size: 12.8px
    }

    .top-score .star-info:before .star-info strong {
        color: #f60;
        font-weight: 700
    }

    .top-score .star-info strong {
        color: #f60;
        font-weight: 700
    }

    .top-score .star {
        display: block;
        width: .83333rem;
        height: .83333rem;
        background-size: 100%
    }

    .top-score .star+.star {
        margin-left: .14583rem
    }

    .top-score .star.star-empty {
        background-color: transparent;
        background-image: url(../images/icons/star-empty.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }

    .top-score .star.star-score {
        background-color: transparent;
        background-image: url(../images/icons/star-score.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }
}


.five-game {
    width: 707.2px;
    padding-top: 30px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game {
        padding-top: 0;
        padding-bottom: 60px;
    }
}

.five-game .tit {
    color: #222222;
    font-size: 58.4px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 40px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game .tit {
        font-size: 38px;
        margin-bottom: 20px;
    }

}

.five-game > ul li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    min-height: 58.4px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game > ul li {
        margin-bottom: 4px;
        min-height: 48px;
    }
}

.five-game > ul li .num {
    display: block;
    width: 44px;
    text-align: right;
    color: #222222;
    font-size: 24px;
    font-weight: 500;
    margin-right: 18.4px;
}

.five-game > ul li .hint {
    display: none;
    padding: 10.4px 27.2px;
    line-height: 1.4em;
    color: #fff;
    background-color: #15a6ca;
    border-radius: 10px;
    font-size: 25.6px;
    max-width: 584px;
    letter-spacing: -0.04em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game > ul li .hint {
        padding: 10.4px 27.2px;
        line-height: 1.2em;
        font-size: 22px;
    }
}

.five-game > ul li .hint small {
    font-size: 19.2px;
    font-weight: 500;
}

.five-game > ul li.tip-opened .hint {
    display: block;
}

.five-game > ul li.tip-opened .btn-use-hint {
    display: none;
}

.five-game .input-answer {
    border: 2px solid #BFC0C2;
    border-radius: 5px;
    background-color: #F3F4F7;
    box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.07);
    display: block;
    width: 100%;
    text-align: center;
    font-size: 22.4px;
    font-weight: 400;
    padding: 28.8px 0;
    margin-top:60px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game .input-answer {
        width: 600px;
        margin:0 auto;
        font-size: 18px;
        font-weight: 400;
        padding: 12px 0;
        margin-top:20px;
    }
}

.five-game .input-answer::placeholder {
    color: #949494;
}

.five-game-result {
    width: 456.8px;
    height: 192.8px;
    background-color: transparent;
    background-image: url("../images/lecture/bg-correct.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    padding: 28px 0 0;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game-result {
        width:350px;
        height:110px;
        min-height:110px;
        padding: 28px 0 0;
        background-size: auto 120px;
        background-position-y: 0px;
    }
}

.five-game-result h2, .five-game-result p {
    color: #fff;
    text-align: center;
}

.five-game-result h2 {
    font-size: 24px;
    font-weight: 700;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game-result h2 {
        font-size: 15px;
        font-weight: 500;
    }
}

.five-game-result p {
    font-size: 26px;
    font-weight: 500;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .five-game-result p {
        font-size: 22px;
        transform:translateY(-7px);
    }
}

@media screen and (max-width: 1199px){

    .five-game {
        width: 100%;
        margin-top: 2.08333rem;
        padding-top: 0;
    }

    .five-game .tit {
        color: #222;
        font-size: 1.66667rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 0;
    }

    .five-game>ul {
        width: 60%;
        margin: 0 auto
    }

    .five-game>ul li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        margin-bottom: .425rem;
        min-height: 1.875rem;
        position: relative
    }

    .five-game>ul li .num {
        display: block;
        width: 1.25rem;
        text-align: right;
        color: #222;
        font-size: .625rem;
        font-weight: 500;
        margin-right: .625rem
    }

    .five-game>ul li .hint {
        display: none;
        padding: .375rem .5rem;
        line-height: 1.4em;
        color: #fff;
        background-color: #15a6ca;
        border-radius: 10px;
        font-size: .875rem;
        max-width: 28rem;
        letter-spacing: -.04em;
        position: relative;
        left: 0;
        top: 0
    }

    .five-game>ul li .hint small {
        font-size: .75rem;
        font-weight: 500
    }

    .five-game>ul li.tip-opened .hint {
        display: block
    }

    .five-game>ul li.tip-opened .btn {
        display: none
    }

    .five-game .input-answer {
        border: 2px solid #bfc0c2;
        border-radius: 5px;
        background-color: #f3f4f7;
        box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, .07);
        display: block;
        width: 50%;
        text-align: center;
        font-size: .9375rem;
        font-weight: 400;
        padding: 0.5rem;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        margin:0 auto;

    }

    .five-game .input-answer::placeholder {
        color: #949494
    }

    .five-game-result {
        width: 15.52083rem;
        height: 7.97917rem;
        background-color: transparent;
        background-image: url(../images-m/lecture/bg-correct.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        padding: 2.08333rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column
    }

    .five-game-result h2,
    .five-game-result p {
        color: #fff;
        text-align: center
    }

    .five-game-result h2 {
        margin-top: 1.04167rem;
        font-size: 1rem;
        font-weight: 700
    }

    .five-game-result p {
        font-size: .875rem;
        font-weight: 500
    }
}



.result-list2 {
    min-width: 885px;
}

.result-list2 li {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border: 2px solid #DCDDE1;
    background: transparent;
    border-radius: 10px;
    margin-bottom: -2px;
    width: 100%;
    z-index: 1;
    position: relative;
    min-height: 94px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .result-list2 {
        padding-bottom: 40px;
    }

    .result-list2 li {
        border-radius: 10px;
        margin-bottom: -2px;
        width: 100%;
        z-index: 1;
        min-height: 68px;
    }
}

.result-list2 li .word {
    color: #222222;
    font-size: 24px;
    font-weight: 500;
    line-height: 1.5em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .result-list2 li .word {
        font-size: 22px;
    }
}

.result-list2 li .txt {
    color: #838383;
    font-size: 18px;
    font-weight: 500;
    line-height: 1.5em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .result-list2 li .txt {
        font-size: 16px;
        font-weight: 400;
    }
}

.result-list2 li.selected {
    background-color: #15a6ca;
    border-color: #15a6ca;
    z-index: 2;
}

.result-list2 li.selected .word, .result-list2 li.selected .txt {
    color: #fff;
}


@media screen and (max-width: 1199px){

    .result-list2 {
        max-width: 31.25rem;
        min-width: initial;
    }

    .result-list2 li {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 2px solid #dcdde1;
        background: 0 0;
        border-radius: 10px;
        margin-bottom: -2px;
        width: 100%;
        z-index: 1;
        position: relative;
        min-height: 4.16667rem;
        padding: 0 1.25rem
    }

    .result-list2 li .word {
        color: #222;
        font-size: .9375rem;
        font-weight: 500;
        line-height: 1.5em
    }

    .result-list2 li .txt {
        color: #838383;
        font-size: .75rem;
        font-weight: 500;
        line-height: 1.5em
    }

    .result-list2 li.selected {
        background-color: #15a6ca;
        border-color: #15a6ca;
        z-index: 2
    }

    .result-list2 li.selected .txt,
    .result-list2 li.selected .word {
        color: #fff
    }
}


.star-achieve {
    position: absolute;
    top: 40px;
    right: 72px;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    padding: 14px 57px;
    border: 2px solid #00759C;
    box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.09);
    background-color: #fff;
    border-radius: 32px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .star-achieve {
        position: absolute;
        top: 30px;
        right: 50px;
        padding: 10px 40px;
        border: 2px solid #00759C;
        box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.09);
        background-color: #fff;
        border-radius: 32px;
    }
}

/*.star-achieve {*/
/*display: inline-block;*/
/*margin: 0;*/
/*font-weight: normal;*/
/*text-align: center;*/
/*white-space: nowrap;*/
/*user-select: none;*/
/*text-decoration: none;*/
/*outline: 0;*/
/*vertical-align: middle;*/
/*display: block;*/
/*margin: 0 auto;*/
/*padding: 14px 57px;*/
/*border: 2px solid #00759C;*/
/*box-shadow: 0px 7px 4px 0px rgba(0, 0, 0, 0.09);*/
/*background-color: #fff;*/
/*border-radius: 32px;*/
/*}*/

.star-achieve:disabled, .star-achieve.disabled {
    opacity: 0.65;
}

.star-achieve:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.star-achieve .achieve-message {
    font-size: 16px;
    text-align: center;
    font-weight: 500;
    color: #00759c;
}

@media screen and (max-width: 1199px){


    .star-achieve {
        position: initial;
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        display: block;
        margin: 0 auto;
        padding: .83333rem 2.5rem;
        border: 2px solid #00759c;
        box-shadow: 0 7px 4px 0 rgba(0, 0, 0, .09);
        background-color: #fff;
        border-radius: 3.33333rem;
        min-width: 11.875rem
    }

    .star-achieve.disabled,
    .star-achieve:disabled {
        opacity: .65
    }

    .star-achieve:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .star-achieve .achieve-message {
        font-size: 1rem;
        text-align: center;
        font-weight: 500;
        color: #00759c
    }
}



.l3-conversation .question {
    color: #222222;
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    margin-top: 40px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l3-conversation .question {
        font-size: 36px;
        font-weight: 500;
        margin-top: 15px;
    }
}

.l3-conversation .question .q {
    display: inline-block;
    color: #f63d4d;
    font-weight: 700;
    font-size: 59.2px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l3-conversation .question .q {
        font-weight: 600;
        font-size: 48px;
    }
}

.l3-conversation .answer-header {
    /*width: 888px;*/
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 30.4px;
}

.l3-conversation .answer-wrap {
    width: 1100px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    margin-bottom: 40px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l3-conversation .answer-wrap {
        width: 900px;
        margin-top:20px;
    }

    .l3-conversation .answer-header {
        margin-bottom: 0;
    }
}

.l3-conversation .answer-wrap .answer {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    border: 2px solid #00759C;
    background-color: #fff;
    position: relative;
    padding: 61.6px 39.2px;
    border-radius: 16px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .l3-conversation .answer-wrap .answer {
        padding:38px 32px 4px 32px;
    }
}

.l3-conversation .answer-wrap .answer:before {
    content: '';
    display: block;
    position: absolute;
    width: 278.4px;
    height: 57.6px;
    top: -5.6px;
    left: 50%;
    transform: translateX(-50%);
    background-size: 100%;
}

.l3-conversation .answer-wrap .answer + .answer {
    margin-left: -2px;
}

.l3-conversation .answer-wrap .answer.my-answer:before {
    background-image: url(../images/lecture/bg-my-answer-wrap.png);
}

.l3-conversation .answer-wrap .answer.correct-answer:before {
    background-image: url(../images/lecture/bg-correct-answer-wrap.png);
}


.l3-conversation .answer-wrap .answer ul li {
    padding: 14.4px 0;
    letter-spacing: -0.04em;
    font-size: 19.2px;
    color: #222222;
    font-weight: 500;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l3-conversation .answer-wrap .answer.my-answer:before {
        background-image: url(../images/lecture/bg-my-answer-wrap.png);
        width:50%;
        height: 50%;
        background-repeat: no-repeat;
    }

    .l3-conversation .answer-wrap .answer.correct-answer:before {
        background-image: url(../images/lecture/bg-correct-answer-wrap.png);
        width:50%;
        height: 50%;
        background-repeat: no-repeat;
    }

    .l3-conversation .answer-wrap .answer ul li {
        padding: 8px 0;
        letter-spacing: -0.04em;
        font-size: 17px;
    }
}

.l3-conversation .answer-wrap .answer ul li + li {
    border-top: 2px dashed #dbdbdb;
}

.l3-conversation .answer-wrap .answer ul li .q, .l3-conversation .answer-wrap .answer ul li .a {
    padding-left: 36px;
    position: relative;
    font-weight: 700;
    line-height: 1.5em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .l3-conversation .answer-wrap .answer ul li .q, .l3-conversation .answer-wrap .answer ul li .a {
        font-weight: 500;
        padding-left: 28px;
    }
}

.l3-conversation .answer-wrap .answer ul li .q:before, .l3-conversation .answer-wrap .answer ul li .a:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}

.l3-conversation .answer-wrap .answer ul li .q:before {
    content: 'Q.';
    color: #f63d4d;
}

.l3-conversation .answer-wrap .answer ul li .a:before {
    content: 'A.';
    color: #00759c;
}

@media screen and (max-width: 1199px){

    .l3-conversation {
        margin: 1rem 0;
    }

    .l3-conversation .question {
        color: #222;
        font-size: 1.35417rem;
        font-weight: 700;
        text-align: center;
        margin-top: 10px;
    }

    .l3-conversation .question .q {
        display: inline-block;
        color: #f63d4d;
        font-weight: 700;
        font-size: 1.54167rem
    }

    .l3-conversation .answer-header {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-end;
        margin-bottom: .79167rem
    }

    .l3-conversation .answer-wrap {
        width: 100%;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        margin: 0 auto
    }

    .l3-conversation .answer-wrap .answer {
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        border: 2px solid #00759c;
        background-color: #fff;
        position: relative;
        padding: 1.60417rem 1.02083rem;
        border-radius: 16px
    }

    .l3-conversation .answer-wrap .answer:before {
        content: '';
        display: block;
        position: absolute;
        width: 9.95833rem;
        height: 1.91667rem;
        top: -.14583rem;
        left: 50%;
        transform: translateX(-50%);
        background-size: 100%
    }

    .l3-conversation .answer-wrap .answer+.answer {
        margin-left: -2px
    }

    .l3-conversation .answer-wrap .answer.my-answer:before {
        background-image: url(../images-m/lecture/bg-my-answer-wrap.png)
    }

    .l3-conversation .answer-wrap .answer.correct-answer:before {
        background-image: url(../images-m/lecture/bg-correct-answer-wrap.png)
    }

    .l3-conversation .answer-wrap .answer ul li {
        padding: 14.4px 0;
        letter-spacing: -.04em;
        font-size: .875rem;
        color: #222;
        font-weight: 500
    }

    .l3-conversation .answer-wrap .answer ul li+li {
        border-top: 2px dashed #dbdbdb
    }

    .l3-conversation .answer-wrap .answer ul li .a,
    .l3-conversation .answer-wrap .answer ul li .q {
        padding-left: .9375rem;
        position: relative;
        font-weight: 700;
        line-height: 1.5em
    }

    .l3-conversation .answer-wrap .answer ul li .a:before,
    .l3-conversation .answer-wrap .answer ul li .q:before {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%)
    }

    .l3-conversation .answer-wrap .answer ul li .q:before {
        content: 'Q.';
        color: #f63d4d
    }

    .l3-conversation .answer-wrap .answer ul li .a:before {
        content: 'A.';
        color: #00759c
    }
}


.l4-memorize h2 {
    color: #222222;
    font-size: 80px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 70px;
}

.l4-memorize .option-wrap {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
}

.l4-memorize .option-wrap .option {
    margin: 0 16px;
}

.l4-memorize .option-wrap .option input[type="radio"] + span {
    color: #222222;
    text-align: center;
    display: block;
}

.l4-memorize .option-wrap .option input[type="radio"] + span:before {
    background-color: transparent;
    background-image: url("../images/common/icon-checked-big-unchecked.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    margin-bottom: 12px;
}

.l4-memorize .option-wrap .option input[type="radio"]:checked + span:before {
    background-color: transparent;
    background-image: url("../images/common/icon-checked-big.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}
@media screen and (max-width: 1199px){

    .l4-memorize h2 {
        color: #222;
        font-size: 3.125rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.45833rem
    }

    .l4-memorize .option-wrap {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center
    }

    .l4-memorize .option-wrap .option {
        margin: 0 .41667rem
    }

    .l4-memorize .option-wrap .option input[type=radio]+span {
        color: #222;
        text-align: center;
        display: block
    }

    .l4-memorize .option-wrap .option input[type=radio]+span:before {
        background-color: transparent;
        background-image: url(../images-m/common/icon-checked-big-unchecked.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        margin-bottom: 12px
    }

    .l4-memorize .option-wrap .option input[type=radio]:checked+span:before {
        background-color: transparent;
        background-image: url(../images-m/common/icon-checked-big.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center
    }
}



.spell-practice {
    width: 100%;
    max-width: 1100px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    margin: 40px auto;

}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .spell-practice {
        width: 100%;
        max-width: 880px;
        margin: 20px auto;
    }
}

.spell-practice .grid {
    width: calc(50% - 6px);
    border: 2px solid #DCDDE1;
    border-radius: 10px;
    margin: 3px 3px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 200px;
}

.spell-practice .grid.row-3 {
    width: calc(33% - 6px);
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .spell-practice .grid {
        margin: 3px 3px;
        height: 150px;
    }
}

.spell-practice .grid .txt {
    text-align: center;
    font-size: 20.8px;
    font-weight: 400;
    color: #222222;
    margin-top: 8px;
}

.spell-practice .input-wrap {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
}

.spell-practice .input-wrap + .input-wrap {
    margin-top: 4.8px;
}

.spell-practice .input-wrap .input {
    border: 2px solid #BFC0C2;
    border-radius: 5px;
    background: #F3F4F7;
    line-height: 56.8px;
    width: 66.4px;
    text-align: center;
    font-weight: 500;
    font-size: 28.8px;
}

.spell-practice .input-wrap .input + .input {
    margin-left: 4.8px;
}

.spell-practice .input-wrap .input:focus {
    outline: none;
}

.spell-practice .input-wrap .input.correct {
    border-color: #15a6ca;
    background-color: #fff;
}

.spell-practice .input-wrap .input.wrong {
    border-color: #ff8400;
    color: #ff5400;
    background-color: #ffe0bf;
}

.spell-practice .input-wrap.small .input {
    width: 42.4px;
    line-height: 36.8px;
    font-size: 22.4px;
}

@media screen and (max-width: 1199px){

    .spell-practice {
        width: 100%;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 1.04167rem;
        margin:initial;
    }

    .spell-practice .grid {
        width: 20.83333rem;
        border: 2px solid #dcdde1;
        border-radius: 10px;
        margin: .33333rem;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        height: 8.95833rem
    }

    .spell-practice .grid .txt {
        text-align: center;
        font-size: 1.14583rem;
        font-weight: 400;
        color: #222;
        margin-top: 8px
    }

    .spell-practice .input-wrap {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center
    }

    .spell-practice .input-wrap+.input-wrap {
        margin-top: 4.8px
    }

    .spell-practice .input-wrap .input {
        border: 2px solid #bfc0c2;
        border-radius: 5px;
        background: #f3f4f7;
        line-height: 2.75rem;
        width: 2.95833rem;
        text-align: center;
        font-weight: 500;
        font-size: 1.14583rem
    }

    .spell-practice .input-wrap .input+.input {
        margin-left: .375rem
    }

    .spell-practice .input-wrap .input:focus {
        outline: 0
    }

    .spell-practice .input-wrap .input.correct {
        border-color: #15a6ca;
        background-color: #fff
    }

    .spell-practice .input-wrap .input.wrong {
        border-color: #ff8400;
        color: #ff5400;
        background-color: #ffe0bf
    }

    .spell-practice .input-wrap.small .input {
        width: 42.4px;
        line-height: 36.8px;
        font-size: 22.4px
    }

}


.test-question-result .result-box {
    width: 584px;
    height: 320px;
    position: relative;
    background-color: transparent;
    background-image: url("../images/lecture/bg-test-question-result.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 90%;
    padding-top: 120px;
    margin-bottom: 20px;
    margin-top: 30px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .test-question-result .result-box {
        width: auto;
        height: 280px;
        position: relative;
        background-color: transparent;
        background-image: url("../images/lecture/bg-test-question-result.png");
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 70%;
        padding-top: 100px;
        margin-bottom: 20px;
        margin-top: 0;
    }
}

.test-question-result .result-box h2 {
    color: #fff;
    font-size: 50px;
    font-weight: 500;
    text-align: center;
    line-height: 1.4em;
}

.test-question-result .result-box h4 {
    color: #fff;
    font-size: 30px;
    font-weight: 500;
    text-align: center;
    line-height: 55.2px;
}

.test-question-result .result-box .btn-play-sound {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.test-question-result .result-commentary {
    width: 600px;
    margin: 0 auto;
    border: 1px solid #D8D9DE;
    background-color: #F9F9FB;
    border-radius: 10px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .test-question-result .result-commentary {
        margin: 0 auto 30px auto;
    }
}

.test-question-result .result-commentary h2 {
    line-height: 43.2px;
    border-bottom: 1px solid #D8D9DE;
    color: #ff7200;
    font-weight: 500;
    font-size: 18px;
    padding-left: 39.2px;
    background-color: transparent;
    background-image: url("../images/icons/icon-info.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-position-x: 12px;
    background-size: 20.8px 20px;
}

.test-question-result .result-commentary p {
    color: #434343;
    font-size: 16px;
    letter-spacing: -0.04em;
    line-height: 1.75em;
    padding: 12px 16px;
}

.test-question-result .result-commentary .word {
    display:inline-block;
    color: #15a6ca;
    font-size: 18px;
    /*letter-spacing: -0.04em;*/
    line-height: 1.75em;
    padding-left: 48px;
    background-color: transparent;
    background-image: url("../images/icons/icon-play-this.png");
    background-repeat: no-repeat;
    background-position-x: 20px;
    background-position-y: 9px;
    background-size: 18px 18px;
    cursor:pointer;
}
.test-question-result .result-commentary .txt {
    display:inline-block;
    color: #434343;
    font-size: 14px;
    letter-spacing: -0.04em;
    line-height: 1.75em;
    padding: 12px 6px;
}

@media screen and (max-width: 1199px){

    .test-question-result {
        margin: 1.97917rem auto 1.5625rem auto
    }

    .test-question-result .result-box {
        width: 18rem;
        height: 11.79167rem;
        position: relative;
        background-color: transparent;
        background-image: url(../images-m/lecture/bg-test-question-result.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        padding-top: 5.20833rem;
        margin: 0 auto 1.04167rem auto
    }

    .test-question-result .result-box h2 {
        color: #fff;
        font-size: 1.83333rem;
        font-weight: 500;
        text-align: center;
        line-height: 1.4em
    }

    .test-question-result .result-box h4 {
        color: #fff;
        font-size: 1.04167rem;
        font-weight: 500;
        text-align: center;
        line-height: 1.4375rem
    }

    .test-question-result .result-box .btn-play-sound {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        background-color: #fff;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%
    }

    .test-question-result .result-commentary {
        width: 496px;
        margin: 0 auto;
        border: 1px solid #d8d9de;
        background-color: #f9f9fb;
        border-radius: 10px
    }

    .test-question-result .result-commentary h2 {
        line-height: 43.2px;
        border-bottom: 1px solid #d8d9de;
        color: #ff7200;
        font-weight: 500;
        font-size: 14.4px;
        padding-left: 39.2px;
        background-color: transparent;
        background-image: url(../images/icons/icon-info.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-position-x: 12px;
        background-size: 20.8px 20px
    }

    .test-question-result .result-commentary p {
        color: #434343;
        font-size: 12.8px;
        letter-spacing: -.04em;
        line-height: 1.75em;
        padding: 12px 16px
    }



    .test-question-result .result-commentary .word {

        font-size: 1rem;
    }
    .test-question-result .result-commentary .txt {
        font-size: 0.8rem;
    }


}



.score-wrapper {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    margin-bottom: 56px;
    position:absolute;
    top:10px;
    right:40px;
}

.score-wrapper .record-score, .score-wrapper .practice-score {
    width: 284.8px;
    height: 292px;
    color: #ffffff;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 48px;
    background-size: 90%;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-wrapper .record-score, .score-wrapper .practice-score {
        width: 200px;
        height: 250px;
        background-size: 70%;
    }
}

.score-wrapper .record-score strong, .score-wrapper .practice-score strong {
    font-size: 88px;
    font-family: CircularStd, sans-serif;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .score-wrapper .record-score strong, .score-wrapper .practice-score strong {
        font-size: 60px;
    }
}


.score-wrapper .record-score {
    background-color: transparent;
    background-image: url("../images/lecture/bg-record-score.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.score-wrapper .practice-score {
    background-color: transparent;
    background-image: url("../images/lecture/bg-practice-score.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
}

.story-practice {
    border: 1px solid #D2D3D6;
    border-radius: 10px;
    background: #fff;
    padding: 24px 54.4px;
    width: 888px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .story-practice {
        border: 1px solid #D2D3D6;
        padding: 18px 40px 0 40px;
        width: 880px;
        transform:translateY(-20px);
    }
}

.story-practice .question-list li {
    counter-increment: section;
    position: relative;
    padding: 32px 40px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .story-practice .question-list li {
        counter-increment: section;
        position: relative;
        padding: 22px 36px;
    }
}

.story-practice .question-list li.correct h2:after, .story-practice .question-list li.wrong h2:after {
    content: '';
    display: block;
    top: 0;
    background-size: 100%;
    position: absolute;
}

.story-practice .question-list li.correct h2:after {
    width: 62.4px;
    height: 59.2px;
    background-color: transparent;
    background-image: url("../images/common/correct-line.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    left: -63.2px;
}

.story-practice .question-list li.wrong h2:after {
    background-color: transparent;
    background-image: url("../images/common/wrong-line.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    width: 47.2px;
    height: 45.6px;
    left: -55.6px;
}

.story-practice .question-list li + li {
    border-top: 2px dashed #e8e8e8;
}

.story-practice .question-list li .question {
    position: relative;
    color: #222222;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 10px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .story-practice .question-list li .question {
        font-size: 22px;
    }
}

.story-practice .question-list li .question:before {
    content: counters(section, ".") ". ";
    color: #00759c;
    font-size: 30px;
    font-weight: 500;
    position: absolute;
    top: 50%;
    left: -40px;
    transform: translateY(-50%);
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .story-practice .question-list li .question:before {
        font-size: 26px;
    }
}

.story-practice .question-list li .options {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
}

.story-practice .question-list li .options > label {
    width: 25%;
    display: block;
}

.story-practice .question-list li .options > label input[type=radio] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
}

.story-practice .question-list li .options > label input[type=radio] + span {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    color: #3e3e3e;
    font-size: 22px;
    font-weight: 400;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .story-practice .question-list li .options > label input[type=radio] + span {
        font-size: 20px;
    }
}

.story-practice .question-list li .options > label input[type=radio] + span:before {
    content: '';
    display: block;
    min-width: 34.4px;
    min-height: 34.4px;
    width: 34.4px;
    height: 34.4px;
    background-color: transparent;
    background-image: url("../images/common/option-unchecked.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    margin-right: 9.6px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .story-practice .question-list li .options > label input[type=radio] + span:before {
        width: 30px;
        height: 30px;
    }
}
.story-practice .question-list li .options > label input[type=radio]:checked + span:before {
    background-image: url(../images/common/option-checked.png);
}

.story-practice .question-list li .options > label.wrong {
    position: relative;
}

.story-practice .question-list li .options > label.wrong:after {
    content: '';
    width: calc(100%);
    position: absolute;
    left: -3px;
    height: 3px;
    background: #ff4200;
    border-radius: 1.5px;
    top: 50%;
    transform: translateY(-50%);
}

.story-practice .question-list li .options > label.correct input[type=radio] + span:before {
    background-image: url(../images/common/option-checked.png);
}

.story-practice .question-list li .options.long-option > label {
    width: 50%;
    margin-bottom: 10px;
}

.story-practice-header {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: flex-end;
    width: 888px;
    margin-bottom: 16px;
    visibility: hidden;
    opacity: 0;
    margin-top: 40px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .story-practice-header {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-end;
        width: 888px;
        margin-bottom: 16px;
        visibility: hidden;
        opacity: 0;
        margin-top:30px;
    }
}

.story-practice-header.opened {
    visibility: visible;
    opacity: 1;
}

@media screen and (max-width: 1199px){

    .story-practice {
        border: 1px solid #d2d3d6;
        border-radius: 10px;
        background: #fff;
        padding: 24px 54.4px;
        width: 888px
    }

    .story-practice .question-list {
        width: 80%;
        margin: 0 auto
    }

    .story-practice .question-list li {
        counter-increment: section;
        position: relative;
        padding: 32px 40px
    }

    .story-practice .question-list li.correct h2:after,
    .story-practice .question-list li.wrong h2:after {
        content: '';
        display: block;
        top: 0;
        background-size: 100%;
        position: absolute
    }

    .story-practice .question-list li.correct h2:after {
        width: 2.58333rem;
        height: 2.4375rem;
        background-color: transparent;
        background-image: url(../images-m/common/correct-line.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        left: -2.5rem
    }

    .story-practice .question-list li.wrong h2:after {
        background-color: transparent;
        background-image: url(../images-m/common/wrong-line.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        width: 2.58333rem;
        height: 2rem;
        left: -2.29167rem
    }

    .story-practice .question-list li+li {
        border-top: 2px dashed #e8e8e8
    }

    .story-practice .question-list li .question {
        position: relative;
        color: #222;
        font-size: 1.10417rem;
        font-weight: 500;
        margin-bottom: .20833rem
    }

    .story-practice .question-list li .question:before {
        content: counters(section, ".") ". ";
        color: #00759c;
        font-size: 1.10417rem;
        font-weight: 500;
        position: absolute;
        top: 50%;
        left: -1.45833rem;
        transform: translateY(-50%)
    }

    .story-practice .question-list li .options {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.25rem
    }

    .story-practice .question-list li .options>label {
        width: 25%;
        display: block
    }

    .story-practice .question-list li .options>label input[type=radio] {
        position: absolute;
        width: 1px;
        height: 1px;
        padding: 0;
        margin: -1px;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0
    }

    .story-practice .question-list li .options>label input[type=radio]+span {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        color: #3e3e3e;
        font-size: .875rem;
        font-weight: 400
    }

    .story-practice .question-list li .options>label input[type=radio]+span:before {
        content: '';
        display: block;
        width: 1.35417rem;
        height: 1.35417rem;
        background-color: transparent;
        background-image: url(../images-m/common/option-unchecked.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        margin-right: .625rem
    }

    .story-practice .question-list li .options>label input[type=radio]:checked+span:before {
        background-image: url(../images-m/common/option-checked.png)
    }

    .story-practice .question-list li .options>label.wrong {
        position: relative
    }

    .story-practice .question-list li .options>label.wrong:after {
        content: '';
        width: calc(100%);
        position: absolute;
        left: -3px;
        height: 3px;
        background: #ff4200;
        border-radius: 1.5px;
        top: 50%;
        transform: translateY(-50%)
    }

    .story-practice .question-list li .options>label.correct input[type=radio]+span:before {
        background-image: url(../images/common/option-checked.png)
    }

    .story-practice .question-list li .options.long-option>label {
        width: 50%;
        margin-bottom: 10px
    }

    .story-practice-header {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: flex-end;
        width: 100%;
        margin-bottom: 1.25rem;
        visibility: hidden;
        opacity: 0;
        margin-top: 0;
    }

    .story-practice-header.opened {
        visibility: visible;
        opacity: 1
    }

    .story-practice-header .btn-story{
        margin: .83333rem .625rem
    }
    .story-practice-header .btn-meaning {
        margin: 0 10rem 0 0;
    }
}


.story-read {
    color: #363636;
    font-size: 20.8px;
    font-weight: 500;
    line-height: 1.69231em;
    letter-spacing: -0.02em;
}

@media screen and (max-width: 1199px){
    .story-read {
        font-size: 1.3rem;
        padding: 1rem;
    }
}

.progress-bar {
    border: 3px solid #D2D3D6;
    box-shadow: inset 0px 5px 5px 0px rgba(0, 0, 0, 0.05);
    width: 796px;
    height: 42.4px;
    position: relative;
    background-color: #F2F3F5;
    border-radius: 21.2px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .progress-bar {
        transform:translateY(-30px);
    }
}

.progress-bar .progress-value {
    position: absolute;
    left: -3px;
    top: -3px;
    height: 42.4px;
    min-width: 100px;
    max-width: calc(100% + 6px);
    background-color: #ff6c00;
    color: #ffffff;
    font-size: 39.2px;
    font-weight: 500;
    font-family: CircularStd, sans-serif;
    border-radius: 21.2px;
    padding-right: 28px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    transition: all .3s ease;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .progress-bar .progress-value {
        font-size: 32px;
    }
}

.progress-bar .progress-value.v0 {
    width: 0%;
}

.progress-bar .progress-value.v5 {
    width: 5%;
}

.progress-bar .progress-value.v10 {
    width: 10%;
}

.progress-bar .progress-value.v15 {
    width: 15%;
}

.progress-bar .progress-value.v20 {
    width: 20%;
}

.progress-bar .progress-value.v25 {
    width: 25%;
}

.progress-bar .progress-value.v30 {
    width: 30%;
}

.progress-bar .progress-value.v35 {
    width: 35%;
}

.progress-bar .progress-value.v40 {
    width: 40%;
}

.progress-bar .progress-value.v45 {
    width: 45%;
}

.progress-bar .progress-value.v50 {
    width: 50%;
}

.progress-bar .progress-value.v55 {
    width: 55%;
}

.progress-bar .progress-value.v60 {
    width: 60%;
}

.progress-bar .progress-value.v65 {
    width: 65%;
}

.progress-bar .progress-value.v70 {
    width: 70%;
}

.progress-bar .progress-value.v75 {
    width: 75%;
}

.progress-bar .progress-value.v80 {
    width: 80%;
}

.progress-bar .progress-value.v85 {
    width: 85%;
}

.progress-bar .progress-value.v90 {
    width: 90%;
}

.progress-bar .progress-value.v95 {
    width: 95%;
}

.progress-bar .progress-value.v100 {
    width: 100%;
}

.progress-bar .progress-value.v100 {
    width: calc(100% + 6px);
}

@media screen and (max-width: 1199px){

    .progress-bar {
        border: 3px solid #d2d3d6;
        box-shadow: inset 0 5px 5px 0 rgba(0, 0, 0, .05);
        width: 90%;
        margin: 0 auto;
        height: 1.45833rem;
        position: relative;
        background-color: #f2f3f5;
        border-radius: 21.2px
    }

    .progress-bar .progress-value {
        position: absolute;
        left: -3px;
        top: -3px;
        height: 1.45833rem;
        min-width: 45px;
        max-width: calc(100% + 6px);
        background-color: #ff6c00;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        font-family: CircularStd, sans-serif;
        border-radius: 21.2px;
        padding-right: 28px;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        transition: all .3s ease
    }

    .progress-bar .progress-value.v0 {
        width: 0%;
        padding-right: 10px;

    }

    .progress-bar .progress-value.v5 {
        width: 5%
    }

    .progress-bar .progress-value.v10 {
        width: 10%
    }

    .progress-bar .progress-value.v15 {
        width: 15%
    }

    .progress-bar .progress-value.v20 {
        width: 20%
    }

    .progress-bar .progress-value.v25 {
        width: 25%
    }

    .progress-bar .progress-value.v30 {
        width: 30%
    }

    .progress-bar .progress-value.v35 {
        width: 35%
    }

    .progress-bar .progress-value.v40 {
        width: 40%
    }

    .progress-bar .progress-value.v45 {
        width: 45%
    }

    .progress-bar .progress-value.v50 {
        width: 50%
    }

    .progress-bar .progress-value.v55 {
        width: 55%
    }

    .progress-bar .progress-value.v60 {
        width: 60%
    }

    .progress-bar .progress-value.v65 {
        width: 65%
    }

    .progress-bar .progress-value.v70 {
        width: 70%
    }

    .progress-bar .progress-value.v75 {
        width: 75%
    }

    .progress-bar .progress-value.v80 {
        width: 80%
    }

    .progress-bar .progress-value.v85 {
        width: 85%
    }

    .progress-bar .progress-value.v90 {
        width: 90%
    }

    .progress-bar .progress-value.v95 {
        width: 95%
    }

    .progress-bar .progress-value.v100 {
        width: 100%
    }

    .progress-bar .progress-value.v100 {
        width: calc(100% + 6px)
    }

}

.view-section .proverb li {
    cursor:default !important;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .view-section .proverb li{
        height: 260px;
        padding-top: 10px;
    }
}

.word-list.proverb li .tit {
    color: #363636;
    font-size: 26.4px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 28px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

    .word-list.proverb li .tit {
        margin-bottom: 10px;
    }

}

.word-list.proverb li .word {
    color: #00759c;
    font-size: 54.4px;
    font-weight: 500;
    text-align: center;
    line-height: 1.43103em;
    letter-spacing: -0.02em;
}

@media screen and (max-width: 1199px){

    .word-list.proverb li .tit {
        color: #363636;
        font-size: .9375rem;
        font-weight: 400;
        text-align: center;
        margin: 1.25rem 0 .625rem 0
    }

    .word-list.proverb li .word {
        color: #00759c;
        font-size: 1.41667rem;
        font-weight: 500;
        text-align: center;
        line-height: 1.35;
        letter-spacing: -.02em
    }

}

.card-select {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    justify-content: center;
}

.card-select .o-card {
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    height: 70.4px;
    margin: 6.8px;
    border: 2px solid #15A6CA;
    border-radius: 4.8px;
    background-color: #fff;
    font-size: 27.2px;
    color: #222222;
}

.card-select .o-card:disabled, .card-select .o-card.disabled {
    opacity: 0.65;
}

.card-select .o-card:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.card-select .o-card.wrong {
    border-color: #ff8400;
    background-color: #ffe0bf;
    color: #ff5400;
}

.card-select .o-card.correct {
    background-color: #15a6ca;
    border-color: #15a6ca;
    color: #fff;
}

@media screen and (max-width: 1199px){

    .card-select {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        margin: 1.875rem auto
    }

    .card-select .o-card {
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 10.5625rem;
        height: 2.22917rem;
        margin: 6.8px;
        border: 2px solid #15a6ca;
        border-radius: 4.8px;
        background-color: #fff;
        font-size: .9375rem;
        color: #222;

    }

    .card-select .o-card.disabled,
    .card-select .o-card:disabled {
        opacity: .65
    }

    .card-select .o-card:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .card-select .o-card.wrong {
        border-color: #ff8400;
        background-color: #ffe0bf;
        color: #ff5400
    }

    .card-select .o-card.correct {
        background-color: #15a6ca;
        border-color: #15a6ca;
        color: #fff
    }
}

.accumulate-game {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 64px;
}

.accumulate-game .game-circle {
    cursor:pointer;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    box-shadow: 0px 17.6px 19.2px 0px rgba(0, 0, 0, 0.16);
    width: 156px;
    height: 156px;
    color: #fff;
    font-weight: 500;
    font-family: CircularStd, sans-serif;
    font-size: 80px;
    background-color: #6a6a6a;
    margin: 0 8.8px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .accumulate-game .game-circle {
        width: 126px;
        height: 126px;
        font-size: 70px;
    }
}


.accumulate-game .game-circle.blue {
    background-color: #15a6ca;
}

.accumulate-game .game-circle.orange {
    background-color: #ff8400;
}

.accumulate-game .game-circle.green {
    background-color: #70a531;
}

@media screen and (max-width: 1199px){

    .accumulate-game {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 64px
    }

    .accumulate-game .game-circle {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        box-shadow: 0 17.6px 19.2px 0 rgba(0, 0, 0, .16);
        width: 5.6875rem;
        height: 5.6875rem;
        color: #fff;
        font-weight: 500;
        font-family: CircularStd, sans-serif;
        font-size: 2.5rem;
        background-color: #6a6a6a;
        margin: 0 .75rem
    }

    .accumulate-game .game-circle.blue {
        background-color: #15a6ca
    }

    .accumulate-game .game-circle.orange {
        background-color: #ff8400
    }

    .accumulate-game .game-circle.green {
        background-color: #70a531
    }

}

.help-text {
    color: #474545;
    font-size: 35.2px;
    font-weight: 400;
    text-align: center;
}

@media screen and (max-width: 1199px){
    .help-text {
        color: #474545;
        font-size: 1.5rem;
        font-weight: 400;
        text-align: center
    }
}


.sentence-practice {
    width: 707.2px;
    padding-top: 60px;
    padding-bottom: 40px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {

}

.align-start .sentence-practice.l7 {
    padding: 80px 0;
}

@media screen and (max-width: 1199px){
    .align-start .sentence-practice.l7 {
        padding: 0;
    }
}

.sentence-practice.small .group .input {
    padding: 5px 17.6px;
    margin-bottom: 5px;
    font-size: 17.6px;
}

.sentence-practice .group {
    margin-bottom: 10.4px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .sentence-practice .group {
        margin-bottom: 8px;
    }
}

.sentence-practice .group .input-group {
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
}

.sentence-practice .group .input-group .input {
    width: auto;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-left: 7px;
    min-width:40%;
}

.sentence-practice .group .input-group .input + .input {
    margin-left: 7px;
}

.sentence-practice .group .origin {
    color: #222222;
    font-size: 22.4px;
    font-weight: 500;
    display: block;
    line-height: 1.92857em;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .sentence-practice .group .origin {
        font-size: 20px;
        line-height: 1.92857em;
    }
}

.sentence-practice .group .origin strong {
    color: #15a6ca;
    font-weight: 600;

}

.sentence-practice .group .input {
    border: 2px solid #BFC0C2;
    background-color: #F3F4F7;
    border-radius: 10px;
    font-size: 22.4px;
    display: block;
    width: 100%;
    padding: 12px;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .sentence-practice .group .input {
        font-size: 20px;
        padding: 8px 12px;
    }
}

.sentence-practice .group .input.correct {
    color: #00759c;
    background-color: #daf8ff;
    border-color: #15a6ca;
}

.sentence-practice .group .input.wrong {
    color: #ff5400;
    background-color: #ffe0bf;
    border-color: #ff8400;
}

.sentence-practice .group .input.warn {
    background-color: #ffe0bf;
    border-color: #ff8400;
}


.sentence-practice .group .my-answer, .sentence-practice .group .best-answer {
    padding-left: 122.4px;
    color: #222222;
    font-size: 20.8px;
    font-weight: 500;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    height: 50.4px;
    align-items: center;
    border-radius: 9px;
    position: relative;
    border: 2px solid;
    margin-bottom: 4px;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .sentence-practice .group .my-answer, .sentence-practice .group .best-answer {
        height: 44px;
    }
}

.sentence-practice .group .my-answer:before, .sentence-practice .group .best-answer:before {
    content: '';
    position: absolute;
    top: -2px;
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    align-items: center;
    left: -2px;
    bottom: 0;
    padding-left: 18.4px;
    border-top-left-radius: 9px;
    border-bottom-left-radius: 9px;
    width: 101.6px;
    height: 50.4px;
    display: block;
    background-size: 100%;
    background-repeat: no-repeat;
}
@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .sentence-practice .group .my-answer:before, .sentence-practice .group .best-answer:before {
        height: 44px;
        background-size: auto 44px;
    }
}

.sentence-practice .group .my-answer {
    border-color: #00beb3;
}

.sentence-practice .group .my-answer:before {
    background-image: url(../images/lecture/bg-my-answer.png);
}

.sentence-practice .group .best-answer {
    border-color: #f29807;
}

.sentence-practice .group .best-answer:before {
    background-image: url(../images/lecture/bg-best-answer.png);
}

@media screen and (max-width: 1199px){

    .sentence-practice {
        width: 80%;
        margin: 4.16667rem auto 12rem auto;
        padding-top:0;
        padding-bottom: 10px;
    }

    .sentence-practice.small .group .input {
        padding: 5px 17.6px;
        margin-bottom: 5px;
        font-size: 17.6px
    }

    .sentence-practice .group {
        margin-bottom: 1.25rem
    }

    .sentence-practice .group .input-group {
        -webkit-display: flex;
        display: -ms-flex;
        display: flex
    }

    .sentence-practice .group .input-group .input {
        width: auto;
        -webkit-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: initial;
    }

    .sentence-practice .group .input-group .input+.input {
        margin-left: 7px
    }

    .sentence-practice .group .origin {
        color: #222;
        font-size: 1.08333rem;
        font-weight: 500;
        display: block;
        line-height: 1.92857em
    }

    .sentence-practice .group .input {
        border: 2px solid #bfc0c2;
        background-color: #f3f4f7;
        border-radius: 10px;
        font-size: 22.4px;
        display: block;
        width: 100%;
        padding: 12px
    }

    .sentence-practice .group .input.correct {
        color: #00759c;
        background-color: #daf8ff;
        border-color: #15a6ca
    }

    .sentence-practice .group .input.wrong {
        color: #ff5400;
        background-color: #ffe0bf;
        border-color: #ff8400
    }

    .sentence-practice .group .best-answer,
    .sentence-practice .group .my-answer {
        padding-left: 4.58333rem;
        color: #222;
        font-size: .875rem;
        font-weight: 500;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        height: 1.79167rem;
        align-items: center;
        border-radius: 9px;
        position: relative;
        border: 2px solid;
        margin-bottom: 4px
    }

    .sentence-practice .group .best-answer:before,
    .sentence-practice .group .my-answer:before {
        content: '';
        position: absolute;
        top: -2px;
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        align-items: center;
        left: -2px;
        bottom: 0;
        padding-left: 18.4px;
        border-top-left-radius: 9px;
        border-bottom-left-radius: 9px;
        width: 4.08333rem;
        height: 1.79167rem;
        display: block;
        background-size: 100%;
        background-repeat: no-repeat
    }

    .sentence-practice .group .my-answer {
        border-color: #00beb3
    }

    .sentence-practice .group .my-answer:before {
        background-image: url(../images-m/lecture/bg-my-answer.png)
    }

    .sentence-practice .group .best-answer {
        border-color: #f29807
    }

    .sentence-practice .group .best-answer:before {
        background-image: url(../images-m/lecture/bg-best-answer.png)
    }

}

.drdt-wrap .tit {
    color: #242525;
    font-size: 39.2px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 24px;
}

.drdt-wrap .tit strong {
    font-weight: 500;
    color: #ff7e00;
}

.drdt-wrap .video-wrapper {
    border: 1px solid #DCDDE1;
    border-radius: 40px;
    background-color: #fff;
    width: 785.6px;
    height: 465.6px;
    position: relative;
    box-shadow: 0px 11px 24px 0px rgba(0, 0, 0, 0.07);
    -webkit-display: flex;
    display: -ms-flex;
    display: flex;
    justify-content: center;
    align-items: center;
    margin:0 auto;

}

.drdt-wrap .video-wrapper iframe {
    width: 728px;
    height: 410.4px;
}

@media screen and (max-width: 1199px){

    .drdt-wrap {
        margin: 1.66667rem
    }

    .drdt-wrap .tit {
        color: #242525;
        font-size: 1.02083rem;
        text-align: center;
        font-weight: 500;
        margin-bottom: .625rem
    }

    .drdt-wrap .tit strong {
        font-weight: 500;
        color: #ff7e00
    }

    .drdt-wrap .video-wrapper {
        border: 1px solid #dcdde1;
        border-radius: 40px;
        background-color: #fff;
        width: 22.91667rem;
        height: 13.54167rem;
        position: relative;
        box-shadow: 0 11px 24px 0 rgba(0, 0, 0, .07);
        -webkit-display: flex;
        display: -ms-flex;
        display: flex;
        justify-content: center;
        align-items: center
    }

    .drdt-wrap .video-wrapper iframe {
        width: 18.95833rem;
        height: 10.625rem
    }
}

.document {
    padding: 60px 0;
}

@media screen and (min-width: 1200px) and (max-width: 1600px) {
    .document {
        padding: 20px 0 40px 0;
    }

}



.document .tit {
    color: #222222;
    font-size: 33.6px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 32px;
}

.document .box {
    border: 1px solid #D2D3D6;
    border-radius: 10px;
    background-color: #fff;
    width: 887.2px;
    padding: 36px 56px;
}

.document .box h4 {
    color: #222222;
    font-weight: 500;
    font-size: 25.6px;
    position: relative;
    margin-bottom: 16px;
    padding-left: 20px;
}

.document .box h4:before {
    content: '';
    display: block;
    position: absolute;
    width: 10.4px;
    height: 10.4px;
    border-radius: 100%;
    background-color: #0cb4f2;
    left: 0;
    top: 20px;
}

.document .box p {
    color: #3e3e3e;
    font-size: 19.2px;
    letter-spacing: -0.02em;
    line-height: 1.54167em;
    padding-left: 20px;
}

.document .box p.small {
    font-size: 16px;
    color: #545454;
}


.document .box p strong {
    color: #00759c;
    font-weight: 400;
}

.document .box p .big {
    font-weight: 500;
    font-size: 22px;
}

.document .box p .underline {
    text-decoration: underline;
    text-decoration-color: #00759C;
}


.document .box p + p {
    margin-top: 12px;
}

.document .box .line {
    border: 0 none;
    border-top: 2px dashed #e8e8e8;
    height: 1px;
    background-color: transparent;
    width: 100%;
    margin: 24px 0;
}

.document .box img {
    display: block;
    margin-left: 20px;
    max-width: calc(100% - 20px);
}

@media screen and (max-width: 1199px){

    .document {
        padding: 40px 0;
        width: 60%;
        margin: 0 auto
    }

    .document .tit {
        color: #222;
        font-size: 1.25rem;
        font-weight: 500;
        text-align: center;
        margin-bottom: 1.25rem
    }

    .document .box {
        margin: 0 auto;
        border: initial;
        border-radius: initial;
        width: initial;
        padding: initial;
        background-color:initial;
    }

    .document .box h4 {
        color: #222;
        font-weight: 500;
        font-size: 1.04167rem;
        position: relative;
        margin-bottom: .83333rem;
        padding-left: .72917rem
    }

    .document .box h4:before {
        content: '';
        display: block;
        position: absolute;
        width: .41667rem;
        height: .41667rem;
        border-radius: 100%;
        background-color: #0cb4f2;
        left: 0;
        top: .41667rem
    }

    .document .box p {
        color: #3e3e3e;
        font-size: .83333rem;
        letter-spacing: -.02em;
        line-height: 1.54167em;
        padding-left: .72917rem
    }

    .document .box p.small {
        font-size: .75rem;
        color: #545454
    }

    .document .box p strong {
        color: #00759c;
        font-weight: 400
    }

    .document .box p .big {
        font-weight: 500;
        font-size: 1rem;
    }


    .document .box p .underline {
        text-decoration: underline;
        text-decoration-color: #00759c
    }

    .document .box p+p {
        margin-top: 12px
    }

    .document .box .line {
        border: 0 none;
        border-top: 2px dashed #e8e8e8;
        height: 1px;
        background-color: transparent;
        width: 100%;
        margin: 20px 0
    }

    .document .box img {
        display: block;
        margin-left: 20px;
        max-width: calc(100% - 20px)
    }
}

.alert-quiz .alert-wrap {
    width: 660px;
    padding-left: 0;
    padding-right: 0;
}

.alert-quiz .alert-wrap .title {
    font-size: 24px;
    color: #fff;
    text-align: center;
    font-weight: 700;
    border-bottom: 1px solid #92bf3b;
    padding-bottom: 12px;
}

.alert-quiz .alert-wrap .quiz-content {
    font-size: 32.8px;
    font-weight: 500;
    color: #fff;
    line-height: 1.68293EM;
    padding: 16px 27.2px 0;
    letter-spacing: -0.02em;
}



.alert-quiz .alert-wrap .quiz-content .quiz-input {
    display: inline-block;
    vertical-align: middle;
    width: 116.8px;
    height: 42.4px;
    border: 2px solid #fff;
    border-radius: 11px;
    line-height: 42.4px;
    background-color: transparent;
    color: #fff;
    padding: 0 3px;
}


.alert-quiz .alert-wrap .quiz-hint {
    font-size: 24px;
    font-weight: 400;
    color: #fff;
    line-height: 1.68293em;
    padding: 16px 27.2px;
    letter-spacing: -0.02em;
}

@media screen and (max-width: 1199px){
    .alert-quiz .alert-wrap .quiz-hint {
        font-size: 1rem;
        line-height: 1rem;
        padding: 0.5rem 1rem;
        letter-spacing: -0.02em;
    }

}

.alert-quiz .alert-wrap .btn-view-correct {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -22.4px;
    display: inline-block;
    margin: 0;
    font-weight: normal;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    text-decoration: none;
    outline: 0;
    vertical-align: middle;
    border: 0 none;
    padding: 0;
    width: 134.4px;
    height: 44.8px;
    background-color: transparent;
    background-image: url("../images/buttons/btn-view-correct.png");
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
    background-size: 100%;
    text-indent: -9999px;
}

.alert-quiz .alert-wrap .btn-view-correct:disabled, .alert-quiz .alert-wrap .btn-view-correct.disabled {
    opacity: 0.65;
}

.alert-quiz .alert-wrap .btn-view-correct:not([disabled]):not(.disabled) {
    cursor: pointer;
}

.alert-quiz .alert-wrap .quiz-answer {
    display: none;
    border-radius: 64px;
    background-color: #fff;
    width: 594px;
    margin: 0 auto 10px auto;
    padding: 24px 36px;
}

.alert-quiz .alert-wrap .quiz-answer h4 {
    color: #2f2f2f;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
}

.alert-quiz .alert-wrap .quiz-answer h2 {
    color: #6a9b0b;
    font-size: 30.4px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -0.02em;
    margin-bottom: 16px;
}

.alert-quiz .alert-wrap .quiz-answer p {
    color: #565656;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.6em;
    text-align: center;
}

.alert-quiz .alert-wrap.opened .quiz-answer {
    display: block;
}

.alert-quiz .alert-wrap.opened .btn-view-correct {
    background-image: url(../images/buttons/btn-close-correct.png);
}



@media screen and (max-width: 1199px){

    .alert-quiz .alert-wrap {
        width: 25rem;
        padding-left: 0;
        padding-right: 0;
        margin-bottom: 40px;

    }

    .alert-quiz .alert-wrap .title {
        font-size: .9375rem;
        color: #fff;
        text-align: center;
        font-weight: 700;
        border-bottom: 1px solid #92bf3b;
        padding-bottom: .3125rem
    }

    .alert-quiz .alert-wrap .quiz-content {
        font-size: 1.10417rem;
        font-weight: 500;
        color: #fff;
        line-height: 1.68293EM;
        padding: 16px 27.2px;
        letter-spacing: -.02em
    }

    .alert-quiz .alert-wrap .quiz-content .quiz-input {
        display: inline-block;
        vertical-align: middle;
        width: 4.58333rem;
        height: 1.875rem;
        border: 2px solid #fff;
        border-radius: 11px;
        line-height: 42.4px;
        background-color: transparent;
        color: #fff;
        padding: 0 .20833rem;
        font-size: 1.10417rem
    }

    .alert-quiz .alert-wrap .btn-view-correct {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -22.4px;
        display: inline-block;
        margin: 0;
        font-weight: 400;
        text-align: center;
        white-space: nowrap;
        user-select: none;
        text-decoration: none;
        outline: 0;
        vertical-align: middle;
        border: 0 none;
        padding: 0;
        width: 134.4px;
        height: 44.8px;
        background-color: transparent;
        background-image: url(../images/buttons/btn-view-correct.png);
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: center;
        background-size: 100%;
        text-indent: -9999px
    }

    .alert-quiz .alert-wrap .btn-view-correct.disabled,
    .alert-quiz .alert-wrap .btn-view-correct:disabled {
        opacity: .65
    }

    .alert-quiz .alert-wrap .btn-view-correct:not([disabled]):not(.disabled) {
        cursor: pointer
    }

    .alert-quiz .alert-wrap .quiz-answer {
        display: none;
        border-radius: 1.66667rem;
        background-color: #fff;
        width: 20.83333rem;
        margin: 28px auto 0;
        padding: .625rem .83333rem
    }

    .alert-quiz .alert-wrap .quiz-answer h4 {
        color: #2f2f2f;
        font-size: .6875rem;
        font-weight: 500;
        text-align: center;
        letter-spacing: -.02em;
        margin-bottom: .41667rem
    }

    .alert-quiz .alert-wrap .quiz-answer h2 {
        color: #6a9b0b;
        font-size: 1.41667rem;
        font-weight: 500;
        text-align: center;
        letter-spacing: -.02em;
        margin-bottom: .41667rem
    }

    .alert-quiz .alert-wrap .quiz-answer p {
        color: #565656;
        font-size: .75rem;
        font-weight: 400;
        line-height: 1.6em;
        text-align: left
    }

    .alert-quiz .alert-wrap.opened .quiz-answer {
        display: block
    }

    .alert-quiz .alert-wrap.opened .btn-view-correct {
        background-image: url(../images/buttons/btn-close-correct.png)
    }



}

@media screen and (max-width:1199px) and (orientation:portrait) {
    /*.full {*/
        /*width: 100vh;*/
        /*height: 100vw;*/
        /*!*-webkit-transform: rotate(90deg) translateY(-100vw);*!*/
        /*!*-moz-transform: rotate(90deg) translateY(-100vw);*!*/
        /*!*-ms-transform: rotate(90deg) translateY(-100vw);*!*/
        /*!*-o-transform: rotate(90deg) translateY(-100vw);*!*/
        /*!*transform: rotate(90deg) translateY(-100vw);*!*/
        /*!*transform-origin: top left*!*/
    /*}*/
}
